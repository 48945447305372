import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../../Config/API";
import RightSideContent from "../RightSideContent";
import styles from "./styles.module.css";
import ServiceSlider from "../ServiceSlider";
import { watchModule } from "../../../Store/Actions/detailsModuleActions";
import { useDispatch } from "react-redux";

const InnerContent = ({ setTitle }) => {
  // Getting ID from Params
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [lang, setLang] = useState("Spanish");
  const [filteredService, setFilteredService] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);
  useEffect(() => {
    dispatch(
      watchModule({
        type: "service",
        valueId: id,
        userId: currentUser?.id || "",
      })
    );
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getServices();
      setIsApiCallInProgress(true);
    }
  }, [currentLang, lang]);

  const getServices = async () => {
    // Calling Language API
    try {
      const res = await API.post("/get-service-lang", {
        language: lang,
        serviceId: id,
      });

      const data = res?.data?.data;
      setFilteredService(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsApiCallInProgress(false);
    }
  };

  setTitle(filteredService?.title);

  return (
    <div className={`d-flex justify-content-around ${styles.parent}`}>
      <section className={styles.child1}>
        <ServiceSlider serviceData={filteredService} />
      </section>
      <section className={styles.child2}>
        <RightSideContent
          serviceData={filteredService}
          lang={lang}
          setLang={setLang}
          setserviceData={setFilteredService}
        />
      </section>
    </div>
  );
};

export default InnerContent;
