import React from "react";
import styles from "./styles.module.css";
import arrowIcon from "../../Assets/arrowIcon.png";

const CustomHeader = ({ label1, label2, icon = true }) => {
  return (
    <div className={styles.header}>
      <section>
        <h2>{label1 && label1}</h2>
        {icon && <img src={arrowIcon} alt="arrowIcon" />}
        <h2>{label2 && label2}</h2>
      </section>
    </div>
  );
};

export default CustomHeader;
