import React, { useEffect, useState } from "react";
import NewsCards from "../../../Components/NewsCard";
import { useNavigate } from "react-router-dom";
import API from "../../../Config/API";
import { Spinner } from "react-bootstrap";
import Heading from "../../../Components/Heading";

const NewsCard = ({ allData, setAllData }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);

  const newsDetails = () => {
    navigate("/news-details");
  };

  const user = localStorage.getItem("UserDetails");
  const parsedUser = JSON.parse(user);
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);

  useEffect(() => {
    // Get User
    setCurrentUser(parsedUser);

    // Get Current Location

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      setIsApiCallInProgress(true);
      getNews();
    }
  }, [currentUser, currentLang, currentLocation]);

  const getNews = async () => {
    setIsLoading(true);

    try {
      let res;
      if (parsedUser) {
        res = await API.post("/get-news", { userId: parsedUser?.id });
      } else {
        res = await API.post("/get-news-web", {
          language: currentLang || "Spanish",
          latitude: String(parsedLocation?.latitude) || "",
          longitude: String(parsedLocation?.longitude) || "",
        });
      }

      const activeNews = res?.data?.data?.filter(
        (news) => news.status === "Active"
      );
      if (parsedUser) {
        let filter = activeNews.filter((val) => val.followed === "yes");
        setAllData(filter);
        return;
      }
      setAllData(activeNews);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div
      className={`${
        isLoading && " d-flex justify-content-center align-items-center"
      } row pb-3`}
    >
      {isLoading ? (
        <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
      ) : allData && allData?.length > 0 ? (
        allData?.map((value) => {
          return (
            <section
              key={value.id}
              className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
            >
              <NewsCards value={value} />
            </section>
          );
        })
      ) : (
        <Heading label={"No News Found"} />
      )}
    </div>
  );
};

export default NewsCard;
