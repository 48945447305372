import {
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_SUCCESS,
  EDIT_EVENT_REQUEST,
  GET_EVENTS_FIALURE,
  GET_EVENTS_SUCCES,
  GET_EVENT_IS_LOADING,
} from "../Constants/eventsConstant";
import { EDIT_POI_SUCCESS } from "../Constants/pointOfInterestConts";

const initialState = {
  events: [],
  isLoading: false,
  error: null,
  deleteEventRes: "",
  editEventRes: ""
};

export const getEventsReducers = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_EVENT_REQUEST:
    case EDIT_EVENT_REQUEST:
      return { ...state, isLoading: true };
    case GET_EVENT_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        error: null,
      };
    case GET_EVENTS_SUCCES:
      return {
        events: action.payload,
      };
    case GET_EVENTS_FIALURE:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        deleteEventRes: action.payload,
      };
    case EDIT_POI_SUCCESS:
      return {
        ...state,
        isLoading: false,
        editEventRes: action.payload
      };

    default:
      return state;
  }
};
