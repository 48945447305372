import React from "react";
import { IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileAction } from "../../../Store/Actions/profileAction";
import styles from "./customlink.module.css";
const CustomLink = ({ heading, icon, routing, logout , title }) => {
  const active = useSelector((state) => state.profileReducer.activeProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profileActiveChange = (activeProfile, route) => {
    dispatch(profileAction(activeProfile));
    navigate(route);
  };

  // Handle Link Click
  const handleLinkClick = () => {
    if (logout) {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("UserDetails");
      profileActiveChange(heading, routing)
    }
    else {
      profileActiveChange(heading, routing)
    }
  }
  return (
    <>
      <div
        className={`${styles.link}`}
        style={{
          backgroundColor: active === heading ? "#34c89a" : "",
          color: active === heading ? "white" : "black",
          // border-bottom: 1px solid #a09f9f;
          borderBottom: active === heading ? "" : "1px solid #a09f9f",
        }}
        onClick={handleLinkClick}
      >
        <section className={`${styles.linkBox}`}>
          <div className={`${styles.iconBox}`}>
            {icon}
            <span>{title}</span>
          </div>
          <div>
            <IoIosArrowForward />
          </div>
        </section>
      </div>
    </>
  );
};

export default CustomLink;
