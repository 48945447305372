import { useState } from "react";
import { GrMapLocation } from "react-icons/gr";
import { useEffect } from "react";
import API from "../../../Config/API";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import { useTranslation } from "react-i18next";

export function RadioBox({ title, icon, value, checked, onChange }) {
  return (
    <div className={styles.radioBox}>
      <label className={styles.radioLabel}>
        <div className={styles.iconBox}>
          <input
            type="radio"
            value={value}
            checked={checked}
            onChange={onChange}
            className={styles.radioInput}
          />
          {icon && <span>{icon}</span>}
        </div>
        <span>{title}</span>
      </label>
    </div>
  );
}

const RadioBoxGroup = ({
  currentUser,
  selectedMuncData,
  setSelectedMuncData,
}) => {
  const [selectedMunc, setSelectedMunc] = useState("");
  const [municipalities, setMunicipality] = useState([]);
  const {t} = useTranslation()

  useEffect(() => {
    currentUser && getMuncipalties();
  }, [currentUser]);

  const getMuncipalties = async () => {
    try {
      const response = await API.post("/get-all-muncipalities", {
        userId: currentUser?.id,
      });
      setMunicipality(response?.data?.data.sli);
    } catch (error) {
      console.log(error);
    }
  };

  const handleMuncChange = (event, munc) => {
    setSelectedMunc(event.target.value);
    setSelectedMuncData(munc);
  };

  return (
    <div>
      <Heading
        label={t("Select-Nearest-Municipality")}
        style={{
          fontSize: "1rem",
          fontWeight: "bold",
        }}
      />
      <div>
        {municipalities?.length > 0 ? (
          municipalities?.map((munc) => (
            <RadioBox
              key={munc?.id}
              value={munc?.title}
              checked={selectedMunc === munc?.title}
              onChange={(e) => handleMuncChange(e, munc)}
              icon={<GrMapLocation />}
              title={munc?.title}
            />
          ))
        ) : (
          <Heading label={"No Muncipalities"} style={{ fontSize: "1.2rem" }} />
        )}
      </div>
    </div>
  );
};

export default RadioBoxGroup;
