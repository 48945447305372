import React from "react";
import styles from "./style.module.css";
import { useState } from "react";
import { useEffect } from "react";

function TimeDropDown({ data }) {
  const [show, setShow] = useState(false);
  const d = new Date();
  let day = d.getDay();
  const [value, setValue] = useState({});

  const handleChange = (val) => {
    handleShow();
    setValue(val);
  };
  const handleShow = () => {
    setShow(!show);
  };
  useEffect(() => {
    setValue({
      label:
        day === 0
          ? "Sunday"
          : day === 1
          ? "Monday"
          : day === 2
          ? "Tuesday"
          : day === 3
          ? "Wednesday"
          : day === 4
          ? "Thursday"
          : day === 5
          ? "Friday"
          : "Saturday",
      startTime:
        day === 0
          ? data?.sundayStartTime
          : day === 1
          ? data?.mondayStartTime
          : day === 2
          ? data?.tuesdayStartTime
          : day === 3
          ? data?.wednesdayStartTime
          : day === 4
          ? data?.thursdayStartTime
          : day === 5
          ? data?.fridayStartTime
          : data?.saturdayStartTime,
      endTime:
        day === 0
          ? data?.sundayEndTime
          : day === 1
          ? data?.mondayEndTime
          : day === 2
          ? data?.tuesdayEndTime
          : day === 3
          ? data?.wednesdayEndTime
          : day === 4
          ? data?.thursdayEndTime
          : day === 5
          ? data?.fridayEndTime
          : data?.saturdayEndTime,
    });
  }, [data]);

  return (
    <div className={styles.dropDownBox}>
      <section className={styles.dropDownSelect} onClick={handleShow}>
        {/* <img src={language.img} width="30" alt="Flag" /> */}

        <span className="d-block">
          {value?.label || "---"} : {value?.startTime || "00:00:00"} -{" "}
          {value?.endTime || "00:00:00"}
        </span>
      </section>
      <section
        className={styles.dropDownOption}
        style={{
          opacity: show ? 1 : 0,
          visibility: show ? "visible" : "hidden",
          transition: show ? "0.5s ease" : "0.5s ease",
          transform: show ? "translateY(0)" : "translateY(-20px)",
        }}
      >
        {(data.mondayStartTime || data?.mondayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Monday",
                startTime: data.mondayStartTime,
                endTime: data.mondayEndTime,
              })
            }
          >
            Monday : {data?.mondayStartTime || "00:00:00"} -{" "}
            {data?.mondayEndTime || "00:00:00"}
          </span>
        )}
        {(data.tuesdayStartTime || data?.tuesdayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Tuesday",
                startTime: data.tuesdayStartTime,
                endTime: data.tuesdayEndTime,
              })
            }
          >
            Tuesday : {data?.tuesdayStartTime || "00:00:00"} -{" "}
            {data?.tuesdayEndTime || "00:00:00"}
          </span>
        )}
        {(data.wednesdayStartTime || data.wednesdayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Wednesday",
                startTime: data.wednesdayStartTime,
                endTime: data.wednesdayEndTime,
              })
            }
          >
            Wednesday : {data?.wednesdayStartTime || "00:00:00"} -{" "}
            {data?.wednesdayEndTime || "00:00:00"}
          </span>
        )}
        {(data.thursdayStartTime || data?.thursdayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Thursday",
                startTime: data.thursdayStartTime,
                endTime: data.thursdayEndTime,
              })
            }
          >
            Thursday : {data?.thursdayStartTime || "00:00:00"} -{" "}
            {data?.thursdayEndTime || "00:00:00"}
          </span>
        )}
        {(data.fridayStartTime || data.fridayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Friday",
                startTime: data.fridayStartTime,
                endTime: data.fridayEndTime,
              })
            }
          >
            Friday : {data?.fridayStartTime || "00:00:00"} -{" "}
            {data?.fridayEndTime || "00:00:00"}
          </span>
        )}
        {(data.saturdayStartTime || data.saturdayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Saturday",
                startTime: data.saturdayStartTime,
                endTime: data.saturdayEndTime,
              })
            }
          >
            Saturday : {data?.saturdayStartTime || "00:00:00"} -{" "}
            {data?.saturdayEndTime || "00:00:00"}
          </span>
        )}
        {(data.sundayStartTime || data.sundayEndTime) && (
          <span
            className="d-block"
            onClick={() =>
              handleChange({
                label: "Sunday",
                startTime: data.sundayStartTime,
                endTime: data.sundayEndTime,
              })
            }
          >
            Sunday : {data?.sundayStartTime || "00:00:00"} -{" "}
            {data?.sundayEndTime || "00:00:00"}
          </span>
        )}
        {!data.mondayEndTime &&
          !data.mondayStartTime &&
          !data.tuesdayStartTime &&
          !data?.tuesdayEndTime &&
          !data.wednesdayStartTime &&
          !data.wednesdayEndTime &&
          !data.thursdayStartTime &&
          !data?.thursdayEndTime &&
          !data.sundayStartTime &&
          !data.fridayStartTime &&
          !data.fridayEndTime &&
          !data.saturdayStartTime &&
          !data.saturdayEndTime &&
          !data.sundayStartTime &&
          !data.sundayEndTime && (
            <span className="d-block">Not Set Timing</span>
          )}
      </section>
    </div>
  );
}

export default TimeDropDown;
