import React, { useState, useEffect } from "react";
import Button from "../../../Components/Button";
import styles from "./style.module.css";
import byke from "../../../Assets/bikeBlack.png";
import { FaRegEdit } from "react-icons/fa";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import Card from "../Cards";
import {
  pointDetailsCardData,
  routesData,
  servicesData,
} from "../../../logics/Data";
import ServicesCard from "../../../Components/ServicesCard";
import RoutesCard from "../../../Components/RoutesCard";
import { useLocation } from "react-router-dom";
import GoogleMapPolyline from "./GoogleMapPolyline";

function SetOfRoutes({ allData }) {
  const [currentPosition, setCurrentPosition] = useState(null);
  const { state } = useLocation();
  let center = {
    lat: Number(state?.point1?.latitude),
    lng: Number(state?.point1?.longitude),
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      const watchId = navigator.geolocation.watchPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentPosition(currentPosition);
        },
        (error) => {
          console.error("Error accessing geolocation:", error);
        }
      );
      return () => navigator.geolocation.clearWatch(watchId); // Cleanup on unmount
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    const cleanup = getUserLocation();
    return () => cleanup(); // Cleanup on unmount
  }, []);

  const points = [
    {
      lat: Number(state?.point1?.latitude),
      lng: Number(state?.point1?.longitude),
    },
    {
      lat: Number(state?.point2?.latitude),
      lng: Number(state?.point2?.longitude),
    },
  ];

  return (
    <>
      <div className={styles.header}>
        <section>
          <h1>Set Of Routes</h1>
        </section>
        <section>
          <Button
            label={"Edit Route"}
            Icon={<FaRegEdit className="me-2" />}
            style={{ padding: "5px 10px" }}
          />
          <Button
            label={"Add Rest Day"}
            // Icon={<FaUmbrellaBeach className="me-2" />}
            style={{ padding: "5px 10px" }}
          />
        </section>
      </div>
      <div className={styles.timeBox}>
        <section className="d-flex align-items-center gap-2">
          <img src={byke} width={30} height={20} />
          <h5>Sagrada Familia</h5>
        </section>
        <section>
          <p>Start Date: 16/12/2022</p>
        </section>
        <section>
          <p>End Date: 22/12/2022</p>
        </section>
      </div>
      <section className={`my-3 ${styles.mapAndStatusBox}`}>
        <div className={styles.mapBox}>
          <GoogleMapPolyline
            center={center}
            path={points}
            currentPosition={currentPosition}
          />
        </div>
        <div className={styles.statusBox}>
          <h5>Status</h5>
          <section className="d-flex gap-3 mt-4">
            <div>
              <p>Oct 3</p>
              <small>1:00 PM</small>
            </div>
            <div>
              <p>Route Started</p>
              <small>
                Stage <HiOutlineArrowNarrowRight className="mx-2" /> Stage 2
              </small>
            </div>
          </section>
          <section className="d-flex gap-3 mt-4">
            <div>
              <p>Oct 3</p>
              <small>6:00 PM</small>
            </div>
            <div>
              <p>Reached at Stage 2</p>
              <small>On Time - Expected 6:15 PM</small>
            </div>
          </section>
          <section className="d-flex gap-3 mt-4">
            <div>
              <p>Oct 4</p>
              <small>11:00 PM</small>
            </div>
            <div>
              <p>Route Started</p>
              <small>
                Stage 2 <HiOutlineArrowNarrowRight className="mx-2" /> Stage 3
              </small>
            </div>
          </section>
          <section className="d-flex gap-3 mt-4">
            <div>
              <p>Oct 3</p>
              <small>1:00 PM</small>
            </div>
            <div>
              <p>Reached at Stage 3</p>
              <small>Late - Expected 4:15 PM</small>
            </div>
          </section>
        </div>
      </section>
      <section className="my-4">
        <div>
          <h4 className="fw-bolder">Stages</h4>
        </div>
        <section className={`m-0  row p-0  ${styles.inResponsive}`}>
          {pointDetailsCardData.slice(0, 3).map((value, index, arr) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-6 " key={index}>
                <Card
                  key={value.id}
                  // selectedRoute={selectedRoute}
                  // setSelectedRoute={setSelectedRoute}
                  value={value}
                  index={index}
                  arr={arr}
                />
              </div>
            );
          })}
        </section>
      </section>
      <section className="my-4">
        <div>
          <h4 className="fw-bolder">Points Of Interest</h4>
        </div>
        <section className={`m-0  row p-0  ${styles.inResponsive}`}>
          {pointDetailsCardData.slice(0, 5).map((value, index, arr) => {
            return (
              <div className="col-lg-3 col-md-4 col-sm-6 " key={index}>
                <Card
                  key={value.id}
                  type={"point"}
                  value={value}
                  index={index}
                  arr={arr}
                />
              </div>
            );
          })}
        </section>
      </section>
      <section className="my-4">
        <div>
          <h4 className="fw-bolder">Accomodations</h4>
        </div>
        <section className={`m-0  row p-0  ${styles.inResponsive}`}>
          {servicesData.slice(0, 2).map((value, index, arr) => {
            return (
              <div className="col-lg-5 col-md-4 col-sm-6 " key={index}>
                <ServicesCard
                  key={value.id}
                  type={"point"}
                  value={value}
                  index={index}
                  arr={arr}
                />
              </div>
            );
          })}
        </section>
      </section>
      <section className="my-4">
        <div>
          <h4 className="fw-bolder">Restoration</h4>
        </div>
        <section className={`m-0  row p-0  ${styles.inResponsive}`}>
          {servicesData.slice(0, 2).map((value, index, arr) => {
            return (
              <div className="col-lg-5 col-md-4 col-sm-6 " key={index}>
                <ServicesCard
                  key={value.id}
                  type={"point"}
                  value={value}
                  index={index}
                  arr={arr}
                />
              </div>
            );
          })}
        </section>
      </section>
      <section className="my-4">
        <div>
          <h4 className="fw-bolder">Events</h4>
        </div>
        <section className={`m-0 row p-0  ${styles.inResponsive}`}>
          {servicesData.slice(0, 3).map((value, index, arr) => {
            return (
              <div className="col-lg-5 col-md-4 col-sm-6  " key={index}>
                <ServicesCard
                  key={value.id}
                  type={"point"}
                  value={value}
                  index={index}
                  arr={arr}
                />
              </div>
            );
          })}
        </section>
      </section>
      <section className="my-4">
        <div>
          <h4 className="fw-bolder">Routes</h4>
        </div>
        <section className={`m-0 row p-0  ${styles.inResponsive}`}>
          {routesData.slice(0, 3).map((value, index, arr) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-6  " key={index}>
                <RoutesCard value={value} />
              </div>
            );
          })}
        </section>
      </section>
    </>
  );
}

export default SetOfRoutes;
