import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./swiper.css";
import styles from "./style.module.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

import ImagePlaceholder from "../../../Assets/ImagePlaceholder.png";

const ServiceSlider = ({ serviceData }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [secondaryThumbsSwiper, setSecondaryThumbsSwiper] = useState(null);

  const imagesArray =
    serviceData?.imagesArray?.length > 1
      ? serviceData?.imagesArray?.slice(1)
      : serviceData?.imagesArray;
  const videosArray = serviceData?.videos;
  const secondaryImages = serviceData?.secondaryImages;

  let combinedArray;
  if (imagesArray && videosArray) {
    combinedArray = imagesArray?.concat(videosArray);
  } else {
    combinedArray = imagesArray || videosArray;
  }
  // console.log(combinedArray)

  return (
    <section>
      {combinedArray && combinedArray?.length > 0 ? (
        <div className={`container  ${styles.sliderParent}`}>
          <Swiper
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-pagination-color": "#000",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {combinedArray &&
              combinedArray.length > 0 &&
              combinedArray?.map((item) => {
                if (item?.image) {
                  return (
                    <SwiperSlide>
                      <img
                        width="100%"
                        height={"300px"}
                        alt="slider"
                        src={`https://veyveback.com/${item.image}`}
                      />
                    </SwiperSlide>
                  );
                } else if (item) {
                  // console.log("item", item);
                  return (
                    <SwiperSlide>
                      <section key={item.id} className={styles.videoBox}>
                        <video width="100%" height={"100%"} controls>
                          <source src={`https://veyveback.com/${item}`} />
                          Your browser does not support the video tag.
                        </video>
                      </section>
                    </SwiperSlide>
                  );
                }
              })}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {combinedArray && combinedArray.length > 0 ? (
              combinedArray?.map((item) => {
                if (item?.image) {
                  return (
                    <SwiperSlide>
                      <img
                        width="100%"
                        height={"100px"}
                        alt="slider"
                        src={`https://veyveback.com/${item.image}`}
                      />
                    </SwiperSlide>
                  );
                } else if (item) {
                  // console.log("item", item);
                  return (
                    <SwiperSlide>
                      <section
                        key={item.id}
                        className={`${styles.thumbnailVideo}`}
                      >
                        <video>
                          <source src={`https://veyveback.com/${item}`} />
                          Your browser does not support the video tag.
                        </video>
                      </section>
                    </SwiperSlide>
                  );
                }
              })
            ) : (
              <h3>No Images or Videos Availabe</h3>
            )}
          </Swiper>
        </div>
      ) : (
        <div>
          <img
            width="100%"
            height={"300px"}
            alt="slider"
            src={ImagePlaceholder}
          />
        </div>
      )}

      {/* Seconary Slider */}
      {secondaryImages && secondaryImages?.length > 0 && (
        <div
          className={`container  ${styles.sliderParent} ${styles.secondarySliderParent}`}
        >
          <Swiper
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-pagination-color": "#000",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: secondaryThumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="secondaryMySwiper2"
          >
            {secondaryImages &&
              secondaryImages?.length > 0 &&
              secondaryImages?.map((item) => {
                if (item) {
                  return (
                    <SwiperSlide>
                      <img
                        width="100%"
                        height={"300px"}
                        alt="slider"
                        src={`https://veyveback.com/${item}`}
                      />
                    </SwiperSlide>
                  );
                }
              })}
          </Swiper>
          <Swiper
            onSwiper={setSecondaryThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="secondaryMySwiper"
          >
            {secondaryImages &&
              secondaryImages.length > 0 &&
              secondaryImages?.map((item) => {
                if (item) {
                  return (
                    <SwiperSlide>
                      <img
                        width="100%"
                        height={"100px"}
                        alt="slider"
                        src={`https://veyveback.com/${item}`}
                      />
                    </SwiperSlide>
                  );
                }
              })}
          </Swiper>
        </div>
      )}
    </section>
  );
};

export default ServiceSlider;
