import React from "react";
import { GrMap } from "react-icons/gr";
import { RiDeleteBinLine } from "react-icons/ri";
import styles from "./lists.module.css";
import API from "../../../../../Config/API";

const Lists = ({ muncipalityData, currentUser, deletListTrigger, setDeletListTrigger }) => {


  const followMunc = async () => {

    const data = {
      userId: currentUser?.id,
      muncipalityId: muncipalityData?.muncipalityId
    }

    try {
      const res = await API.post("/follow-muncipality", data);
      setDeletListTrigger(!deletListTrigger);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className={`${styles.listContainer} mt-2`}>
        <div className={`${styles.listDiv}`}>
          <GrMap fontSize={"2rem"} />
          <span>{muncipalityData?.muncipality?.address}</span>
        </div>
        <div className={`${styles.delIcon}`} onClick={followMunc}>
          <RiDeleteBinLine fontSize={"2rem"} />
        </div>
      </div>
    </>
  );
};

export default Lists;
