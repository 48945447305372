import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../../Config/API";
import RightSideContent from "../RightSideContent";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { watchModule } from "../../../Store/Actions/detailsModuleActions";
import RoutesSlider from "../RoutesSlider";

const InnerContent = ({ setTitle, cordinates }) => {
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [lang, setLang] = useState("Spanish");
  const [routesData, setRoutesData] = useState("");
  const [currentLoaction, setCurrentLocation] = useState("");
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentLocation(parsedLocation);
    setCurrentUser(parsedUser);
    // getPoints();
  }, [routesData, currentLocationUser]);

  // const getPoints = async () => {
  //   // Calling Language API
  //   try {
  //     if (!currentLocationUser) return;
  //     const res = await API.post("/get-root-lang", {
  //       language: lang,
  //       pointId: id,
  //       latitude:
  //         String(cordinates?.latitude) == "undefined"
  //           ? ""
  //           : String(cordinates?.latitude),
  //       longitude:
  //         String(cordinates?.longitude) == "undefined"
  //           ? ""
  //           : String(cordinates?.longitude),
  //     });
  //     const data = res?.data?.data;
  //     const sortedData = data?.filter((value) => {
  //       return value.id == id;
  //     });
  //     setRoutesData(sortedData[0]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  setTitle(routesData?.title);
  return (
    <div className={`d-flex justify-content-around ${styles.parent}`}>
      <section className={styles.child1}>
        <RoutesSlider routeData={routesData} lang={lang} />
      </section>
      <section className={styles.child2}>
        <RightSideContent
          cordinates={cordinates}
          routesData={routesData}
          lang={lang}
          setLang={setLang}
          setRoutesData={setRoutesData}
        />
      </section>
    </div>
  );
};

export default InnerContent;
