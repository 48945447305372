import React from "react";
import facebook from "../../Assets/facebook.png";
import twitter from "../../Assets/twitter.png";
import instagram from "../../Assets/instagram.png";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <footer>
      <div className={`${styles.footer}`}>
        <section className={styles.child1}>
          <div
            className="text-uppercase"
            onClick={() => navigate("/contact-us")}
          >
            {t("footer.contactUs")}
          </div>
          <div onClick={() => navigate("/aviso-legal")}>
            {t("footer.legalWarning")}
          </div>
          <div
            onClick={() => navigate("/política-de-cookies")}
            className="text-uppercase"
          >
            {t("footer.cookiesPolicy")}
          </div>
          <div
            onClick={() => navigate("/política-de-privacidad")}
            className="text-uppercase"
          >
            {t("footer.privacyPolicy")}
          </div>
          <div>{t("footer.termsOfUse")}</div>
          <div>{t("footer.accessibility")}</div>
          <div>{t("footer.do_not_sell_my_info")}</div>
          <div>{t("footer.customerService")}</div>
          <div className="text-center">
            @2019-2022 {t("footer.allRightsReserved")}
          </div>
        </section>
      </div>
      <section className={styles.child2}>
        <div>
          <img src={facebook} width="40px" alt="facebook" />
        </div>
        <div>
          <img src={instagram} width="40px" alt="instagram" />
        </div>
        <div>
          <img src={twitter} width="40px" alt="twitter" />
        </div>
      </section>
    </footer>
  );
};

export default Footer;
