import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import showVideoIcon from "../../../Assets/showVideoIcon.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import "./swiper.css";
import styles from "./style.module.css";

// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper";

import ImagePlaceholder from "../../../Assets/ImagePlaceholder.png";
import { useEffect } from "react";
import API from "../../../Config/API";

const RoutesSlider = ({ routeData, lang }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [signVideo, setSignVideo] = useState(false);

  const swiperRef = useRef();

  const imagesArray = routeData?.routesArray?.map(
    (v) => v.point1.imagesArraySpanish[0]
  );
  // const videosArray = routeData?.videos;
  // const secondaryImages = routeData?.secondaryImages;
  // const videosSigned = routeData?.videosSigned;

  let combinedArray;

  // const watchApi = async (index) => {
  //   await API.post("/watch", {
  //     type: "points",
  //     valueId: routeData?.id || "",
  //     language:
  //       combinedArray.length - 1 === index && routeData?.videosSigned
  //         ? "Signed"
  //         : lang,
  //   })
  //     .then(() => {})
  //     .catch(() => {});
  // };

  // if (imagesArray && videosArray) {
  //   combinedArray = imagesArray?.concat(videosArray);
  // } else {
  //   combinedArray = imagesArray || videosArray;
  // }
  // if (videosSigned) {
  //   combinedArray = [...combinedArray, videosSigned];
  // }
  useEffect(() => {
    if (
      swiperRef &&
      swiperRef?.current &&
      swiperRef?.current?.swiper &&
      signVideo
    ) {
      swiperRef?.current?.swiper?.slideTo(combinedArray?.length - 1);
    }
  }, [signVideo === true]);
  // console.log("routeData", routeData);
  return (
    <section>
      <div className={`container  ${styles.sliderParent}`}>
        {/* {routeData?.videosSigned && (
          <img
            src={showVideoIcon}
            onClick={() => setSignVideo(!signVideo)}
            className={styles.videoIcon}
          />
        )} */}

        <>
          <Swiper
            ref={swiperRef}
            style={{
              "--swiper-navigation-color": "#000",
              "--swiper-pagination-color": "#000",
            }}
            activeSlide={signVideo ? signVideo : ""}
            spaceBetween={10}
            navigation={true}
            initialSlide={0}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {imagesArray && imagesArray?.length > 0 ? (
              imagesArray?.map((item, index) => {
                return (
                  <SwiperSlide>
                    <img
                      width="100%"
                      height={"300px"}
                      alt="slider"
                      src={`https://veyveback.com/${item.image}`}
                    />
                  </SwiperSlide>
                );
              })
            ) : (
              <div className={styles.sliderParent}>
                <img
                  width="100%"
                  height={"300px"}
                  alt="slider"
                  src={ImagePlaceholder}
                />
              </div>
            )}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {imagesArray && imagesArray?.length > 0 ? (
              imagesArray?.map((item) => {
                return (
                  <SwiperSlide>
                    <img
                      width="100%"
                      height={"100px"}
                      alt="slider"
                      src={`https://veyveback.com/${item.image}`}
                    />
                  </SwiperSlide>
                );
              })
            ) : (
              <h3>No Images or Videos Availabe</h3>
            )}
          </Swiper>
        </>
      </div>
    </section>
  );
};

export default RoutesSlider;
