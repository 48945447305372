import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import EventsCart from "../../../Components/EventsCart";
import PremiumEventCard from "../../../Components/PremiumEventCard";
import API from "../../../Config/API";
import EventsCard from "../../../Components/EventsCard";
import Heading from "../../../Components/Heading";

const EventCard = ({ allData, setAllData, cordinates }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);

  const eventsDetails = () => {
    navigate("/event-details");
  };

  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);
  const user = localStorage.getItem("UserDetails");
  const parsedUser = JSON.parse(user);
  useEffect(() => {
    // Get User
    setCurrentUser(parsedUser);

    // Get Current Location

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      setIsApiCallInProgress(true);
      getEvents();
    }
  }, [currentUser, currentLang, currentLocation, cordinates]);

  function compareDistances(a, b) {
    const aDistance = a.events ? a.events[0].distance : a.distance;
    const bDistance = b.events ? b.events[0].distance : b.distance;

    return aDistance - bDistance;
  }

  const getEvents = async () => {
    setIsLoading(true);

    try {
      let res;
      if (currentUser) {
        res = await API.post("/get-events", {
          userId: currentUser?.id,
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      } else {
        res = await API.post("/get-events-web", {
          language: currentLang || "Spanish",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      }

      const events = res?.data?.data || [];
      const premiumEvents = res?.data?.premium;

      let filteredArray = premiumEvents.filter((item) => {
        return item.events.some(
          (event) => event.status.toLowerCase() === "active"
        );
      });

      // Filter only the events with status "Active"
      const activeEvents = events.filter(
        (event) => event.status?.toLowerCase() === "active"
      );
      // Combine active events and active premium events into a single array
      const allEvents = [...activeEvents, ...filteredArray];
      allEvents.sort(compareDistances);

      // Set the combined events to the "allData" state
      setAllData(allEvents);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div className="row pb-3">
      {/* {pointsOfInterest.map((value) => {
        return (
          <section
            key={value.id}
            className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
          >
            <PointsOfInterestCards onclick={pointsDetails} value={value} />
          </section>
        );
      })} */}
      {isLoading ? (
        <section className="d-flex justify-content-center align-items-center">
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        </section>
      ) : allData && allData?.length > 0 ? (
        allData?.map((value, ind) => {
          if (value?.events) {
            return (
              <section
                key={ind}
                className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
              >
                <PremiumEventCard value={value} />
              </section>
            );
          } else if (!value?.events) {
            return (
              <section
                key={value.id}
                className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
              >
                <EventsCard onclick={eventsDetails} value={value} />
              </section>
            );
          }
        })
      ) : (
        <Heading label={"No Events Found"} />
      )}
    </div>
  );
};

export default EventCard;
