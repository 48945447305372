import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
} from "@react-google-maps/api";
import userMarker from "../../../Assets/Marker.png";
import pointMarker from "../../../Assets/Group 17999.png";

const GoogleMapPolyline = ({ center, path, currentPosition }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyCPr-b3VlC4X08HU1jYqBPYeZrzt_Ci0SE",
  });

  return isLoaded ? (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "100%" }}>
          <GoogleMap
            // onClick={addPointToPath}
            mapContainerStyle={{ width: "100%", height: "300px" }}
            center={{ ...center }}
            zoom={6}
          >
            {/* =====Polyline===== */}
            <Polyline
              path={[...path, currentPosition]}
              options={{
                strokeColor: "#FF0000",
                strokeOpacity: 1,
                strokeWeight: 2,
              }}
            />
            {/* =====Marker===== */}
            {path.map((item, i) => (
              <Marker
                key={i}
                position={item}
                options={{
                  icon: {
                    url: pointMarker,
                    scaledSize: new window.google.maps.Size(30, 30),
                  },
                }}
              />
            ))}
            {currentPosition && (
              <Marker
                position={currentPosition}
                options={{
                  icon: {
                    url: userMarker,
                    scaledSize: new window.google.maps.Size(30, 30),
                  },
                }}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

export default GoogleMapPolyline;
