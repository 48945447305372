import API from "../../Config/API";
import { GET_USER } from "../Constants/userConstant";
import { showToast } from "./toastActions";

const getUserDetail = () => {
  const user = JSON.parse(localStorage.getItem("UserDetails"));
  return async (dispatch) => {
    if (user) {
      try {
        const res = await API.post("/get-user", {
          userId: user?.id,
        });
        if (res?.status === 200 || res?.status === true) {
          dispatch({
            type: GET_USER,
            payload: res.data.data,
          });
        }
      } catch (error) {}
    }
  };
};

export default getUserDetail;
