import React from "react";
import { pointDetailsCardData, routesData } from "../../../logics/Data";
import Card from "../Cards";
import styles from "./style.module.css";

function TouristCard() {
  return (
    <div className={styles.cardBoxParent}>
      <section>
        <h3>Tourist Points</h3>
      </section>
      <section className={`m-0  row p-0  ${styles.inResponsive}`}>
        {routesData.slice(0, 4).map((value, index, arr) => {
          return (
            <div className="col-lg-6 col-md-3 col-sm-6 " key={index}>
              <Card
                key={value.id}
                // selectedRoute={selectedRoute}
                // setSelectedRoute={setSelectedRoute}
                value={value}
                index={index}
                arr={arr}
              />
            </div>
          );
        })}
      </section>
    </div>
  );
}

export default TouristCard;
