import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../../Config/API";
import RightSideContent from "../RightSideContent";
import styles from "./styles.module.css";
import NewsSlider from "../NewsSlider";
import { watchModule } from "../../../Store/Actions/detailsModuleActions";
import { useDispatch } from "react-redux";
const InnerContent = ({ setTitle }) => {
  // Getting ID from Params
  const { id } = useParams();
  const [lang, setLang] = useState("Spanish");
  const [filteredNews, setFilteredNews] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getNews();
      setIsApiCallInProgress(true);
    }
  }, [currentLang, lang]);
  useEffect(() => {
    dispatch(
      watchModule({
        type: "news",
        valueId: id,
        userId: currentUser?.id || "",
      })
    );
  }, []);

  const getNews = async () => {
    // Calling Language API
    try {
      const res = await API.post("/get-news-lang", {
        language: lang,
        newsId: id,
      });
      if (res?.response?.status == 500) {
        setTitle("404");
      } else {
        const data = res?.data?.data;
        setFilteredNews(data);
        setTitle(data?.title);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div className={`d-flex justify-content-around ${styles.parent}`}>
      <section className={styles.child1}>
        <NewsSlider newsData={filteredNews} />
      </section>
      <section className={styles.child2}>
        <RightSideContent
          newsData={filteredNews}
          lang={lang}
          setLang={setLang}
          setNewsData={setFilteredNews}
        />
      </section>
    </div>
  );
};

export default InnerContent;
