import React from "react";
import styles from "./styles.module.css";
import { BsPlayFill, BsFillPauseFill } from "react-icons/bs";
import playGray from "../../Assets/Playicongrey.png";
const Audio = ({
  isPlaying,
  setIsplaying,
  audioElem,
  progress,
  setProgress,
  audioFile,
}) => {
  const playPause = () => {
    setIsplaying(!isPlaying);
  };
  // console.log("progress", progress);
  return (
    <div className={styles.parent}>
      <section className={styles.playPauseIcon}>
        {audioFile ? (
          <button onClick={playPause}>
            {isPlaying ? (
              <BsFillPauseFill color="white" />
            ) : (
              <BsPlayFill color="white" />
            )}
          </button>
        ) : (
          <img src={playGray} width={30} />
        )}
      </section>
      <section className={styles.preogressParetn}>
        <div
          style={{ width: progress ? progress?.progresss + "%" : "0" }}
        ></div>
      </section>
      <section className={styles.time}>1:00</section>
    </div>
  );
};

export default Audio;
