import React, { useEffect, useState } from "react";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import Audio from "../../../Components/Audio";
import FLagDropDown from "../../../Components/FlagDropDown";
import location from "../../../Assets/locationBlackIcon.png";
import rightArrow from "../../../Assets/rightArrow.png";
import flrgCircle from "../../../Assets/flagCircle.png";
import signatureimage from "../../../Assets/signatureimage.webp";
import manIcon from "../../../Assets/manIcon.png";
import clock from "../../../Assets/clockIcon.png";
import star from "../../../Assets/starIcon.png";
import star2 from "../../../Assets/starIcon2.png";
import download from "../../../Assets/downloadIcon.png";
import share from "../../../Assets/shareIcon.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import API from "../../../Config/API";
import DOMPurify from "dompurify";
import { TbPhone } from "react-icons/tb";
import { BsEnvelope, BsGlobe } from "react-icons/bs";
import Button from "../../../Components/Button";
import newsBall from "../../../Assets/newsBall.png";
import { useTranslation } from "react-i18next";
import { AiFillStar, AiOutlineFile, AiOutlineStar } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Store/Actions/toastActions";

const RightSideContent = ({ lang, setLang, newsData, setNewsData }) => {
  const { state } = useLocation();
  const { id } = useParams();
  const [isPlaying, setIsplaying] = useState(false);
  const [progress, setProgress] = useState();
  const [currentUser, setCurrentUser] = useState("");
  const [isFollowed, setIsFollowed] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const audioElem = useRef();
  const navigate = useNavigate();

  // Getting Audio Filename

  // const parts = url.split("/");
  // const audioFilename = parts[parts.length - 1];
  useEffect(() => {
    if (newsData?.audioFile) {
      if (isPlaying) {
        audioElem.current.play();
      } else {
        audioElem.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    // Get Current user
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 7000);
  }, [currentUser]);

  // useEffect(() => {
  //   handleChangeDrop();
  // }, [lang]);
  // const handleChangeDrop = async (e) => {
  //   // console.log(e.target.value);
  //   console.log("--->", newsData?.id);
  //   await API.post("/get-point-lang", {
  //     language: lang,
  //     pointId: id,
  //   })
  //     .then((res) => {
  //       setNewsData(res?.data?.data);
  //       console.log(res);
  //     })
  //     .catch((err) => console.log("err", err));
  // };

  const onPlaying = () => {
    const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;
    setProgress({ progresss: (ct / duration) * 100, length: duration });
  };

  const sanitizedDescription = DOMPurify.sanitize(newsData?.description);
  // console.log(sanitizedDescription)

  // Download File
  // const downloadFile = async (url) => {
  //   const response = await fetch(url);
  //   const blob = await response.blob();
  //   const link = document.createElement("a");
  //   link.href = URL.createObjectURL(blob);
  //   link.download = url.split("/").pop();
  //   link.click();
  // };

  // Get Categories
  // const getCategories = async () => {

  //   try {

  //     const res = await API.post("/get-categories-lang", {
  //       type: "news",
  //       language: lang
  //     });
  //     let categories = res?.data?.data;
  //     // console.log(res);
  //     // Match categoryId with categories.id
  //     const matchedCategory = categories?.find(category => category.id == newsData?.categoryId);
  //     setFilteredCategory(matchedCategory);
  //     // console.log(matchedCategory);

  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // Follow News
  const followNews = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "news",
          userId: currentUser?.id,
          valueId: newsData?.id,
        });
        dispatch(showToast(res?.data?.message, "success"));
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "news",
          userId: currentUser?.id,
          valueId: newsData?.id,
        });
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // Copy link to Clipboard
  const handleShareClick = () => {
    const currentURL = window.location.href;

    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        dispatch(showToast("URL Copied", "success"));
      })
      .catch((error) => {
        console.log("Failed to copy URL to clipboard:", error);
      });
  };

  return (
    <div>
      <section className="mb-3">
        <div className={styles.heading}>
          <Heading label={newsData?.title} />
        </div>
        <p className={styles.para}>
          {newsData?.publicationDate} {newsData?.publicationTime}
        </p>
      </section>
      <section className={`d-flex ${styles.dropDOwnAudioBox} align-items-end`}>
        {/* <div className={styles.audioBox}>
        </div> */}
        {/* <div className={``}>
          {newsData?.audioFile ? (
            <>
              <audio
                src={`https://veyveback.com/${newsData?.audioFile}`}
                ref={audioElem}
                onTimeUpdate={onPlaying}
              />
              <Audio
                isPlaying={isPlaying}
                setIsplaying={setIsplaying}
                audioElem={audioElem}
                progress={progress}
                setProgress={setProgress}
              />
            </>
          ) : (
            <p>No Audio</p>
          )}
        </div> */}
        <div className={`ms-3 ${styles.dropDownBox}`}>
          <FLagDropDown setName={setLang} />
          {/* <select
            style={{
              width: "200px",
              height: "30px",
            }}
            onChange={handleChangeDrop}
          >
            <option value="Spanish" selected>
              Spanish
            </option>
            <option value="Chinese">Chinese</option>
            <option value="English">English</option>
            <option value="French">French</option>
            <option value="Italian">Italian</option>
            <option value="Portuguese">Portuguese</option>
            <option value="German">German</option>
          </select> */}
        </div>
      </section>
      <section className={`my-3 ${styles.locationBox}`}>
        {/* <div className={styles.locationContent}>
          <img width="25" height="25" src={location} alt="icon" />
          <span className="ms-2">23 KM Away</span>
          <div>
            <img width="40" className="ms-3" src={rightArrow} alt="icon" />
          </div>
        </div> */}
        <section className={styles.locationIconBox}>
          <div className="d-flex align-items-center justify-content-between">
            <img
              width="30"
              className="mr-3"
              src={
                `https://veyveback.com/${newsData?.category?.image}` || newsBall
              }
              alt="icon"
            />
            <h6
              className="ml-3 mb-1"
              style={{ fontWeight: 900, color: "#35c89b", fontSize: "18px" }}
            >
              {newsData?.category?.name
                ? newsData?.category?.name
                : newsData?.category?.nameSpanish}
            </h6>
          </div>
        </section>
      </section>
      <section className="mt-3">
        <h6
          className="m-0 mb-1"
          style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}
        >
          {t("detailScreen.about")}
        </h6>
        {/* <p
          className={styles.para}
          style={{
            fontSize: "13px",
          }}
        >
          {
            newsData?.description
              ? newsData?.description?.replace(/<\/?[^>]+(>|$)/g, "")
              : "No Description"
          }
        </p> */}
        {newsData?.description ? (
          <section className={styles.descrptionBox}>
            <div
              style={{ letterSpacing: "1px", wordSpacing: "2px" }}
              className="text-muted mb-3"
              dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
            ></div>
          </section>
        ) : (
          <p
            className={styles.para}
            style={{
              fontSize: "13px",
            }}
          >
            {" "}
            No Description
          </p>
        )}
      </section>

      {/* <section className="my-4">
        <div className="d-flex justify-content-between w-50">
          <h6
            className="m-0 mb-2"
            style={{ fontWeight: 900, color: "#000", fontSize: "15px" }}
          >
            Publication Date :{" "}
            <p className="text-muted">
              {newsData?.publicationDate} - {newsData?.publicationTime}
            </p>
          </h6>
        </div>
      </section> */}
      <section>
        <div>
          <h6
            className="m-0 mb-2"
            style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}
          >
            {t("detailScreen.attatchments")}
          </h6>
        </div>
        <div>
          {newsData?.attachedDocuments &&
          newsData?.attachedDocuments?.length > 0 ? (
            newsData?.attachedDocuments?.map((fileUrl) => (
              <a download={true} href={`https://veyveback.com/${fileUrl}`}>
                <AiOutlineFile
                  key={fileUrl}
                  className="mx-2"
                  size={40}
                  style={{ color: "#35c89b", cursor: "pointer" }}
                />
              </a>
            ))
          ) : (
            <h3>No Files</h3>
          )}
        </div>
      </section>
      <section>
        {/* <h6 className="m-0 my-3" style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}>
          Ticket Price{" "}
          <span className={`${styles.ticket}`}>
            {newsData?.price ? newsData.price : "50"}
          </span>
        </h6> */}
        <div className={`${styles.flagBox} my-3`}>
          <section
            className={styles.cirCleImgBox}
            onClick={() =>
              navigate(
                `/muncipality/${newsData?.muncipality?.title}/${newsData?.muncipality?.id}`
              )
            }
          >
            <img
              src={
                newsData?.signatureImage
                  ? `https://veyveback.com/${newsData?.signatureImage}`
                  : manIcon
              }
              width="100%"
              alt="signature"
            />
          </section>
          <section className="ms-3">
            <div>
              <h6
                className="m-0 mb-2"
                style={{ fontWeight: 600, color: "#000" }}
              >
                {t("detailScreen.organizerName")}
              </h6>
              <p className="m-0 mb-2 text-muted">
                {newsData?.muncipality?.title}
              </p>
            </div>
            <div className={`${styles.organizerIconsBox}`}>
              {newsData?.phone && (
                <Link className="mx-2" to={`tel:${newsData?.phone}`}>
                  <TbPhone color={"#000"} size={27} />
                </Link>
              )}
              {newsData?.websiteLink && (
                <Link
                  className="mx-2"
                  to={`${newsData?.websiteLink}`}
                  target="_blank"
                >
                  <BsGlobe color={"#000"} size={27} />
                </Link>
              )}
              {newsData?.email && (
                <Link className="mx-2" to={`mailto:${newsData?.email}`}>
                  <BsEnvelope color={"#000"} size={27} />
                </Link>
              )}
            </div>
          </section>
        </div>
      </section>
      <section className={styles.actionsBox}>
        {/* <section className={styles.btnBox}>
          <Button label={"Buy Ticket"} width={"100%"} />
        </section> */}
        {currentUser && (
          <section className={`${styles.iconBox}`}>
            {/* <div>
            <img src={clock} width="20px" alt="icon" />
          </div> */}
            <div className="mx-3" onClick={followNews}>
              {isFollowed ? (
                <AiFillStar size={20} color="#34C89A" />
              ) : (
                <AiOutlineStar size={20} color="#34C89A" />
              )}
            </div>
            {/* <div>
              <img src={download} width="20px" alt="icon" />
            </div> */}
            <div className="ms-3" onClick={handleShareClick}>
              <img src={share} width="20px" alt="icon" />
            </div>
          </section>
        )}
      </section>
    </div>
  );
};

export default RightSideContent;
