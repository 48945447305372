import React, { useState } from "react";
import CustomHeader from "../../Components/CustomHeader";
import Heading from "../../Components/Heading";
import HeaderBox from "../Home/HeaderBox";
import routesIcon from "../../Assets/routesIcon.png";
import RouteCard from "./RouteCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { navAction } from "../../Store/Actions/navAction";
import { useTranslation } from "react-i18next";
import styles from "./style.module.css";

const RoutesPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState("todos");
  const [allData, setAllData] = useState([]);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("routes"));
  }, []);

  return (
    <div>
      <CustomHeader icon={false} setAllDat={setAllData} allData={allData} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox btnValue={t("Search")} />
        <div className="container">
          <Heading
            label={t("routes")}
            img={routesIcon}
            bottom="8px"
            width="80px"
          />

          <ul className={styles.tabs}>
            <li
              onClick={() => setTabs("todos")}
              style={{
                color: tabs == "todos" ? "#35c89b" : "#000",
                borderBottom: tabs == "todos" ? "1px solid #35c89b" : "",
                transition: "0.5s",
              }}
            >
              Todos
            </li>
            <li
              onClick={() => setTabs("urbans")}
              style={{
                color: tabs == "urbans" ? "#35c89b" : "#000",
                borderBottom: tabs == "urbans" ? "1px solid #35c89b" : "",
                transition: "0.5s",
              }}
            >
              Urbans
            </li>
            <li
              onClick={() => setTabs("rusticas")}
              style={{
                color: tabs == "rusticas" ? "#35c89b" : "#000",
                borderBottom: tabs == "rusticas" ? "1px solid #35c89b" : "",
                transition: "0.5s",
              }}
            >
              Rusticas
            </li>
            <li
              onClick={() => setTabs("premium")}
              style={{
                color: tabs == "premium" ? "#35c89b" : "#000",
                borderBottom: tabs == "premium" ? "1px solid #35c89b" : "",
                transition: "0.5s",
              }}
            >
              Premium
            </li>
          </ul>

          <RouteCard allData={allData} setAllData={setAllData} tabs={tabs} />
        </div>
      </div>
    </div>
  );
};

export default RoutesPage;
