import React from "react";
import Slider from "react-slick";
import { HiOutlineChevronLeft, HiOutlineChevronRight } from "react-icons/hi";
import EventsCard from "../EventsCard";
import styles from "./style.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PointsOfInterstCart from "../PointsOfInterstCart";
import ServicesCard from "../ServicesCard";
import RoutesCard from "../RoutesCard";
import NewsCard from "../NewsCard";
import PremiumEventCard from "../PremiumEventCard";
import useWindowDimensions from "../../logics/useWindowDimension";
import PremiumPointsOfInterstCard from "../PremiumPointsOfInterstCard";
import HeadPremiumsCard from "../HeadPremiumsCard";

const PreviousBtn = (props) => {
  const { className, onClick } = props;
  return (
    <>
      <div onClick={onClick} className={styles.preBtn}>
        <HiOutlineChevronLeft
          className={className}
          onClick={onClick}
          style={{
            color: "gray",
            fontSize: "10px",
            padding: "8px",
            width: "35px",
            height: "35px",
            position: "absolute",
            background: "#fff",
            borderRadius: "50%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            zIndex: 99,
          }}
        />
      </div>
    </>
  );
};
const NextBtn = (props) => {
  const { className, onClick } = props;
  return (
    <>
      <div onClick={onClick} className={styles.nextBtn}>
        <HiOutlineChevronRight
          className={className}
          onClick={onClick}
          style={{
            color: "gray",
            fontSize: "10px",
            padding: "8px",
            width: "35px",
            height: "35px",
            position: "absolute",
            background: "#fff",
            borderRadius: "50%",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          }}
        />
      </div>
    </>
  );
};

const CardsSlider = ({ data, name }) => {
  let { width } = useWindowDimensions();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={`container  ${styles.parent}`}>
      {data?.length > 4 ||
      width < 558 ||
      (data?.length === 3 && width < 1024) ? (
        <Slider {...settings} className={styles.slider}>
          {data?.map((value) => {
            return (
              <div key={value.id}>
                {name === "pointsOfInterest" ? (
                  value.points ? (
                    <PremiumPointsOfInterstCard value={value} />
                  ) : value.premiums ? (
                    <HeadPremiumsCard value={value} />
                  ) : (
                    <PointsOfInterstCart value={value} />
                  )
                ) : name === "services" ? (
                  <ServicesCard value={value} />
                ) : name === "routes" ? (
                  <RoutesCard value={value} />
                ) : name === "news" ? (
                  <NewsCard value={value} />
                ) : name === "events" ? (
                  value?.events ? (
                    <PremiumEventCard value={value} />
                  ) : (
                    <EventsCard value={value} />
                  )
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </Slider>
      ) : (
        <div className="d-flex flex-wrap">
          {data?.map((value, ind) => {
            return (
              <div key={value.id} className={styles.unSliderCard}>
                {name === "pointsOfInterest" ? (
                  <PointsOfInterstCart value={value} />
                ) : name === "services" ? (
                  <ServicesCard value={value} />
                ) : name === "routes" ? (
                  <RoutesCard value={value} />
                ) : name === "news" ? (
                  <>
                    <NewsCard value={value} />
                  </>
                ) : name === "events" ? (
                  value?.events ? (
                    <PremiumEventCard value={value} />
                  ) : (
                    <EventsCard value={value} />
                  )
                ) : (
                  ""
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CardsSlider;
