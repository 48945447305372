import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/Button";
import Heading from "../../Components/Heading";
import { navAction } from "../../Store/Actions/navAction";
import AddedEvents from "./AddedEvents";
import GeneralDataForm from "./GeneralDataForm";
import LocationData from "./LocationData";
import MultimediaData from "./MultimediaData";
import styles from "./style.module.css";
import { showToast } from "../../Store/Actions/toastActions";
import { Spinner } from "react-bootstrap";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AudioData from "./AudioData/AudioData";
import VideoData from "./VideoData";
import Gallery from "../../Components/Gallery";
import AddSecondaryImages from "./AddSecondaryImages";
import CustomHeader from "../../Components/CustomHeader";
import { handleCreateEvent_Action } from "../../Store/Actions/createEvent";
import { useTranslation } from "react-i18next";

const AddEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [generalData, setGeneralData] = useState({
    title: "",
    address: "",
    publicationTime: "",
    publicationRange: "",
    price: "",
    telephone: "",
    email: "",
    websiteLink: "",
    muncipalityId: "",
    description: "",
    lat: "",
    lng: "",
    imagesArray: [],
    audioFile: "",
    signatureImage: "",
    videos: [],
    categoryId: "",
    status: "Pending",
    type: "Public",
    notificationType: "dontSend",
  });

  const [originalImage, setOriginalImage] = useState("");
  const [listingImage, setListingImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [originalVideo, setOriginalVideo] = useState("");
  const [listingVideo, setListingVideo] = useState("");
  const [bannerVideo, setBannerVideo] = useState("");
  const [multyImagesText, setMultyImagesText] = useState([]);
  const [multyVideos, setMultyVideos] = useState([]);
  const [logoImage, setLogoImage] = useState("");
  const [audio, setAudio] = useState("");
  const [addEvents, setAddEvents] = useState([]);
  const [userId, setUserID] = useState("");
  const [showGallery, setShowGallery] = useState(false);
  const [galleryImage, setGalleryImage] = useState("");
  const [showMuliMediaModal, setShowMuliMediaModal] = useState(false);
  const [addPointModal, setAddPointModal] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  const handleGetImage = (path) => {
    setGalleryImage(path);
    setShowGallery(false);
    if (showMuliMediaModal) {
      setShowMuliMediaModal(true);
    }
    if (addPointModal) {
      setAddPointModal(true);
    }
    if (showSecondaryModal) {
      setShowSecondaryModal(true);
    }
  };

  const eventState = useSelector((state) => state.getEventsReducers);
  let getmuncipalityId = localStorage.getItem("muncipalityId");

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setUserID(parsedUser?.id);
  }, []);

  useEffect(() => {
    dispatch(navAction("Points Of interest"));
    // dispatch(handleGetCategory_Action("point", currentUser?.id));
  }, []);

  const handleCreateEvent = async () => {
    if (
      !generalData.lng ||
      !generalData.lat ||
      !generalData.title ||
      !generalData.description ||
      !getmuncipalityId ||
      !generalData.price ||
      !generalData.imagesArray.length == 0 ||
      !generalData.videos.length == 0
    ) {
      dispatch(showToast("Required Fileds are Missing", "error"));
    } else {
      await dispatch(handleCreateEvent_Action({ ...generalData }, navigate));
    }
  };

  // const handleNavigate = () => {
  //   navigate("/notice-board/add-premium-points");
  // };

  return (
    <>
      {showGallery ? (
        <div className={`${styles.formBox}`}>
          <Gallery handleGetImage={handleGetImage} />
        </div>
      ) : (
        <>
          <section className="pb-3">
            <CustomHeader label1={t("events")} label2={t("Add-Event")} />
            {/* <CustomButton
              onClick={handleNavigate}
              label={"Add Premium Point of Interest"}
              icon={<BiPlusCircle />}
            /> */}
          </section>
          <div className="container">
            <div className={styles.formBox}>
              <GeneralDataForm
                generalData={generalData}
                setGeneralData={setGeneralData}
              />
            </div>
            {/* <div className={`my-5 ${styles.formBox}`}>
            <MultimediaData
              setShowGallery={setShowGallery}
              galleryImage={galleryImage}
              showGallery={showGallery}
              setGalleryImage={setGalleryImage}
              originalImage={originalImage}
              setOriginalImage={setOriginalImage}
              listingImage={listingImage}
              setListingImage={setListingImage}
              bannerImage={bannerImage}
              setBannerImage={setBannerImage}
              logoImage={logoImage}
              setLogoImage={setLogoImage}
              MultyImagesText={multyImagesText}
              setMultyImagesText={setMultyImagesText}
              showModal={showMuliMediaModal}
              setShowModal={setShowMuliMediaModal}
            />
          </div> */}
            {/* <div className={`my-5 ${styles.formBox}`}>
            <AudioData setAudio={setAudio} audio={audio} />
          </div> */}
            <div className={`my-5 ${styles.formBox}`}>
              <VideoData
                setMultyVideos={setMultyVideos}
                multyVideos={multyVideos}
                originalVideo={originalVideo}
                setOriginalVideo={setOriginalVideo}
                listingVideo={listingVideo}
                setListingVideo={setListingVideo}
                bannerVideo={bannerVideo}
                setBannerVideo={setBannerVideo}
              />
            </div>
            <div className={styles.formBox}>
              <LocationData
                generalData={generalData}
                setGeneralData={setGeneralData}
              />
            </div>
            <div className={`my-5 ${styles.formBox}`}>
              <AddedEvents
                addEvents={addEvents}
                setAddEvents={setAddEvents}
                setShowGallery={setShowGallery}
                showModal={addPointModal}
                setShowModal={setAddPointModal}
                galleryImage={galleryImage}
                setGalleryImage={setGalleryImage}
              />
            </div>
            {/* <div className={`my-5 ${styles.formBox}`}>
            <AddSecondaryImages
              showModal={showSecondaryModal}
              setShowModal={setShowSecondaryModal}
              setShowGallery={setShowGallery}
              galleryImage={galleryImage}
              setGalleryImage={setGalleryImage}
              secondaryImages={secondaryImages}
              setSecondaryImages={setSecondaryImages}
            />
          </div> */}
            <div className="d-flex justify-content-center align-items-center mb-2">
              {eventState.isLoading ? (
                <Spinner style={{ color: "#35C89B" }} />
              ) : (
                <CustomButton
                  onClick={handleCreateEvent}
                  label={t("Upload-Event")}
                  width="50%"
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddEvent;
