import bannerImg from "../../Assets/banner.png";
import mobBanner from "../../Assets/MobBanner.png";
import card1 from "../../Assets/card1.png";
import card2 from "../../Assets/card2.png";
import card3 from "../../Assets/card3.png";
import card4 from "../../Assets/card4.png";
import card5 from "../../Assets/card5.png";
import card6 from "../../Assets/card6.png";
import card7 from "../../Assets/card7.png";
import card8 from "../../Assets/card8.png";
import pointCard1 from "../../Assets/ImagePlaceholder.png";
import pointCard2 from "../../Assets/pointCard2.png";
import pointcard3 from "../../Assets/pointCard3.png";

const bannerData = [{ img: bannerImg }, { img: bannerImg }, { img: bannerImg }];
const mobBannerData = [
  { img: mobBanner },
  { img: mobBanner },
  { img: mobBanner },
];

const eventsCardData = [
  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 1,
  },

  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 2,
  },
  // {
  //   eventName: "Concert (Monday 10:30 PM)",
  //   eventDescription: "Palay de la Musica Catalana",
  //   eventImg: card1,
  //   id: 3,
  // },

  // {
  //   eventName: "Music Night (Monday 11:30 PM)",
  //   eventDescription: "Palay de la Musica Catalana",
  //   eventImg: card2,
  //   id: 4,
  // },
  // {
  //   eventName: "Concert (Monday 10:30 PM)",
  //   eventDescription: "Palay de la Musica Catalana",
  //   eventImg: card1,
  //   id: 5,
  // },

  // {
  //   eventName: "Music Night (Monday 11:30 PM)",
  //   eventDescription: "Palay de la Musica Catalana",
  //   eventImg: card2,
  //   id: 6,
  // },
  // {
  //   eventName: "Concert (Monday 10:30 PM)",
  //   eventDescription: "Palay de la Musica Catalana",
  //   eventImg: card1,
  //   id: 7,
  // },

  // {
  //   eventName: "Music Night (Monday 11:30 PM)",
  //   eventDescription: "Palay de la Musica Catalana",
  //   eventImg: card2,
  //   id: 8,
  // },
];
const pointsOfInterest = [
  {
    eventName: "Opera",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card6,
    id: 1,
  },

  {
    eventName: "Football Match (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card3,
    id: 2,
  },
  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 3,
  },

  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 4,
  },
  {
    eventName: "Opera",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card6,
    id: 5,
  },

  {
    eventName: "Football Match (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card3,
    id: 6,
  },
  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 7,
  },

  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 8,
  },
];
const servicesData = [
  {
    title: "Madrid XYZ Hotel",
    address: "Sala  Upload Barcelona",
    image: card7,
    id: 1,
  },

  {
    title: "Hotel XYZ",
    address: "Palay de la Musica Catalana",
    image: card8,
    id: 2,
  },
  {
    title: "Restaurant XYZ",
    address: "Palay de la Musica Catalana",
    image: card4,
    id: 3,
  },

  {
    title: "Madrid Bar XYZ",
    address: "Palay de la Musica Catalana",
    image: card5,
    id: 4,
  },
  {
    title: "Madrid XYZ Hotel",
    address: "Sala  Upload Barcelona",
    image: card7,
    id: 5,
  },

  {
    title: "Hotel XYZ",
    address: "Palay de la Musica Catalana",
    image: card8,
    id: 6,
  },
  {
    title: "Restaurant XYZ",
    address: "Palay de la Musica Catalana",
    image: card4,
    id: 7,
  },

  {
    title: "Madrid Bar XYZ",
    address: "Palay de la Musica Catalana",
    image: card5,
    id: 8,
  },
];
const routesData = [
  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 1,
  },

  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 2,
  },
  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 3,
  },

  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 4,
  },
  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 5,
  },

  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 6,
  },
  {
    eventName: "Concert (Monday 10:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card1,
    id: 7,
  },

  {
    eventName: "Music Night (Monday 11:30 PM)",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: card2,
    id: 8,
  },
];
const newsData = [
  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 1,
  },

  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 2,
  },
  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 3,
  },

  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 4,
  },
  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 5,
  },

  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 6,
  },
  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 7,
  },

  {
    eventName: "Tomatine Festival",
    eventDescription: "Publisher Name",
    eventImg: card1,
    id: 8,
  },
];
const pointDetailsCardData = [
  {
    eventName: "Casa Battlo Madrid",
    eventDescription: "Palau de la Musica Catalona",
    eventImg: pointCard1,
    km: "19",
    category: "premium",
    id: 1,
  },

  {
    eventName: "Plaza Mayor Madrid",
    eventDescription: "Palau de la Musica Catalona",
    eventImg: pointCard2,
    km: "20",
    category: "local",
    id: 2,
  },
  {
    eventName: "Sagrada Familia Madrid",
    eventDescription: "Palay de la Musica Catalana",
    eventImg: pointcard3,
    km: "23",
    category: "local",
    id: 3,
  },

  {
    eventName: "Casa Battlo Madrid",
    eventDescription: "Palau de la Musica Catalona",
    eventImg: pointCard1,
    km: "19",
    category: "premium",
    id: 4,
  },
  {
    eventName: "Plaza Mayor Madrid",
    eventDescription: "Palau de la Musica Catalona",
    eventImg: pointCard2,
    category: "local",
    km: "20",
    id: 5,
  },
  {
    eventName: "Plaza Mayor Madrid",
    eventDescription: "Palau de la Musica Catalona",
    eventImg: pointCard2,
    category: "local",
    km: "20",
    id: 6,
  },
];
export {
  bannerData,
  eventsCardData,
  pointsOfInterest,
  servicesData,
  routesData,
  newsData,
  mobBannerData,
  pointDetailsCardData,
};
