import React from "react";
import AllLinks from "./AllLinkSections/AllLinks";
import SideLinksBar from "./SideLinksBar/SideLinksBar";
import styles from "./userdetails.module.css";
const UserDetails = () => {
  return (
    <>
      <div className={`${styles.mainDiv}`}>
        <SideLinksBar />
        <AllLinks />
      </div>
    </>
  );
};

export default UserDetails;
