import React, { useEffect, useState } from "react";
import Button from "../Button";
import styles from "./style.module.css";
import { IoIosArrowUp } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";
import logo from "../../Assets/logo.png";
import useWindowDimensions from "../../logics/useWindowDimension";
import { useDispatch, useSelector } from "react-redux";
import { navAction, navLangAction } from "../../Store/Actions/navAction";
import { useNavigate } from "react-router-dom";
import V from "../../Assets/V.ico";
import { profileAction } from "../../Store/Actions/profileAction";
import FlagDropDown from "../FlagDropDown";
import { useTranslation } from "react-i18next";
import API from "../../Config/API";

const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    dispatch(navLangAction(name));
  }, [name]);

  useEffect(() => {
    localStorage.setItem("userLanguage", name);
    storeLanguage();
  }, [name]);

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  const active = useSelector((state) => state.navReducer.activeNav);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navActiveChange = (activeNav, route) => {
    dispatch(navAction(activeNav));
    navigate(route);
  };
  const ProfileActiveChange = (activeProfile, route) => {
    dispatch(profileAction(activeProfile));
    navigate(route);
  };

  const storeLanguage = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/store-language", {
          userId: currentUser?.id,
          language: name,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const { width } = useWindowDimensions();

  return (
    <>
      <div className={styles.nav_parent}>
        <section className={styles.logo}>
          <section className={styles.menu_bar}>
            <AiOutlineMenu onClick={() => setShowSidebar(!showSidebar)} />
          </section>
          <img
            src={logo}
            alt="logo"
            width="120px"
            onClick={() => navActiveChange("home", "/")}
          />
        </section>
        <section className={styles.navlinks}>
          <ul>
            <li
              style={{
                color: active === "home" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "home" ? 500 : 400,
              }}
              onClick={() => navActiveChange("home", "/")}
            >
              {t("home")}
            </li>
            <li
              style={{
                color: active === "events" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "events" ? 500 : 400,
              }}
              onClick={() => navActiveChange("events", "/events")}
            >
              {t("events")}
            </li>
            <li
              style={{
                color: active === "pointOfinterest" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "pointOfinterest" ? 500 : 400,
              }}
              onClick={() =>
                navActiveChange("pointOfinterest", "/points-of-interest")
              }
            >
              {t("pointsOfInterest")}
            </li>
            <li
              style={{
                color: active === "map" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "map" ? 500 : 400,
              }}
              onClick={() => navActiveChange("map", "/map")}
            >
              {t("map")}
            </li>
            <li
              style={{
                color: active === "services" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "services" ? 500 : 400,
              }}
              onClick={() => navActiveChange("services", "/services")}
            >
              {t("services")}
            </li>
            <li
              style={{
                color: active === "news" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "news" ? 500 : 400,
              }}
              onClick={() => navActiveChange("news", "/news")}
            >
              {t("news")}
            </li>
            <li
              style={{
                color: active === "routes" ? "#34c89a" : "#5d5b5b",
                fontWeight: active === "routes" ? 500 : 400,
              }}
              onClick={() => navActiveChange("routes", "/routes")}
            >
              {t("routes")}
            </li>
          </ul>
        </section>

        {localStorage.getItem("UserDetails") ? (
          <section className="d-flex justify-content-center align-items-center">
            <FlagDropDown setName={setName} type={"navbar"} />
            <div
              onClick={() =>
                ProfileActiveChange("My Profile", "/user-details/my-profile")
              }
            >
              <img
                src={V}
                // className={`${styles.vicon}`}
                style={{
                  width: "53px",
                  cursor: "pointer",
                }}
                alt="V Logo"
              />
            </div>
          </section>
        ) : (
          <section className={styles.nav_btn_box}>
            <FlagDropDown setName={setName} type={"navbar"} />
            <Button
              onClick={() => navigate("/register")}
              label={width >= 900 ? t("signup-with-gamil") : t("signup")}
              width={width >= 900 ? "200px" : "120px"}
            />
          </section>
        )}
      </div>

      <div
        className={styles.sidebar_parent}
        style={{
          opacity: showSidebar ? 1 : 0,
          visibility: showSidebar ? "visible" : "hidden",
          transition: showSidebar ? "0.5s ease" : "0.5s ease",
          transform: showSidebar ? "translateY(0)" : "translateY(-20px)",
          background: "rgba(53, 200, 155, 0.9)",
          zIndex: 999,
        }}
      >
        <ul>
          <li className={styles.arrowUp}>
            <IoIosArrowUp onClick={() => setShowSidebar(!showSidebar)} />
          </li>
          <li
            style={{
              color: active === "home" ? "#171717" : "#fff",
              fontWeight: active === "home" ? 500 : 400,
            }}
            onClick={() => {
              navActiveChange("home", "/");
              setShowSidebar(!showSidebar);
            }}
          >
            Home
          </li>
          <li
            style={{
              color: active === "events" ? "#171717" : "#fff",
              fontWeight: active === "events" ? 500 : 400,
            }}
            onClick={() => {
              navActiveChange("events", "/events");
              setShowSidebar(!showSidebar);
            }}
          >
            Events
          </li>
          <li
            style={{
              color: active === "pointOfinterest" ? "#171717" : "#fff",
              fontWeight: active === "pointOfinterest" ? 500 : 400,
            }}
            onClick={() => {
              navActiveChange("pointOfinterest", "/points-of-interest");
              setShowSidebar(!showSidebar);
            }}
          >
            Point Of Interests
          </li>
          <li
            style={{
              color: active === "map" ? "#171717" : "#fff",
              fontWeight: active === "map" ? 500 : 400,
            }}
            onClick={() => navActiveChange("map", "/map")}
          >
            Map
          </li>
          <li
            style={{
              color: active === "services" ? "#171717" : "#fff",
              fontWeight: active === "services" ? 500 : 400,
            }}
            onClick={() => {
              navActiveChange("services", "/services");
              setShowSidebar(!showSidebar);
            }}
          >
            Services
          </li>
          <li
            style={{
              color: active === "news" ? "#171717" : "#fff",
              fontWeight: active === "news" ? 500 : 400,
            }}
            onClick={() => {
              navActiveChange("news", "/news");
              setShowSidebar(!showSidebar);
            }}
          >
            News
          </li>
          <li
            style={{
              color: active === "routes" ? "#171717" : "#fff",
              fontWeight: active === "routes" ? 500 : 400,
            }}
            onClick={() => {
              navActiveChange("routes", "routes");
              setShowSidebar(!showSidebar);
            }}
          >
            Routes
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
