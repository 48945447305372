import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Audio from "../../Components/Audio";
import CustomHeader from "../../Components/CustomHeader";
import { navAction } from "../../Store/Actions/navAction";
import FlagDropDown from "../../Components/FlagDropDown";
import styles from "./style.module.css";
import Card from "./Cards";
import { pointDetailsCardData } from "../../logics/Data";
import RoutesSlider from "./RoutesSlider";
import Heading from "../../Components/Heading";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
const RoutesDetails = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [name, setName] = useState("");

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("routes"));
  }, []);

  return (
    <div>
      <CustomHeader label1="Routes" label2={state.title} />
      <div className="container py-5 f">
        <section className={`my-3 overflow-hidden ${styles.responsive}`}>
          {/* <Heading label="Sagrada Familia" /> */}
          <RoutesSlider allData={state?.routesArray} />
        </section>
        {/* <section className={styles.dropDownAndAudio}>
          <div>
            <Audio />
          </div>
          <div className="ms-3">
            <FlagDropDown setName={setName} />
          </div>
        </section> */}

        <section className={`my-5 row p-0 m-0 ${styles.inResponsive}`}>
          {state.routesArray.map((value, index, arr) => {
            return (
              <Card key={value.id} value={value} index={index} arr={arr} />
            );
          })}
        </section>
      </div>
    </div>
  );
};

export default RoutesDetails;
