import axios from 'axios';

const API = axios.create({
    baseURL: 'https://veyveback.com/api/',
    // baseURL: "https://veyveback.com/api",
});

// Add request interceptor
API.interceptors.request.use(
    config => {
        // Add any headers, authentication data, or other configuration here
        return config;
    },
    error => {
        // Handle request error
        return error
    }
);

// Add response interceptor
API.interceptors.response.use(
    response => {
        // Handle successful response
        return response;
    },
    error => {
        // Handle response error
        return error
    }
);

export default API;