import React, { useEffect, useState } from "react";
import ServicesCards from "../../../Components/ServicesCard";
import API from "../../../Config/API";
import Heading from "../../../Components/Heading";
import { Spinner } from "react-bootstrap";

const ServicesCard = ({ allData, setAllData, cordinates }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Current Location

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getServices();
      setIsApiCallInProgress(true);
    }
  }, [currentUser, currentLang, currentLocation]);
  const compareDistances = (a, b) => {
    return a.distance - b.distance;
  };

  const getServices = async () => {
    setIsLoading(true);

    try {
      let res;
      if (currentUser) {
        res = await API.post("/get-services", {
          userId: currentUser?.id,
          latitude: String(cordinates?.latitude) || "",
          longitude: String(cordinates?.longitude) || "",
          distance: 10000,
        });
      } else {
        res = await API.post("/get-services-web", {
          language: currentLang || "Spanish",
          latitude: String(cordinates?.latitude) || "",
          longitude: String(cordinates?.longitude) || "",
          distance: 10000,
        });
      }

      let activeServices = res?.data?.data?.filter(
        (event) => event.status === "Active"
      );
      activeServices.sort(compareDistances);
      setAllData(activeServices);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div className="row pb-3">
      {isLoading ? (
        <section className="d-flex justify-content-center align-items-center">
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        </section>
      ) : allData && allData?.length > 0 ? (
        allData?.map((value) => (
          // console.log(value);
          <section className="col-lg-3 col-md-4 col-sm-6 col-12 my-2">
            <ServicesCards key={value?.id} value={value} />
          </section>
        ))
      ) : (
        <Heading label={"No Services Found"} />
      )}
    </div>
  );
};

export default ServicesCard;
