import React, { useEffect, useState } from "react";
import RoutesCards from "../../../Components/RoutesCard";
import { routesData } from "../../../logics/Data";
import { Spinner } from "react-bootstrap";
import API from "../../../Config/API";

const EventsCard = ({ setAllData, allData, tabs }) => {
  const [isLoading, setIsLoading] = useState(true);

  const user = localStorage.getItem("UserDetails");
  const parsedUser = JSON.parse(user);
  const userLang = localStorage.getItem("userLanguage");

  const getRoutes = async () => {
    let response;
    try {
      if (parsedUser) {
        response = await API.post("get-roots", { userId: parsedUser?.id });
      } else {
        response = await API.post("get-roots-web", {
          language: userLang || "Spanish",
        });
      }
      let filtered =
        tabs === "urbans"
          ? response?.data?.data?.filter((v) => v?.rootType === "urbanas")
          : tabs === "rusticas"
          ? response?.data?.data?.filter((v) => v?.rootType === "rusticas")
          : tabs === "premium"
          ? response?.data?.data?.filter((v) => v?.rootType === "premium")
          : response?.data?.data;
      setAllData(filtered);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRoutes();
  }, [tabs]);

  return (
    <div className="row pb-3">
      {isLoading ? (
        <section className="d-flex justify-content-center align-items-center">
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        </section>
      ) : (
        allData?.map((value) => {
          return (
            <section
              key={value.id}
              className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
            >
              <RoutesCards value={value} />
            </section>
          );
        })
      )}
    </div>
  );
};

export default EventsCard;
