import API from "../../Config/API";
import { showToast } from "./toastActions";

export const handleCreateEvent_Action = (eventData, navigate) => {

  return async (dispatch) => {
    try {
      const res = await API.post("/store-event", eventData);
      if (res.data.status === 200) {
        dispatch(showToast("Event Created Successfully", "info"));
        navigate(-1);
      } else {
        const error = res.data;
        throw error;
      }
      return res
    } catch (error) {
      dispatch(showToast(error.message, "error"));
    }
  };
};
