import React from "react";
import styles from "./style.module.css";
const Heading = ({ label, img, bottom, width, style }) => {
  return (
    <h2
      className={styles.h2}
      style={{
        ...style,
        fontWeight: "bold",
      }}
    >
      {label}
      {img && (
        <img
          src={img}
          alt="eventsImg"
          style={{
            marginLeft: "10px",
            position: "relative",
            bottom: bottom ? bottom : "5px",
            fontWeight: "bold",
          }}
          width={width ? width : "40px"}
        />
      )}
    </h2>
  );
};

export default Heading;
