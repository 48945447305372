// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnc78OAA0952HpZQqOnUnvzK3loYqFnaQ",
  authDomain: "veyve-15005.firebaseapp.com",
  projectId: "veyve-15005",
  storageBucket: "veyve-15005.appspot.com",
  messagingSenderId: "426869587246",
  appId: "1:426869587246:web:00f353d1de0c42cd670ebf"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


// Initialize Auth
export const auth = getAuth(app);
