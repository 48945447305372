import React from "react";
import styles from "./style.module.css";
import CustomHeader from "../../Components/CustomHeader";
import Heading from "../../Components/Heading";
import { Link } from "react-router-dom";

const PolíticaDePrivacidad = () => {
  return (
    <section className={styles.mainBox}>
      <CustomHeader icon={false} label1={"Politica De Privacidad"} />
      <div className={`${styles.contentBox} container mt-4`}>
        <div>
          <Heading
            label={"POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS"}
            style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          />
          <p className="mt-3">
            <span className="fw-bold">veyve tecnologic sl.</span>, como
            Responsable del Tratamiento, le informa que, según lo dispuesto en
            el Reglamento (UE) 2016/679, de 27 de abril, (RGPD) y en la L.O.
            3/2018, de 5 de diciembre, de protección de datos y garantía de los
            derechos digitales (LOPDGDD), trataremos su datos tal y como
            reflejamos en la presente Política de Privacidad. En esta Política
            de Privacidad describimos cómo recogemos sus datos personales y por
            qué los recogemos, qué hacemos con ellos, con quién los compartimos,
            cómo los protegemos y sus opciones en cuanto al tratamiento de sus
            datos personales.
          </p>
          <p>
            Esta Política se aplica al tratamiento de sus datos personales
            recogidos por la empresa para la prestación de sus servicios. Si
            acepta las medidas de esta Política, acepta que tratemos sus datos
            personales como se define en esta Política.
          </p>
        </div>

        <div>
          <Heading
            label={"Responsable:"}
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          />
          <p>
            Denominación social:{" "}
            <span className="fw-bold">veyve tecnologic sl</span>
          </p>
          <p>
            Nombre comercial: <span className="fw-bold">Veyve</span> o similares
          </p>
          <p>
            CIF: <span className="fw-bold">B72749088</span>
          </p>
          <p>
            Domicilio:{" "}
            <span className="fw-bold">
              Calle Peon De La Villa 26 06427, Monterrubio De La Serena
              (Badajoz). España
            </span>
          </p>
          <p>
            e-mail: <span className="fw-bold">info@veyve.net</span>
          </p>
          <p>
            Teléfono: <span className="fw-bold">645 77 10 87</span>
          </p>
        </div>

        <div className={styles.listBox}>
          <li>
            Finalidad de los datos recabados y CONSENTIMIENTO al tratamiento.
          </li>
          <p>
            La presente política de privacidad está establecida conforme a lo
            dispuesto en la normativa vigente en materia de Protección de Datos
            de Carácter Personal.
          </p>
          <p>
            Se informa al usuario que mediante los formularios de registro de la
            web se recaban datos que se almacenan en un fichero, con la
            exclusiva finalidad de envío de comunicaciones electrónicas, tales
            como boletines, ofertas comerciales, así como otras comunicaciones
            que el sitio web entiende interesantes para sus usuarios. Los campos
            marcados como de cumplimentación obligatoria son imprescindibles
            para realizar la finalidad expresada. Únicamente el titular tendrá
            acceso a sus datos y bajo ningún concepto, estos datos serán
            cedidos, compartidos, transferidos ni vendidos a ningún tercero.
          </p>
          <p>
            Los datos recabados serán conservados durante el plazo estrictamente
            necesario para cumplir con los preceptos mencionados con
            anterioridad.
          </p>
          <p>
            Mientras no nos comunique lo contrario, entenderemos que sus datos
            no han sido modificados, que usted se compromete a notificarnos
            cualquier variación y que tenemos su consentimiento para utilizarlos
            para las finalidades mencionadas
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Medidas de seguridad</li>
          <p>
            El sitio web le informa que tiene implantadas las medidas de
            seguridad de índole técnica y organizativa necesarias para
            garantizar la seguridad de sus datos de carácter personal y evitar
            su alteración, pérdida y tratamiento y/o acceso no autorizado,
            habida cuenta del estado de la tecnología, la naturaleza de los
            datos almacenados y los riesgos a que están expuestos, ya provengan
            de la acción humana o del medio físico o natural.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>
            Ejercicio de Derechos ARCO: Acceso, Rectificación, Cancelación y
            Oposición.
          </li>
          <p>
            Aquellas personas físicas que hayan facilitado sus datos a través de
            la web, podrán dirigirse al titular de la misma con el fin de poder
            ejercitar sus derechos de acceso, rectificación, cancelación y
            oposición de los datos incorporados en sus ficheros.
          </p>
          <p>
            El interesado podrá ejercitar sus derechos mediante comunicación por
            correo ordinario con la referencia “Protección de datos”,
            especificando sus datos y acreditando su identidad a la dirección
            que figura en el aviso legal:
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Modificación de la Política de Privacidad</li>
          <p>
            El sitio web se reserva el derecho a modificar su Política de
            Privacidad, de acuerdo a su propio criterio, motivado por un cambio
            legislativo, jurisprudencial o doctrinal de la Agencia Española de
            Protección de Datos. El uso de la web después de dichos cambios,
            implicará la aceptación de los mismos.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Que hacemos con sus datos?</li>
          <p>- Apartado "Cliente"</p>
          <p>
            La principal finalidad de dicho tratamiento es la gestión de los
            usuarios registrados en nuestra web, así como el envío de publicidad
            relativa a los productos y servicios comercializados por Veyev o para el envío de publicidad, descuentos y promociones de
            productos y servicios de otras entidades.
          </p>
          <p>
            veyve asegura la confidencialidad de los datos aportados y
            garantiza que, en ningún caso, serán cedidos para ningún otro uso
            sin mediar consentimiento previo y expreso de nuestros usuarios.
            Sólo le pediremos aquellos datos necesarios para la prestación del
            servicio requerido y únicamente serán empleados para este fin.
          </p>

          <p>- Apartado "Realizar Compra"</p>
          <p>
            La principal finalidad de dicho tratamiento es mantener la relación
            contractual con nuestros clientes, facilitar la tramitación de los
            pedidos, la realización de estudios estadísticos, así como el envío
            de publicidad relativa a los productos y servicios comercializados
            por veyve o para el envío de publicidad, descuentos y
            promociones de productos y servicios de otras entidades.
          </p>

          <p>- Acciones de Marketing.</p>
          <p>
            veyve podrá utilizar dichos datos para informar sobre
            futuras promociones, ofertas y otras acciones comerciales en los
            casos en los que Vd. sea ya cliente o si no lo es, cuando Vd. nos
            haya proporcionado su consentimiento en el correspondiente apartado.
          </p>

          <p>
            - Apartado "Formulario de Contacto y Centro de Soporte de Veyev"
          </p>
          <p>
            Recogeremos datos con el fin de gestionar el centro de soporte de
            veyve y el contacto con el cliente a fin de poder atender su
            petición.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Base jurídica de los tratamientos</li>
          <p>
            Para el registro, compra y la gestión de las garantías basaremos el
            tratamiento de los datos en la ejecución del contrato de compra o en
            el marco de la relación precontractual.
          </p>
          <p>
            Para el envío de información comercial basaremos el tratamiento en
            su consentimiento aunque si Vd. ya es cliente podremos enviarle
            información sobre productos similares proporcionándole siempre un
            medio sencillo y gratuito para darse de baja.
          </p>
          <p>
            El consentimiento aquí prestado por el Titular para comunicación de
            datos a terceros tiene carácter revocable en todo momento, sin
            efectos retroactivos.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Base jurídica de los tratamientos</li>
          <p>
            Para el registro, compra y la gestión de las garantías basaremos el
            tratamiento de los datos en la ejecución del contrato de compra o en
            el marco de la relación precontractual.
          </p>
          <p>
            Para el envío de información comercial basaremos el tratamiento en
            su consentimiento aunque si Vd. ya es cliente podremos enviarle
            información sobre productos similares proporcionándole siempre un
            medio sencillo y gratuito para darse de baja.
          </p>
          <p>
            El consentimiento aquí prestado por el Titular para comunicación de
            datos a terceros tiene carácter revocable en todo momento, sin
            efectos retroactivos.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Destinatarios</li>
          <p>
            Con motivo de la compra sus datos pueden ser comunicados a los
            siguientes destinatarios:
          </p>
          <ul>
            <li>
              Entidades bancarias para el pago las compras mediante tarjeta.
            </li>
            <li>
              A la Oficina de consumidores y usuarios en caso de existir alguna
              reclamación.
            </li>
            <li>
              A los fabricantes, Servicios técnicos y/o mayoristas en el caso de
              garantías o reparaciones. A aquellas empresas transportistas que
              puedan tener encargado el envío a los clientes de los pedidos
              correspondientes. Estos destinatarios pueden estar ubicados dentro
              del territorio español como en fuera del mismo, en función del
              producto y/o servicio adquirido.
            </li>
            <li>
              En los supuestos legalmente establecidos, como es el caso de las
              Fuerzas y Cuerpos de Seguridad.
            </li>
            <li>
              A las administraciones públicas a las que tengamos que facilitar
              información con motivo del cumplimiento de nuestras obligaciones
              legales.
            </li>
          </ul>
          <p>
            Para el envío de newsletters o boletines de noticias es posible que
            utilicemos la herramienta Mailchimp que pertenece a la compañía
            estadounidense The Rocket Science Group, LLC, u otras herramientas
            alternativas. Por lo tanto, estaríamos ante una transferencia
            internacional de sus datos para prestar únicamente este servicio.
            Puedes encontrar información sobre esta compañía y su política de
            privacidad aquí. Además, esta compañía se encuentra adherida al
            protocolo Privacy Shield que permite las transferencias
            internacionales de datos entre la Unión Europea y Estados Unidos.
          </p>
          <p>
            El uso de Mailchimp no es una cesión de datos, sino que los datos
            sólo se usarán por esta plataforma única y exclusivamente para
            remitir los boletines y siempre con su consentimiento
          </p>
          <p>
            veyve asegura la confidencialidad de los datos aportados y
            garantiza que, en ningún caso, serán cedidos para ningún otro uso
            sin mediar consentimiento previo y expreso de nuestros clientes.
            Sólo le pediremos aquellos datos necesarios para la prestación del
            servicio requerido y únicamente serán empleados para este fin.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Derechos de los interesados</li>
          <p>El interesado tiene los siguientes derechos:</p>
          <ul>
            <li>
              Podrá solicitar información sobre los datos de carácter personal
              almacenados que le conciernan (acceso)
            </li>
            <li>
              Podrá solicitar que se corrija la inexactitud de sus datos
              personales. (rectificación)
            </li>
            <li>
              Podrá solicitar la eliminación o limitación del tratamiento de sus
              datos personales y (supresión y limitación)
            </li>
            <li>
              También podrá solicitar que se le pongan a disposición sus datos
              personales en un formato estructurado, corriente y de lectura
              mecánica. (portabilidad)
            </li>
          </ul>
          <p>
            Para ello deberá dirigirse por escrito adjuntando una copia del DNI
            o documento que le identifique, a la dirección Calle Zurbano, número
            7, 2º-5ª, código postal 08201, Sabadell, España, también a través de
            nuestro formulario o al correo electrónico info@veyve.com.
            veyve.
          </p>
          <p>
            El interesado también tiene derecho a presentar una reclamación ante
            las autoridades de protección de datos (Agencia Española de
            Protección de Datos). No obstante, cualquier cuestión relacionada
            con los datos le invitamos a contactar con nosotros para
            solucionarla.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Delegado de protección de datos</li>
          <p>
            Hemos designado un delegado de protección de datos (DPO) para
            cualquier cuestión relacionada con sus datos personales. Puede
            contactar con él a través de la dirección info@veyve.com
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Calidad de los datos</li>
          <p>
            Los usuarios deberán garantizar la veracidad, exactitud,
            autenticidad y vigencia de los datos de carácter personal que les
            hayan sido recogidos.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Protección de los menores</li>
          <p>
            No recogemos datos personales de menores. Es responsabilidad del
            padre/madre/tutor legal velar por para la privacidad de los menores,
            haciendo todo lo posible para asegurar que han autorizado la
            recogida y el uso de los datos personales del menor.
          </p>
        </div>

        <div className={styles.listBox}>
          <li>Redes sociales</li>
          <p>
            A través de nuestra página web puede acceder a las redes sociales
            Facebook, Twitter, Instagra, o las que tengamos en el momento
            actual, de acceso abierto a todos los usuarios. Se trata de sitios
            web donde el usuario puede registrase y seguirnos gratuitamente. En
            estas redes sociales los usuarios podrán conocer de nuestras
            actividades, opiniones, acceder a las fotos y vídeos. Los usuarios
            de estas redes sociales deben ser conscientes de que este lugar es
            independiente de la web
            <Link to={"https://veyve.net"} className="fw-bold" target="_blank">
              www.veyve.net
            </Link>
            y está abierto, es decir, es visible para todos sus usuarios, y las
            políticas de privacidad a aplicar a estos contenidos son las fijadas
            por Facebook, Twitter o Youtube. veyve no es titular de las
            redes sociales.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PolíticaDePrivacidad;
