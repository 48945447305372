import React from "react";
import TouristCard from "./TouristCard";
import styles from "./style.module.css"
import MapBox from "./MapBox";

function TouristPoint() {
  return (
    <div className={`my-5 container ${styles.parent}`}>
      <section className={styles.child1}>
        <TouristCard />
      </section>
      <section className={styles.child2}>
        <MapBox />
      </section>
    </div>
  );
}

export default TouristPoint;
