import React, { useState } from "react";
import Heading from "../../../Components/Heading";
import CustomButton from "../../../Components/Button";
import styles from "./style.module.css";
import OriginalUpload from "./OriginalUpload";
import API from "../../../Config/API";
import { useDispatch } from "react-redux";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { showToast } from "../../../Store/Actions/toastActions";
import { useTranslation } from "react-i18next";

const VideoData = ({ setOriginalVideo, setMultyVideos, multyVideos }) => {
  const [loading, setLoading] = useState(false);
  const [showVideoModal, setshowVideoModal] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleUploadClick = async (file, setSrc, setFile) => {
    setLoading(true);
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const res = await API.post("/upload-file", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        setshowVideoModal(false);
        setMultyVideos((old) => [...old, res?.data.data]);
        setSrc("");
        setFile("");
        setLoading(false);
        dispatch(showToast("Videos Uploaded Successfully", "info"));
      } catch (error) {
        dispatch(showToast(error?.message, "error"));
        setLoading(false);
      }
    } else {
      dispatch(showToast("All Videos are Required", "error"));
      setLoading(false);
    }
  };

  return (
    <>
      <div className="p-4">
        <section className="d-flex justify-content-between align-items-center w-100">
          <Heading label={t("Video")} fontSize="1.5rem" color="#35C89B" />
          <CustomButton
            label={t("Add Video")}
            width="180px"
            onClick={() => setshowVideoModal(true)}
            icon={<AiOutlinePlusCircle />}
          />
        </section>
        <div className={styles.inpFileParent}>
          <OriginalUpload
            multyVideos={multyVideos}
            showVideoModal={showVideoModal}
            setshowVideoModal={setshowVideoModal}
            setOriginalVideo={setOriginalVideo}
            handleUploadClick={handleUploadClick}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default VideoData;
