import React from "react";
import styles from "./style.module.css";
import { RadioBox } from "../FollowMunicipality/RadioBox";
import walkingMan from "../../Assets/walkBlack.png";
import bike from "../../Assets/bikeBlack.png";
import locationRoutes from "../../Assets/locationBlackIcon.png";
import location from "../../Assets/locationIcon.png";
import { useState } from "react";
import OutlineButton from "../../Components/OutlineBtn";
import { AiOutlinePlusCircle, AiOutlineCalendar } from "react-icons/ai";
import Button from "../../Components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { ImArrowRight } from "react-icons/im";

function PlanRoute() {
  const { state } = useLocation();
  const [selectedRoute, setSelectedRoute] = useState(state ? state.check : "");
  const navigate = useNavigate();
  return (
    <div className={styles.parent}>
      <section className={styles.child}>
        <div>
          <h3>Route Planner</h3>
        </div>
        <div className={styles.inpParent}>
          <section>
            <AiOutlineCalendar />
            <input type="text" placeholder="Start Date" />
          </section>
          <section>
            <AiOutlineCalendar />
            <input type="text" placeholder="Start Date" />
          </section>
          <section>
            <img src={location} width={15} />
            <input type="text" placeholder="Start Date" />
          </section>
        </div>
        <div
          className={styles.howToPlan}
          style={{
            width: state
              ? state.check == "Manual" && "65%"
              : selectedRoute == "Manual"
              ? "100%"
              : "65%",
          }}
        >
          <section
            style={{
              width: state
                ? state.check == "Manual" && "46.2%"
                : selectedRoute == "Manual"
                ? "30%"
                : "46.2%",
            }}
          >
            <h6>How To Do it?</h6>
            <aside>
              <div>
                <RadioBox
                  title="by Walking"
                  icon={<img src={walkingMan} width={12} />}
                  value={"byWalking"}
                  checked={selectedRoute == "byWalking"}
                  onChange={() => setSelectedRoute("byWalking")}
                />
              </div>
              <div>
                <RadioBox
                  title="by Bike"
                  icon={<img src={bike} width={20} />}
                  value={"bybike"}
                  checked={selectedRoute == "bybike"}
                  onChange={() => setSelectedRoute("bybike")}
                />
              </div>
            </aside>
          </section>

          <section
            style={{
              width: state
                ? state.check == "Manual" && "46.2%"
                : selectedRoute == "Manual"
                ? "30%"
                : "46.2%",
            }}
          >
            <h6>How to plan your route?</h6>
            <aside>
              <div>
                <RadioBox
                  title="AutoMatic"
                  value={"AutoMatic"}
                  checked={selectedRoute == "AutoMatic"}
                  onChange={() => setSelectedRoute("AutoMatic")}
                />
              </div>
              <div>
                <RadioBox
                  title="Manual"
                  value={"Manual"}
                  checked={selectedRoute == "Manual"}
                  onChange={() => setSelectedRoute("Manual")}
                />
              </div>
              <div>
                <RadioBox
                  title="Partnership"
                  value={"Partnership"}
                  checked={selectedRoute == "Partnership"}
                  onChange={() => setSelectedRoute("Partnership")}
                />
              </div>
            </aside>
          </section>
          {selectedRoute == "Manual" && !state && (
            <section className={styles.btnBox}>
              <OutlineButton
                Icon={<AiOutlinePlusCircle />}
                width={"100%"}
                label={"Add Manual Routes"}
                onClick={() => navigate("/manual-routes")}
              />
            </section>
          )}
        </div>

        {state && (
          <>
            <section className="d-flex justify-content-end">
              <Button label={"Edit Routes"} style={{ fontSize: "12px" }} />
            </section>
            <div
              className={styles.howToPlan}
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <section
                style={{
                  width: "30%",
                }}
              >
                <h6>Mirecoles. 16 Dec 2022</h6>
                <small>
                  Monterruiba de la serena <ImArrowRight /> Medline
                </small>
              </section>
              <section
                style={{
                  width: "30%",
                }}
              >
                <h6>Mirecoles. 17 Dec 2022</h6>
                <small>Rest Day</small>
              </section>
              <section
                style={{
                  width: "30%",
                }}
              >
                <h6>Mirecoles. 16 Dec 2022</h6>
                <small>
                  Monterruiba de la serena <ImArrowRight /> Medline
                </small>
              </section>
            </div>
          </>
        )}
        <div className={styles.locationBox}>
          <section>
            <div>
              <img src={locationRoutes} width={20} />
              <span>Distance: 0 KM</span>
            </div>
            <div>
              <img src={location} width={20} />
              <span>Stages: 0 </span>
            </div>
          </section>
          <section className={styles.btnBoxEnd}>
            <Button
              label={"Save & Continue"}
              onClick={() => navigate("/set-routes", { state: state })}
            />
          </section>
        </div>
      </section>
    </div>
  );
}

export default PlanRoute;
