import { SHOW_TOAST, HIDE_TOAST } from "../Actions/toastActions";


const initialState = {
    visible: false,
    message: '',
    type: ''
}

export const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_TOAST:
            return {
                ...state,
                visible: true,
                message: action.payload.message,
                type: action.payload.type
            }
        case HIDE_TOAST:
            return {
                ...state,
                visible: false,
                message: '',
                type: ''
            }
        default:
            return state
    }
}