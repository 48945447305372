import React from "react";
import styles from "./cards.module.css";
import backImg from "../../../../../Assets/pointOfinterstSlider3.png";
import { RiDeleteBin5Line } from "react-icons/ri";
const Cards = () => {
  return (
    <>
      {[1, 2, 3, 4, 5, 6].map(() => (
        <div
          className={`${styles.mainDiv}`}
          style={{
            backgroundImage: `url(${backImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className={styles.textDiv}>
            <p style={{ fontWeight: "bold" }}>Concert</p>
            <p>Palau de le Musica Catalana</p>
          </div>
          <div className={styles.delIcon}>
            <RiDeleteBin5Line size={25}/>
          </div>
        </div>
      ))}
    </>
  );
};

export default Cards;
