import React from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";

const DataCard = ({ addEvents }) => {
  return (
    <section className="row ">
      {addEvents.map((val, index) => {

        return (
          <div
            key={index}
            className="card col-lg-3 m-1  "
            style={{
              width: "250px",
            }}
          >
            <img
              src={`https://veyveback.com/${val?.image}`}
              className=" "
              width={"100%"}
              height={200}
              alt="eventImg"
            />
            <div className="card-body">
              <h5 className="card-title">{val.title}</h5>
              <div
                style={{
                  display: "flex",
                  // border:"1px solid red",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // border:"1px solid red",
                    // padding: "0 20px"
                  }}
                ></div>
              </div>
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default DataCard;
