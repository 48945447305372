import React, { useState } from "react";
import { AiOutlineStar } from "react-icons/ai";
import { BiUserCircle } from "react-icons/bi";

import Heading from "../../../Components/Heading";

import styles from "./sidelinksbar.module.css";
import { GrMap } from "react-icons/gr";
import { BsArrowBarDown, BsShare } from "react-icons/bs";
import { IoSettingsOutline } from "react-icons/io5";
import { FiLogOut, FiThumbsUp } from "react-icons/fi";
import { MdOutlineReportProblem } from "react-icons/md";
import { RiCalendarCheckLine } from "react-icons/ri";
import { TbSpeakerphone } from "react-icons/tb";
import { SlEvent } from "react-icons/sl";
import CustomLink from "../CustomLink/CustomLink";
import { useTranslation } from "react-i18next";

const SideLinksBar = () => {
  const { t } = useTranslation();
  //   const [changeLinkColor, setChangeLinkColor] = useState(true);
  //   const clickLink = () => {
  //     setChangeLinkColor(!changeLinkColor);
  //   };

  const arr = [
    {
      icon: <BiUserCircle fontSize={"1.5rem"} />,
      heading: "My Profile",
      title: t("sidebar.myProfile"),
      route: "my-profile",
      id: 1,
    },
    {
      icon: <AiOutlineStar fontSize={"1.5rem"} />,
      heading: "My Favourites",
      title: t("sidebar.myFavourites"),
      route: "my-favourite",
      id: 2,
    },
    {
      icon: <GrMap fontSize={"1.5rem"} />,
      heading: "My Municipalities",
      title: t("sidebar.myMunicipalities"),
      route: "my-municipalities",
      id: 3,
    },
    // {
    //   icon: <BsArrowBarDown fontSize={"1.5rem"} />,
    //   heading: "My Downloads",
    //   route: "my-downloads",
    //   id: 4,
    // },
    // {
    //   icon: <IoSettingsOutline fontSize={"1.5rem"} />,
    //   heading: "My Setting",
    //   title: t("sidebar.mySettings"),
    //   route: "my-settings",
    //   id: 5,
    // },
    {
      icon: <IoSettingsOutline fontSize={"1.5rem"} />,
      heading: "Radius Of Action",
      title: "Radius Of Action",
      route: "radius-of-action",
      id: 6,
    },
    // {
    //   icon: <FiThumbsUp fontSize={"1.5rem"} />,
    //   heading: "Rate Vevye",
    //   route: "rate-vevye",
    //   id: 6,
    // },
    // {
    //   icon: <MdOutlineReportProblem fontSize={"1.5rem"} />,
    //   heading: "Report Incident",
    //   route: "report-incident",
    //   id: 7,
    // },
    // {
    //   icon: <BsShare fontSize={"1.5rem"} />,
    //   heading: "Share App",
    //   route: "share-app",
    //   id: 8,
    // },
    // {
    //   icon: <RiCalendarCheckLine fontSize={"1.5rem"} />,
    //   heading: "My Bookings",
    //   route: "my-bookings",
    //   id: 9,
    // },
    // {
    //   icon: <TbSpeakerphone fontSize={"1.5rem"} />,
    //   heading: "My News",
    //   route: "my-news",
    //   id: 10,
    // },
    // {
    //   icon: <SlEvent fontSize={"1.5rem"} />,
    //   heading: "My Events",
    //   route: "my-events",
    //   id: 11,
    // },
    {
      icon: <FiLogOut fontSize={"1.5rem"} />,
      heading: "Logout",
      title: t("sidebar.logout"),
      route: "/",
      logout: true,
      id: 12,
    },
  ];

  return (
    <>
      <div className={`${styles.mainContainer}`}>
        {arr.map((item) => {
          return (
            <CustomLink
              key={item.id}
              icon={item.icon}
              routing={item.route}
              heading={item.heading}
              logout={item?.logout && item?.logout}
              title={item.title}
            />
          );
        })}
      </div>
    </>
  );
};

export default SideLinksBar;
