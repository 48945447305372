import React, { useState } from "react";
import CustomHeader from "../../Components/CustomHeader";
import Heading from "../../Components/Heading";
import HeaderBox from "./HeaderBox";
import serViceIcon from "../../Assets/servicesIcon.png";
import ServicesCard from "./ServicesCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { navAction } from "../../Store/Actions/navAction";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import OutlineButton from "../../Components/OutlineBtn";

const Services = ({cordinates}) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allData, setAllData] = useState([]);
  const { t } = useTranslation();
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("services"));
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);


  return (
    <div>
      <CustomHeader icon={false} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox setAllData={setAllData} btnValue={t("Search")} cordinates={cordinates} />
        <div className={`container`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Heading
              label={t("services")}
              img={serViceIcon}
            />
            {
              currentUser &&
              <OutlineButton
                Icon={<AiOutlinePlusCircle />}
                label={"Add Service"}
                width={"25%"}
                onClick={() => {
                  navigate("/add-service");
                }}
              />
            }
          </div>
          <div className="mt-5" id="target">
            <ServicesCard
              allData={allData}
              cordinates={cordinates}
              setAllData={setAllData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
