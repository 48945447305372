import React from "react";
import styles from "./style.module.css";
import newsBall from "../../../../../Assets/newsBall.png";
import manIcon from "../../../../../Assets/manIcon.png";

const NewsCard = () => {
  return (
    [1, 2, 3].map(() => (
      <div className={styles.cardBox}>
        <section className={styles.cardContentParent}>
          <section className="d-flex justify-content-between align-items-center mb-3">
            <h6 className="fw-bold">Tomationo Festival</h6>
            <div className="d-flex">
              <img src={newsBall} alt="newsBall" width="20" />
              <span>Sport</span>
            </div>
          </section>
          <section className={styles.cardContent}>
            <div className={`d-flex align-items-center ${styles.child1}`}>
              <img src={manIcon} width="50px" height="50px" alt="locationBlack" />
              <p>Publisher Name</p>
            </div>
            <div className={styles.child2}>
              <span>20/12/22</span>
            </div>
          </section>
          <section className={styles.descbox}>
            <p className="ms-2 fw-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce vel imperdiet dolor.</p>
          </section>
        </section>
      </div>
    ))
  );
};

export default NewsCard;
