import API from "../../Config/API";
import { showToast } from "./toastActions";

// Action Type Constants for Registeration
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

// Action Type Constants for Login
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

const registerAction = (credentials, navigate) => {
  return async (dispatch) => {

    dispatch({
      type: REGISTER_REQUEST
    })
    try {
      const data = await API.post(`/register`, credentials);

      if (data?.data?.status) {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: data.data,
        });
        dispatch(showToast(data?.data?.message, "info"));
        navigate("/login")
      } else {
        const error = data.data;
        throw error;
      }
    } catch (error) {
      dispatch({
        type: REGISTER_FAILURE,
        payload: error
      })
      dispatch(showToast(error?.message, "error"));
    }
  };
};
const loginAction = (credentials) => {
  return async (dispatch) => {

    dispatch({
      type: LOGIN_REQUEST
    })
    try {
      const data = await API.post(`/login`, credentials);
      // console.log("data", data?.data?.data);
      let logDetail = data?.data?.data;
      if (data.data.message === "Logged In successfully") {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data.data,
        });
        localStorage.setItem("UserDetails", JSON.stringify(logDetail));
        localStorage.setItem("currentUser", JSON.stringify(logDetail));
        dispatch(showToast(data.data.message, "success"));
        
      } else {
        const error = data.data;
        throw error;
      }
    } catch (error) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: error
      })
      dispatch(showToast(error?.message, "error"))
    }
    // console.log("data", data?.data?.data);
  };
};

// const updateProfile = (credentials) => {
//   return async (dispatch) => {
//     try {
//       const data = await API.post(`/update-profile`, credentials);
//       dispatch({
//         type: "UPDATE_PROFILE",
//         payload: data.data,
//       });

//       if (data.data.data !== undefined) {
//         localStorage.setItem("userDetails", JSON.stringify(data?.data?.data));
//         // navigate("/user-profile");
//       } else {
//         alert("Please Enter Name Field");
//       }
//       // console.log("Update Profile", data.data);
//     } catch (error) {
//       console.log("error == >", error);
//     }
//     // console.log("data", data?.data?.data);
//   };
// };

export { registerAction, loginAction };
