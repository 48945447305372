import React, { useEffect, useState } from "react";
import CardsSlider from "../../../Components/CardsSilder";
import Heading from "../../../Components/Heading";
import newsIcon from "../../../Assets/newsIcon.png";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../../Config/API";
import { Spinner } from "react-bootstrap";

const News = () => {
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);

  const { t } = useTranslation();
  const user = localStorage.getItem("UserDetails");

  const parsedUser = JSON.parse(user);

  useEffect(() => {
    // Get User
    setCurrentUser(parsedUser);

    // Get Current Location
    const currentLocation = localStorage.getItem("currentLocation");
    const parsedLocation = JSON.parse(currentLocation);
    setCurrentLocation(parsedLocation);

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getNews();
      setIsApiCallInProgress(true);
    }
  }, [currentUser, currentLang, currentLocation]);

  const getNews = async () => {
    setIsLoading(true);

    try {
      let res;
      if (parsedUser) {
        res = await API.post("/get-news", { userId: parsedUser?.id });
      } else {
        res = await API.post("/get-news-web", {
          language: currentLang || "Spanish",
          lat: currentLocation?.latitude || "",
          long: currentLocation?.longitude || "",
        });
      }

      const activeNews = res?.data?.data?.filter(
        (news) => news.status?.toLowerCase() === "active"
      );

      if (user) {
        let filter = activeNews.filter((val) => val.followed === "yes");
        setAllData(filter);
        return;
      }
      setAllData(activeNews);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div className="container py-5">
      <section className={styles.eventHeader}>
        <Heading
          label={t("news")}
          img={newsIcon}
          style={{ fontWeight: "bold" }}
          bottom="20px"
          width="50px"
        />
        <Link to={"/news"}>
          <button>See All</button>
        </Link>
      </section>
      <section>
        {isLoading ? (
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        ) : (
          <CardsSlider data={allData} name="news" />
        )}
      </section>
    </div>
  );
};

export default News;
