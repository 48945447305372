import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../Components/Heading";
import { navAction } from "../../Store/Actions/navAction";
import mapIcon from "../../Assets/mapIcon.png";
import CustomHeader from "../../Components/CustomHeader";
import HeaderBox from "../Home/HeaderBox";
import { useTranslation } from "react-i18next";
import LeafletMap from "../../Components/GoogleMap/LeafletMap";

const Map = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = localStorage.getItem("UserDetails");
  const [pointData, setPointsData] = useState([]);
  const parsedUser = JSON.parse(user);

  // const getPoints = async () => {
  //   // setIsLoading(true);

  //   try {
  //     let data;
  //     let url;

  //     if (parsedUser) {
  //       url = "/get-points";
  //       data = await API.post(url, {
  //         userId: parsedUser?.id,
  //         distance: "10000",
  //       });
  //     } else {
  //       url = "/get-points-web";
  //       data = await API.post(url, {
  //         //   language: currentLang || "Spanish",
  //         //   lat: currentLocation?.latitude || "",
  //         //   long: currentLocation?.longitude || "",
  //       });
  //     }

  //     let points = data?.data?.data;
  //     let premiumPoints = data?.data.premium;

  //     const activePoints = points?.filter(
  //       (point) => point?.status?.toLowerCase() === "active"
  //     );

  //     let filteredArray = premiumPoints.filter((item) => {
  //       return item.points.some(
  //         (point) => point.status.toLowerCase() === "active"
  //       );
  //     });
  //     let mergedPoints = [...activePoints, ...filteredArray];

  //     //   Filter only the points with status "Active"

  //     setPointsData(mergedPoints);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("map"));
  }, []);

  // console.log("pointData", pointData);
  return (
    <div>
      <CustomHeader icon={false} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox btnValue={t("Search")} />
        <div className="container py-4 pb-5">
          <Heading label={t("map")} img={mapIcon} bottom="10px" width="80px" />
          {/* <GoogleMap  /> */}

          {/* <ClusterMap /> */}
          <LeafletMap />
        </div>
      </div>
    </div>
  );
};

export default Map;
