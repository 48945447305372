import React, { useState } from "react";
import CustomHeader from "../../Components/CustomHeader";
import Heading from "../../Components/Heading";
import HeaderBox from "./HeaderBox";
import pointSofIcon from "../../Assets/pointOfInterest.png";
import PointsOfInterestCard from "./PointsOfInterestCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { navAction } from "../../Store/Actions/navAction";
import OutlineButton from "../../Components/OutlineBtn";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PointsOfInterest = () => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [allData, setAllData] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [cordinates, setCordinates] = useState();

  useEffect(() => {
    // Getting user's current location
    navigator.geolocation.getCurrentPosition(function (position) {
      setCordinates({
        ...cordinates,
        ["latitude"]: position.coords.latitude,
        ["longitude"]: position.coords.longitude,
      });
    });
  }, []);

  const { t } = useTranslation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("pointOfinterest"));
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, [cordinates]);

  return (
    <div>
      <CustomHeader icon={false} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox
          setAllData={setAllData}
          btnValue={t("Search")}
          cordinates={cordinates}
          setIsLoading={setIsLoading}
        />
        <div className={`container`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Heading
              label={t("pointsOfInterest")}
              img={pointSofIcon}
              bottom="20px"
            />

            {currentUser && (
              <OutlineButton
                Icon={<AiOutlinePlusCircle />}
                label={t("Add-Point")}
                width={"25%"}
                onClick={() => {
                  navigate("/add-point-of-interest");
                  setToggle(true);
                }}
              />
            )}
          </div>
          <div className="mt-5" id="target">
            <PointsOfInterestCard
              allData={allData}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              cordinates={cordinates}
              setAllData={setAllData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PointsOfInterest;
