import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { profileAction } from "../../../Store/Actions/profileAction";
import MyFavorite from "./Favorite Section/MyFavorite";
import MyMunicipalities from "./Municipalities/MyMunicipalities";
import MyBookings from "./My Bookings/MyBookings";
import MyEvents from "./My Events/MyEvents";
import MyNews from "./My News/MyNews";
import MyDownloads from "./MyDownloads/MyDownloads";
import MySettings from "./MySettings/MySettings";
import MyProfile from "./Profile Section/MyProfile";
import RateVevye from "./RateVevye/RateVevye";
import ReportIncident from "./Report/ReportIncident";
import ShareApp from "./ShareApp/ShareApp";
import RadiusOfAction from "./RadiusOfAction";

const AllLinks = () => {
  const active = useSelector((state) => state.profileReducer.activeProfile);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  //   console.log(active);
  // const profileActiveChange = (activeProfile, route) => {
  //   dispatch(profileAction(active));
  //   navigate(route);
  // };

  // useEffect(() => {
  //   dispatch(profileAction(active));
  // }, [active]);

  return (
    <>
      {active === "My Profile" && <MyProfile />}
      {active === "My Favourites" && <MyFavorite />}
      {active === "My Municipalities" && <MyMunicipalities />}
      {active === "My Downloads" && <MyDownloads />}
      {active === "My Setting" && <MySettings />}
      {active === "Rate Vevye" && <RateVevye />}
      {active === "Report Incident" && <ReportIncident />}
      {active === "Share App" && <ShareApp />}
      {active === "My Bookings" && <MyBookings />}
      {active === "Radius Of Action" && <RadiusOfAction />}
      {active === "My News" && <MyNews />}
      {active === "My Events" && <MyEvents />}
    </>
  );
};

export default AllLinks;
