import React from "react";
import styles from "./circlegroup.module.css";

const CircleGroup = ({ icon, h6, isActive, onClick, activeTab, heading }) => {
  return (
    <div
      className={`${styles.circleContainer} ${isActive ? styles.active : ""}`}
      onClick={onClick}
    >
      <div
        className={styles.circleIcon}
        style={{
          background: activeTab === heading ? "#34c89a" : "#fff",
        }}
      >
        {icon}
      </div>
      <div>
        <h6>{h6}</h6>
      </div>
    </div>
  );
};

export default CircleGroup;
