import React, { useState } from "react";
import CustomHeader from "../../Components/CustomHeader";
import Heading from "../../Components/Heading";
import HeaderBox from "./HeaderBox";
import newsIcom from "../../Assets/newsIcon.png";
import NewsCard from "./NewsCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { navAction } from "../../Store/Actions/navAction";
import { useTranslation } from "react-i18next";

const News = () => {
  const [allData, setAllData] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("news"));
  }, []);

  return (
    <div>
      <CustomHeader icon={false} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox setAllData={setAllData} btnValue={t("Search")} />
        <div className="container" id="target">
          <Heading label={t("news")} img={newsIcom} bottom="15px" />
          <NewsCard allData={allData} setAllData={setAllData} />
        </div>
      </div>
    </div>
  );
};

export default News;
