import React, { useEffect, useState } from "react";
import { GrMap, GrMapLocation } from "react-icons/gr";
import { RiDeleteBinLine } from "react-icons/ri";
import Button from "../../../../Components/Button";
import Heading from "../../../../Components/Heading";
import Lists from "./Lists/Lists";
import styles from "./mymunicipalities.module.css";
import API from "../../../../Config/API";
import Select from "react-select";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import RadioBoxGroup from "../../../FollowMunicipality/RadioBox";
import { useTranslation } from "react-i18next";

const MyMunicipalities = () => {
  const [municipalities, setMuncipalities] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [deleteListTrigger, setDeleteListTrigger] = useState("");
  const [addMuncipality, setAddMuncipality] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedMuncData, setSelectedMuncData] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);
  const handleMuncChange = (selectedOption) => {
    setSelectedMuncData(selectedOption);
  };
  const getMuncipalties = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/get-all-muncipalities", {
          userId: currentUser?.id,
        });

        if (res?.data?.data) {
          if (res?.data?.data?.length > 0) {
            const formattedArray = res?.data?.data?.map((val) => ({
              id: val?.id,
              value: val?.title,
              label: val?.title,
            }));
            setOptions(formattedArray);
          }
        }
        // console.log(res)
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      getFollowedMunc();
      getMuncipalties();
    }
  }, [currentUser, deleteListTrigger]);

  const getFollowedMunc = async () => {
    try {
      const res = await API.post("/get-follows", { userId: currentUser?.id });

      setMuncipalities(res?.data?.muncipalities);
    } catch (error) {
      console.log(error);
    }
  };

  const addAction = async (e) => {
    e.preventDefault();
    if (addMuncipality) {
      if (selectedMuncData) {
        const data = {
          userId: currentUser?.id,
          muncipalityId: selectedMuncData?.id,
        };
        try {
          const res = await API.post("/follow-muncipality", data);
          getFollowedMunc();
          setAddMuncipality(false);
        } catch (error) {
          console.log(error);
        }
      } else alert("Municipality is Not Selected");
    } else setAddMuncipality(true);
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <section className={`${styles.heading}`}>
          <Heading label={t("sidebar.myMunicipalities")} color="#35C89B" />
        </section>
        <section className={`${styles.listGroup}`}>
          {addMuncipality ? (
            <>
              <section className={styles.inpBox}>
                <span
                  className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
                >
                  <GrMapLocation size={25} />
                  <Select
                    options={options}
                    onChange={handleMuncChange}
                    className={styles.muncSelect}
                    placeholder={"Search Municipality"}
                  />
                </span>
                <span className={styles.iconBox}>
                  <IoIosArrowDropdownCircle size={25} color="#34c89a" />
                </span>
              </section>
              <section>
                <RadioBoxGroup
                  currentUser={currentUser}
                  selectedMuncData={selectedMuncData}
                  setSelectedMuncData={setSelectedMuncData}
                />
              </section>
            </>
          ) : municipalities?.length > 0 ? (
            municipalities?.map((item) => {
              return (
                <Lists
                  deletListTrigger={deleteListTrigger}
                  setDeletListTrigger={setDeleteListTrigger}
                  key={item.id}
                  currentUser={currentUser}
                  muncipalityData={item}
                />
              );
            })
          ) : (
            "No Muncipalitu"
          )}
        </section>

        <div className="mt-5">
          <Button
            label={
              addMuncipality ? t("sidebar.addMuncipality") : t("sidebar.addNew")
            }
            onClick={addAction}
            width="100%"
          />
        </div>
      </div>
    </>
  );
};

export default MyMunicipalities;
