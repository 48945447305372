import React, { useEffect, useState } from "react";
import arrowDown from "../../Assets/arrowDown.png";
import spain from "../../Assets/spainFlag.png";
import styles from "./style.module.css";
import Spanish from "../../Assets/Flags/Spainish.png";
import Italian from "../../Assets/Flags/Italian.png";
import German from "../../Assets/Flags/German.png";
import French from "../../Assets/Flags/French.png";
import English from "../../Assets/Flags/English.png";
import Chinese from "../../Assets/Flags/Chinease.png";
import Portuguese from "../../Assets/Flags/Portuguese.png";
import i18n from "../../i18n";
import { useSelector } from "react-redux";

const FlagDropDown = ({ setName, type }) => {
  const typeLanguage = useSelector((state) => state.PoiReducer.checkLang);
  const languages = [
    {
      langName: "Spanish",
      img: Spanish,
      code: "es",
      type: type === "point" ? typeLanguage?.data?.spanish : "yes",
      id: 1,
    },
    {
      langName: "Italian",
      img: Italian,
      code: "it",
      type: type === "point" ? typeLanguage?.data?.italian : "yes",
      id: 2,
    },
    {
      langName: "German",
      img: German,
      code: "de",
      type: type === "point" ? typeLanguage?.data?.german : "yes",
      id: 3,
    },
    {
      langName: "French",
      img: French,
      code: "fr",
      type: type === "point" ? typeLanguage?.data?.french : "yes",
      id: 4,
    },
    {
      langName: "English",
      img: English,
      code: "en",
      type: type === "point" ? typeLanguage?.data?.english : "yes",
      id: 5,
    },
    {
      langName: "Chinese",
      img: Chinese,
      code: "zh",
      type: type === "point" ? typeLanguage?.data?.chinese : "yes",
      id: 6,
    },
    {
      langName: "Portuguese",
      img: Portuguese,
      code: "pt",
      type: type === "point" ? typeLanguage?.data?.portuguese : "yes",
      id: 7,
    },
  ];

  const [show, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [language, setLanguage] = useState(languages[4]);
  // Get Current User

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Find the language object that matches the user's preferred language
    const userLanguage = parsedUser?.language;
    const defaultLanguage = languages.find(
      (lang) => lang.langName === userLanguage
    );

    // Set the default language
    setLanguage(languages[0]);
    setName(languages[0].langName);
    i18n.changeLanguage(languages[0].code);
  }, []);

  const handleShow = () => {
    setShow(!show);
  };

  const handleChange = (val) => {
    handleShow();
    setLanguage(val);
    setName(val.langName);
    if (type === "navbar") {
      i18n.changeLanguage(val?.code);
    }
  };

  return (
    <div className={styles.dropDownBox}>
      <section className={styles.dropDownSelect} onClick={handleShow}>
        <img src={language.img} width="30" alt="Flag" />
      </section>
      <section
        className={styles.dropDownOption}
        style={{
          opacity: show ? 1 : 0,
          visibility: show ? "visible" : "hidden",
          transition: show ? "0.5s ease" : "0.5s ease",
          transform: show ? "translateY(0)" : "translateY(-20px)",
        }}
      >
        {languages
          ?.filter((val) => val?.type === "yes")
          ?.map((val) => {
            return (
              <span
                key={val.id}
                className="d-block"
                onClick={() => handleChange(val)}
              >
                <img src={val.img} width="30" />
              </span>
            );
          })}
      </section>
    </div>
  );
};

export default FlagDropDown;
