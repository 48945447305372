import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { GrMapLocation } from "react-icons/gr";
import Select from "react-select";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import OutlineButton from "../../../Components/OutlineBtn";
import RadioBox from "../RadioBox";
import API from "../../../Config/API";
import { useTranslation } from "react-i18next";

const Form = () => {
  const [currentUser, setCurrentUser] = useState("");
  const [options, setOptions] = useState([]);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [selectedMuncData, setSelectedMuncData] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Getting user from LocalStorage
  useEffect(() => {
    getUserfromLS();
  }, []);

  // Getting user's current location
  useEffect(() => {
    if (currentUser) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    let currentLocation = { latitude, longitude };
    localStorage.setItem("currentLocation", JSON.stringify(currentLocation));
  }, [latitude, longitude]);

  // Getting Municipalities from API
  useEffect(() => {
    getMuncipalties();
    storeUserLocation();
  }, [currentUser]);

  const getUserfromLS = async () => {
    const storedUser = await JSON.parse(localStorage.getItem("currentUser"));
    setCurrentUser(storedUser);
  };
  // Getting Municipalities
  const getMuncipalties = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/get-all-muncipalities", {
          userId: currentUser?.id,
        });

        if (res?.data?.data) {
          if (res?.data?.data?.length > 0) {
            const formattedArray = res?.data?.data?.map((val) => ({
              id: val?.id,
              value: val?.title,
              label: val?.title,
            }));
            setOptions(formattedArray);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Storing User Location
  const storeUserLocation = async () => {
    if (latitude && longitude) {
      try {
        const data = {
          userId: currentUser?.id,
          latitude: latitude,
          longitude: longitude,
        };
        const res = await API.post("/store-location", data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  //
  const handleNextBtn = async (e) => {
    e.preventDefault();

    if (selectedMuncData) {
      const data = {
        userId: currentUser?.id,
        muncipalityId: selectedMuncData?.id,
      };
      try {
        const res = await API.post("/follow-muncipality", data);
        navigate("/");
      } catch (error) {
        console.log(error);
      }
    } else alert("Municipality is Not Selected");
  };

  const handleMuncChange = (selectedOption) => {
    setSelectedMuncData(selectedOption);
  };

  return (
    <form className={styles.formBox}>
      <section className={` ${styles.heading}`}>
        <Heading
          label={t("Do-you-want-to")}
          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          color="#35C89B"
        />
        <Heading
          label={t("Skip-this-step")}
          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
          color="#35C89B"
        />
      </section>
      <section className={styles.inpBox}>
        <span
          className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
        >
          <GrMapLocation size={25} />
          <Select
            options={options}
            onChange={handleMuncChange}
            className={styles.muncSelect}
            placeholder={t("Search-Muncpalit")}
          />
        </span>
        <span className={styles.iconBox}>
          <IoIosArrowDropdownCircle size={25} color="#34c89a" />
        </span>
      </section>
      <section>
        <RadioBox
          currentUser={currentUser}
          selectedMuncData={selectedMuncData}
          setSelectedMuncData={setSelectedMuncData}
        />
      </section>
      <section className="d-flex justify-content-between">
        <Button onClick={handleNextBtn} label={t("Next")} width="48%" />
        <OutlineButton
          onClick={() => navigate("/")}
          label={t("Skip")}
          width="48%"
        />
      </section>
    </form>
  );
};

export default Form;
