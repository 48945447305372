import {
  CREATE_POI_FAILURE,
  CREATE_POI_REQUEST,
  CREATE_POI_SUCCESS,
  DELETE_POI_FAILURE,
  DELETE_POI_REQUEST,
  DELETE_POI_SUCCESS,
  EDIT_POI_REQUEST,
  EDIT_POI_SUCCESS,
  EDIT_POI_FAILURE,
  GET_POI_FAILURE,
  GET_POI_REQUEST,
  GET_POI_SUCCESS,
} from "../Constants/pointOfInterestConts";
import { CHECK_LANGUAGE } from "../Constants/userConstant";

const initialState = {
  poi: [],
  createPoiRes: "",
  deletePoiRes: "",
  editPoiRes: "",
  loading: false,
  error: null,
  checkLang: null,
};

export const PoiReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POI_REQUEST:
    case CREATE_POI_REQUEST:
    case DELETE_POI_REQUEST:
    case EDIT_POI_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case GET_POI_SUCCESS:
      return {
        ...state,
        loading: false,
        poi: action.payload,
      };
    case CREATE_POI_SUCCESS:
      return {
        ...state,
        loading: false,
        createPoiRes: action.payload,
      };
    case DELETE_POI_SUCCESS:
      return {
        ...state,
        loading: false,
        deletePoiRes: action.payload,
      };
    case EDIT_POI_SUCCESS:
      return {
        ...state,
        loading: false,
        editPoiRes: action.payload,
      };
    case CHECK_LANGUAGE:
      return {
        ...state,
        loading: false,
        checkLang: action.payload,
      };

    case GET_POI_FAILURE:
    case CREATE_POI_FAILURE:
    case DELETE_POI_FAILURE:
    case EDIT_POI_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
