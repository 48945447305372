import React from "react";
import styles from "./style.module.css";
import location from "../../Assets/locationssIcon.png";
import cycle from "../../Assets/cycleIcon.png";
import manIcon from "../../Assets/manicon1.png";
import { useNavigate } from "react-router-dom";
import imagePlacehoder from "../../Assets/ImagePlaceholder.png";

const RoutesCard = ({ value }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.cardBox}
      onClick={() => navigate(`/routes-details/route/${value?.id}`, { state: value })}
    >
      <section className={styles.cardImageBox}>
        <img
          src={
            value?.routesArray?.length > 0
              ? `https://veyveback.com/${
                  value?.routesArray[0]?.point1?.imagesArraySpanish[0]?.image
                    ? value?.routesArray[0]?.point1?.imagesArraySpanish[0]
                        ?.image
                    : value?.routesArray[0]?.point1?.imagesArraySpanish[1]
                        ?.image
                }`
              : imagePlacehoder
          }
          alt="card1"
          width="100%"
          height="140px"
        />
        <div className={styles.imgBoxText}>
          <h6>{value.title}</h6>
          <section className="d-flex align-items-end mt-2">
            <img src={location} alt="location" width="22px" heigth="15px" />
            <span>{value.distance}KM</span>
          </section>
          <section className="d-flex align-items-end w-50 mt-2">
            <img src={cycle} alt="location" width="25px" heigth="18px" />
            <img src={manIcon} alt="location" width="12px" heigth="10px" />
            <img src={cycle} alt="location" width="25px" heigth="18px" />
            <img src={manIcon} alt="location" width="12px" heigth="10px" />
          </section>
        </div>
      </section>
    </div>
  );
};

export default RoutesCard;
