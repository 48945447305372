import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import "./myBookings.css";
import Heading from "../../../../Components/Heading";
import { UpcomingBookings, PreviousBookings } from "./BookingCards"

const MyBookings = () => {

  const [activeTab, setActiveTab] = useState("upcoming");

  return (
    <section className={"mainBox"}>
      <div className="mb-3">
        <Heading label={"My Bookings"} style={{ fontSize: "2rem", fontWeight: "bold" }} />
      </div>
      <div className={"tabBox"}>
        <Tabs className={"customTabs"} activeKey={activeTab} onSelect={(tab) => setActiveTab(tab)}>
          <Tab eventKey="upcoming" title="Upcoming">
            <UpcomingBookings />
          </Tab>
          <Tab eventKey="previous" title="Previous">
            <PreviousBookings />
          </Tab>
        </Tabs>
      </div>
    </section>
  )
};

export default MyBookings;