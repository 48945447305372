import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "../../../../Assets/Marker.png";

const AnyReactComponent = ({ text }) => (
  <div
    style={{ zIndex: 222, position: "relative", bottom: "20px", right: "20px" }}
  >
    <img src={Marker} width="50px" alt="Marker " />
    {text}
  </div>
);

const GoogleMap = ({ city, handleMapChange,lat,lng }) => {
  const [getLatLong, setGetLatLong] = useState({});
  useEffect(() => {
    localStorage.setItem("lat", city?.lat);
    localStorage.setItem("lng", city?.lng);
  }, [city]);

  const defaultProps = {
    center: {
      lat: Number(lat) || 39.21377454981294,
      lng: Number(lng) || -3.4179556504347497,
    },
    zoom: 11,
  };
  const defaultMapOptions = {
    disableDefaultUI: true,
  };
  return (
    <>
      <div style={{ height: "80vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCPr-b3VlC4X08HU1jYqBPYeZrzt_Ci0SE" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={defaultMapOptions}
          onClick={(e) => {
            setGetLatLong(e);
            handleMapChange(e);
          }}
        >
          <AnyReactComponent
            lat={getLatLong.lat}
            lng={getLatLong.lng}
            text="My Marker"
          />
        </GoogleMapReact>
      </div>
    </>
  );
};

export default GoogleMap;
