import React, { useEffect, useRef, useState } from "react";
import styles from "./style.module.css";
import locationIcon from "../../../Assets/locationIcon.png";
import boxImage from "../../../Assets/boxImage.png";
import arrowDown from "../../../Assets/arrowDownIcon.png";
import searchIcon from "../../../Assets/searchIcon.png";
import Button from "../../../Components/Button";
import API from "../../../Config/API";
import Select from "react-select";
import { BsSearch } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Store/Actions/toastActions";
import MultiRangeSlider from "multi-range-slider-react";
import "./style.css";
import { useTranslation } from "react-i18next";
const HeaderBox = ({
  btnValue,
  location,
  setSearchData,
  setSearchType,
  setIsLoading,
  cordinates,
}) => {
  const [currentUser, setCurrentUser] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [categories, setCategories] = useState([]);
  const [muncipalities, setMuncipalties] = useState([]);
  const [minDistanceValue, set_minDistanceValue] = useState(0);
  const [maxDistanceValue, set_maxDistanceValue] = useState(200);
  const ref = useRef();

  const [searchParams, setSearchParams] = useState({
    selectedType: "",
    selectedCategory: "",
    selectedMunc: "",
    keyword: "",
    latitude: currentLocation?.latitude,
    longitude: currentLocation?.longitude,
    language: "Spanish",
    distance: "",
  });
  const [isCategoryDisabled, setIsCategoryDisabled] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    // Get Current User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Current Location
    const currentLocation = localStorage.getItem("currentLocation");
    const parsedLocation = JSON.parse(currentLocation);
    setCurrentLocation(parsedLocation);

    // Get Current Language
    const userLanguage = localStorage.getItem("userLanguage");
    setCurrentLanguage(userLanguage);
  }, []);

  // Updating SearchParams whenever the location changes
  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      latitude: currentLocation?.latitude,
      longitude: currentLocation?.longitude,
    }));
  }, [currentLocation]);

  // Updating SearchParams whenever the language changes
  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      language: currentLanguage,
    }));
  }, [currentLanguage]);

  useEffect(() => {
    getCategories();
  }, [currentUser, searchParams?.selectedType]);

  useEffect(() => {
    getMuncipalities();
  }, [currentUser]);

  const getCategories = async () => {
    try {
      const selectedType = () => {
        if (searchParams?.selectedType === "points") {
          return "point";
        } else if (searchParams?.selectedType === "events") {
          return "event";
        } else if (searchParams?.selectedType === "news") {
          return "news";
        } else if (searchParams?.selectedType === "services") {
          return "service";
        }
      };

      const res = await API.post("/get-categories-lang", {
        language: currentUser?.language || "Spanish",
        type: selectedType(),
      });
      setCategories(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getMuncipalities = async () => {
    try {
      const res = await API.post("/get-all-muncipalities", { userId: 2 });
      setMuncipalties(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const options = categories?.map((category) => ({
    label: category?.name,
    value: category,
  }));

  const muncipalitiesOptions = muncipalities?.map((munc) => ({
    label: munc?.title,
    value: munc,
  }));

  const handleCategoryChange = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      selectedCategory: selectedOption.value,
    });
  };

  const handleMuncChange = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      selectedMunc: selectedOption.value,
    });
  };

  const handleDistanceChange = (value) => {
    // console.log(value)
    set_minDistanceValue(value?.minValue);
    set_maxDistanceValue(value?.maxValue);
  };

  const handleSelectType = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      selectedType: selectedOption.value,
    });

    if (selectedOption.value) {
      // Enable the category select box
      setIsCategoryDisabled(false);
      // setIsMunicipalityDisabled(false);
    } else {
      // Disable the category select box
      setIsCategoryDisabled(true);
      // setIsMunicipalityDisabled(true);
    }
  };

  const handleKeywordChange = (event) => {
    setSearchParams({
      ...searchParams,
      keyword: event.target.value,
    });
  };

  const handleSearch = async () => {
    setIsLoading(true);

    {
      try {
        const res = await API.post("/search", {
          searchType: searchParams?.selectedType,
          search: searchParams?.keyword,
          language:
            searchParams?.language || currentUser?.language || "Spanish",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
          distance: maxDistanceValue,
          categoryId: searchParams?.selectedCategory?.id,
          muncipalityId: searchParams?.selectedMunc?.id,
        });
        let filterData = res?.data?.data.filter(
          (val) => val?.status?.toLowerCase() === "active"
        );
        setSearchData(filterData);
        setSearchType(searchParams?.selectedType);

        if (res?.data?.data <= 0) {
          dispatch(
            showToast("No Data Availabele according to Your Search", "info")
          );
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Type Options

  const typeOptions = [
    {
      label: "Points",
      value: "points",
    },
    {
      label: "Events",
      value: "events",
    },
    {
      label: "News",
      value: "news",
    },
    {
      label: "Services",
      value: "services",
    },
  ];

  return (
    <div className="container">
      <section
        className={`row ${
          location === "home" ? styles.header_box : styles.header_other
        } `}
      >
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={`px-1 py-2 ${styles.header_box_1}`}>
            <img
              src={searchIcon}
              className={styles.searchIcon}
              width="20px"
              height="20px"
              alt="searchIcon"
            />
            <input
              type="text"
              placeholder={t("Select-Keyword")}
              className={styles.inp}
              onChange={handleKeywordChange}
            />
          </section>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={styles.inpBox}>
            <span
              className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
            >
              <img width="35px" src={boxImage} alt="arrowDown" />
              <Select
                options={typeOptions}
                onChange={handleSelectType}
                className={styles.muncSelect}
                placeholder={t("Select-Type")}
                ref={ref}
              />
            </span>
          </section>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={styles.inpBox}>
            <span
              className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
            >
              <img width="35px" src={boxImage} alt="arrowDown" />
              <Select
                options={options}
                onChange={handleCategoryChange}
                isDisabled={isCategoryDisabled}
                className={styles.muncSelect}
                placeholder={t("Select-Category")}
              />
            </span>
          </section>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={styles.inpBox}>
            <span
              className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
            >
              <img
                width="20px"
                className="mx-2"
                src={locationIcon}
                alt="arrowDown"
              />
              <Select
                className={styles.muncSelect}
                options={muncipalitiesOptions}
                placeholder={t("Select-Muncipality")}
                onChange={handleMuncChange}
              />
            </span>
          </section>
        </div>
        <div
          className={`col-lg-2 col-md-6 col-sm-6 col-12 py-3  ${styles.progessParent}`}
        >
          <MultiRangeSlider
            onChange={handleDistanceChange}
            min={0}
            max={200}
            minValue={minDistanceValue}
            step={0.01}
            maxValue={maxDistanceValue}
            labels={[`${minDistanceValue}km`, `${maxDistanceValue}km`]}
            ruler={false}
            baseClassName={styles.multiRangeContainer}
            className={styles.multiRangeSlider}
            barInnerColor="#35c89b"
            barLeftColor="#fff"
            barRightColor="#fff"
            thumbLeftColor="#35c89b"
            thumbRightColor="#35c89b"
          />
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className="d-flex justify-content-center align-items-center">
            <Button Icon={<BsSearch />} onClick={handleSearch} width="50%" />
          </section>
        </div>
      </section>
    </div>
  );
};

export default HeaderBox;
