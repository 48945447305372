import React from "react";
import styles from "./style.module.css";

const Button = ({ label, width, onClick, Icon, disable = false, style }) => {
  return (
    <button
      className={styles.btn}
      onClick={onClick}
      disabled={disable}
      style={{
        width: width ? width : "",
        ...style,
      }}
    >
      {Icon ? Icon : ""}
      {label}
    </button>
  );
};

export default Button;
