import React from "react";
import styles from "./style.module.css";
const Input = ({ placeholder, type, onchange, value, disabled }) => {
  return (
    <input
      className={styles.input}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={(e) => onchange(e.target.value)}
      disabled={disabled && disabled}
    />
  );
};

export default Input;
