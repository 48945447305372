import React from "react";
import styles from "./style.module.css";
import locationICon from "../../../Assets/locationIcon.png";
import locationBlack from "../../../Assets/locationBlackIcon.png";
import { FaCheckCircle } from "react-icons/fa";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaGlassMartiniAlt } from "react-icons/fa";
import { ImSpoonKnife } from "react-icons/im";
import { FaBed } from "react-icons/fa";
import Button from "../../../Components/Button";
import { IoEyeOutline } from "react-icons/io5";

function StageCard({ value }) {
  return (
    <div className={styles.parent}>
      <div>
        <section className={styles.child}>
          <h5>
            {value == 1
              ? "First"
              : value === 2
              ? "2nd"
              : value === 3
              ? "3rd"
              : "4th"}{" "}
            Stage Name
          </h5>
          <p>
            <span>
              <FaCheckCircle size={20} />
            </span>
            <span>Completed</span>
          </p>
        </section>
        <section className={`${styles.child}`}>
          <p>
            <img className="me-2" src={locationICon} width={20} />
            <small>Stage 1</small>
          </p>
          <p>
            <img className="me-2" src={locationBlack} width={20} />
            <small>23 KM Away</small>
          </p>
        </section>
        <section className={`my-2 ${styles.child}`}>
          <p>
            <small>Start Date: 16/12/2022</small>
          </p>
          <p className="pe-2">
            <MdOutlineWatchLater color="gray" className="me-2" size={20} />
            <small>3:30 Hours</small>
          </p>
        </section>
        <section className={` ${styles.child}`}>
          <p>
            <FaGlassMartiniAlt color="gray" size={20} />
            <ImSpoonKnife color="gray" size={20} className="mx-3" />
            <FaBed color="gray" size={20} />
          </p>
          <Button
            label={"View Details"}
            style={{
              padding: "2px 10px",
              fontSize: "12px",
            }}
            Icon={<IoEyeOutline className="me-1" />}
          />
        </section>
      </div>
      <div></div>
    </div>
  );
}

export default StageCard;
