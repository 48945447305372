export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const showToast = (message, type) => {
    return {
        type: SHOW_TOAST,
        payload: { message, type }
    }
}

export const hideToast = () => {
    return {
        type: HIDE_TOAST
    }
}