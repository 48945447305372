import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import img1 from "../../../../Assets/Mask Group 261.png";
import img2 from "../../../../Assets/Mask Group 264.png";
import img3 from "../../../../Assets/Mask Group 265.png";
import { GrMap } from "react-icons/gr";
import { AiOutlineCheck, AiOutlineCheckCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Heading from "../../../../Components/Heading";
import Input from "../../../../Components/Input";
import Button from "../../../../Components/Button";
import { BsUpload } from "react-icons/bs";
import Select from "react-select";
import API from "../../../../Config/API";

const ReportIncident = () => {

  // const [checkbox1, setCheckbox1] = useState(false);
  // const [checkbox2, setCheckbox2] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);
  const [show, setShow] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [selectedMunc, setSelectedMunc] = useState("");
  const [municipalities, setMunicipality] = useState([]);
  const [muncOptions, setMuncOptions] = useState([]);

  useEffect(() => {
    getUserfromLS();
  }, [])

  const getUserfromLS = async () => {
    const storedUser = await JSON.parse(localStorage.getItem("currentUser"));
    setCurrentUser(storedUser);
  }

  const handleClose = () => setShow(false);
  const checkMark = () => {
    setToggleCheck(!toggleCheck);
    // setCheckbox1(!toggleCheck);
  };

  const submit = () => {
    setShow(true);
  };
  setTimeout(() => {
    setShow(false);
  }, 5000);

  // Get Municipalities
  useEffect(() => {
    currentUser && getMuncipalties();
  }, [currentUser]);

  const getMuncipalties = async () => {
    try {
      const response = await API.post("/get-all-muncipalities", { userId: currentUser?.id });
      // console.log(response);
      setMunicipality(response?.data?.data);
      setMuncOptions(response?.data?.data.map(municipality => ({ value: municipality, label: municipality?.title })));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className={`container ${styles.mainContainer}`}>
        <section className={`${styles.heading}`}>
          <Heading label={"Report Incident"} />
        </section>
        <section className={`${styles.imgSection}`}>
          <div className={`${styles.headingContainer}`}>
            <h6>Upload Upto 5 Pictures</h6>
          </div>
          <div className={`${styles.imagesContainer}`}>
            <div className={`${styles.imgBox} ${styles.inpFileBox}`}>
              <label>
                <BsUpload className={styles.uploadIcon} />
                <input type="file" />
              </label>
            </div>
            <div className={`${styles.imgBox}`}>
              <img src={img2} alt="" />
            </div>
            <div className={`${styles.imgBox}`}>
              <img src={img3} alt="" />
            </div>
            <div className={`${styles.imgBox}`}>
              <img src={img1} alt="" />
            </div>
          </div>
        </section>

        <section className={`${styles.inpSection}`}>
          <section className={styles.inpBox}>
            <Input placeholder="Name" type="text" />
          </section>
          <section className={styles.inpBox}>
            <Input placeholder="Email" type="text" />
          </section>
        </section>
        <section className={`${styles.inpSection}`}>
          <section className={styles.inpBox}>
            <Input placeholder="Phone Number" type="text" />
          </section>
          <section className={styles.muncSelectContainer}>
            <Select options={muncOptions} className={styles.muncSelect} />
          </section>
        </section>
        <section className={styles.locationInpBox}>
          <Input placeholder="Location" type="text" />
          <GrMap fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
        </section>
        <section className={`${styles.textAreaSection}`}>
          {/* <section className={styles.textBox}>Description</section> */}
          <textarea placeholder="Description" />
        </section>

        <div className={styles.checkBoxContainer}>
          {toggleCheck ? (
            <div className={styles.checkBox} onClick={checkMark}>
              <AiOutlineCheck fontSize={"0.9rem"} />
            </div>
          ) : (
            <div className={styles.checkBox2} onClick={checkMark}>
              <AiOutlineCheck fontSize={"0.9rem"} />
            </div>
          )}

          <Link className={`${styles.termsAndCondition}`}>
            I agree to VEYVE
            <span>terms and conditions</span>
          </Link>
        </div>

        <div className="mt-5" onClick={submit}>
          <Button label="Submit" width="100%" />
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className={`d-flex justify-content-center align-items-center`}
      >
        <Modal.Body className={`py-5 px-3 ${styles.modal_body}`}>
          <section className="text-center">
            <AiOutlineCheckCircle color="#34c89a" fontSize={"5rem"} />
          </section>
          <section className="text-center mt-3">
            <h5 style={{ fontWeight: "bold" }}>Submitted Successfully</h5>
            <p className="mt-3 fs-4">
              Your Have Successfully Submitted Point of Intrest,Point will be
              activated once admin approve,Thank You.
            </p>
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportIncident;
