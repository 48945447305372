import React from "react";
import Heading from "../../../../Components/Heading";
import Cards from "./Cards/Cards";
import styles from "./mydownloads.module.css";

const MyDownloads = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <section className={`${styles.heading}`}>
          <Heading label="My Downloads" color="#35C89B" />
        </section>
        <section className={`${styles.cardSection}`}>
          <Cards />
        </section>
      </div>
    </>
  );
};

export default MyDownloads;
