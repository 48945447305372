import React from "react";
import Heading from "../../../../Components/Heading";
import styles from "./style.module.css"
import Logo from "../../../../Assets/logo.png"
import { ImStarEmpty, ImStarFull } from "react-icons/im"
import Button from "../../../../Components/Button";

const RateVevye = () => {
  return (
    <section className={styles.mainBox}>
      <div className={styles.headingBox}>
        <Heading label={"Rate Veyve"} style={{ fontWeight: "bold" }} />
      </div>
      <div className={styles.logoBox}>
        <img src={Logo} alt="Logo" width={"100%"} height={"100%"} />
      </div>
      <div className={styles.reviewBox}>
        <Heading label={"Your opinion matter to us!"} style={{ fontWeight: "bold", fontSize: "1.5rem" }} />
        <div className={styles.subHeadingBox}>
          <Heading label={"Rate & Review"} style={{ fontWeight: "bold", fontSize: "1.2rem" }} />
          <p>Share your experience to help others</p>
        </div>
        <div className={styles.starBox}>
          <ImStarFull size={25} color="#34c89a" />
          <ImStarFull size={25} color="#34c89a" />
          <ImStarFull size={25} color="#34c89a" />
          <ImStarEmpty size={25} color="gray" />
          <ImStarEmpty size={25} color="gray" />
        </div>
        <div className={styles.textAreaContainer}>
          <textarea placeholder="Your Comments" />
        </div>
        <div className={styles.rateBtn}>
          <Button label={"Rate Now"} width={"100%"} />
        </div>
      </div>
    </section>
  )
};

export default RateVevye;
