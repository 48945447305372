import axios from "axios";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import GoogleMap from "./GoogleMap";
import Heading from "../../../Components/Heading";
import citiesData from "../../../Assets/Data/cities.json";
import provinceData from "../../../Assets/Data/province.json";

import styles from "./styles.module.css";
import API from "../../../Config/API";
import { useTranslation } from "react-i18next";

const GeneralDataForm = ({ generalData, setGeneralData }) => {

  const [inputValues, setInputValues] = useState({
    direction: "Spain",
    postalCode: "",
    country: "",
    province: "",
    city: "",
  });
  const { t } = useTranslation();


  const [cities, setCities] = useState([]);
  const [province, setProvince] = useState([]);
  const [country, setCountry] = useState([]);
  const [munuciplaities, setMunuciplaities] = useState([]);
  const [currentUser, setCurrentUser] = useState("");

  // Get Cities from API
  // const getCities = async () => {
  //   try {
  //     const res = await axios.post(
  //       "https://countriesnow.space/api/v0.1/countries/cities",
  //       { country: "Spain" }
  //     );

  //     const formattedCities = res?.data?.data?.map((city) => ({
  //       value: city,
  //       label: city
  //     }))
  //     setCities(formattedCities);

  //   } catch (error) {

  //   }
  // }
  // useEffect(() => {
  //   getCities();
  // }, [])

  // Get Cities and Province from JSON
  useEffect(() => {
    const formattedArray = citiesData.map((cityObj) => ({
      value: cityObj,
      label: cityObj.city,
    }));
    setCities(formattedArray);

    const formattedProvince = provinceData.map((city) => ({
      value: city,
      label: city,
    }));
    setProvince(formattedProvince);
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  const handleMapChange = (e) => {
    setGeneralData({
      ...generalData,
      "lat": e.lat,
      "lng": e.lng,
    });
  }

  const handleSelectCoutnry = (e) => {
    setInputValues({ ...inputValues, "country": e.value });
    setCountry(["Spain"]);
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setGeneralData({ ...generalData, [name]: value });
  };

  useEffect(() => {
    getMuncipality();
  }, [currentUser]);

  const getMuncipality = async () => {
    try {
      const res = await API.post("/get-all-muncipalities", { userId: currentUser?.id });
      if (res?.data?.data) {
        if (res?.data?.data?.length > 0) {
          const formattedArray = res?.data?.data?.map((val) => ({
            id: val?.id,
            value: val?.title,
            label: val?.title,
          }));
          setMunuciplaities(formattedArray);
        }
      }
      // console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  const handleMunucipality = (e) => {
    localStorage.setItem("muncipalityId", e.id);
    setInputValues({ ...inputValues, "munuciplaities": e.value });
  };

  const addMuncipality = (e) => {
    e.preventDefault();
    if (
      !inputValues?.direction ||
      !inputValues?.postalCode ||
      !inputValues?.country ||
      !inputValues?.province ||
      !inputValues?.city
    ) {
      alert("Empty Fields");
    }
  };

  const countryName = [{ value: "Spain", label: "Spain" }];

  return (
    <form className={`p-4`} onSubmit={addMuncipality}>
      <section>
        <Heading label={t("Location")} fontSize="1.5rem" color="#35C89B" />
      </section>
      <section className={styles.selectContainer}>
        <Select
          onChange={handleMunucipality}
          className={styles.selectBox}
          options={munuciplaities}
          placeholder={`${t("detailScreen.muncipality")}...`}
        />
        <Select
          onChange={handleSelectCoutnry}
          className={styles.selectBox}
          options={countryName}
          placeholder="Country.."
        />
      </section>
      <section>
        <GoogleMap city={inputValues?.city} handleMapChange={handleMapChange} />
      </section>
    </form>
  );
};

export default GeneralDataForm;
