import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { BsDownload } from "react-icons/bs";
import ModalCompForCropping from "../ModalCompForCropping";
import styles from "../style.module.css";

const OriginalUpload = ({
  setOriginalVideo,
  showVideoModal,
  setshowVideoModal,
  handleUploadClick,
  multyVideos,
  loading,
}) => {
  const [file, setFile] = useState(null);
  const [croppedImg, setcroppedImg] = useState(null);

  const handleModalClose = () => {
    setshowVideoModal(false);
  };

  const handleDownloadClick = () => {
    setshowVideoModal(true);
  };

  const handleCroppedImg = (data) => {
    setcroppedImg(data?.previewImg);
    setOriginalVideo(data?.file);
    setFile(data?.file);
  };

  return (
    <>
      <section className={styles.inpFileBox2}>
        {croppedImg ? (
          <label className="d-flex justify-content-center align-items-center text-center">
            <p style={{ fontSize: "1.2rem" }} className="text-muted">
              {file?.name}
            </p>
          </label>
        ) : (
          <div className="d-flex">
            {multyVideos?.map((item, index) => (
              <div
                key={index}
                className="d-flex justify-content-center w-100 align-items-center m-1"
              >
                <label>{item.split("/")[1]}</label>
              </div>
            ))}
          </div>
        )}
      </section>
      <ModalCompForCropping
        show={showVideoModal}
        handleClose={handleModalClose}
        title={"Your Video"}
        onImgData={handleCroppedImg}
        handleUploadClick={handleUploadClick}
        loading={loading}
      />
    </>
  );
};

export default OriginalUpload;
