import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../../Config/API";
import PointsInterestSlider from "../PointsInterestSlider";
import RightSideContent from "../RightSideContent";
import styles from "./styles.module.css";
import { useDispatch } from "react-redux";
import { watchModule } from "../../../Store/Actions/detailsModuleActions";

const InnerContent = ({ setTitle, cordinates }) => {
  // Getting ID from Params
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [lang, setLang] = useState("Spanish");
  const [filteredMuncipality, setFilteredMuncipality] = useState("");
  const [currentLoaction, setCurrentLocation] = useState("");
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);
  const dispatch = useDispatch();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentLocation(parsedLocation);
    setCurrentUser(parsedUser);
    // getMuncipality();
  }, [currentLocationUser]);

  const getMuncipality = async () => {
    // Calling Language API
    try {
      if (!currentLocationUser) return;
      const res = await API.post("/get-all-muncipalities", {
        userId: 2,
      });
      const data = res?.data?.data;

      setFilteredMuncipality(data);
    } catch (error) {
      console.log(error);
    }
  };

  setTitle(filteredMuncipality?.title);
  return (
    <div className={`d-flex justify-content-around ${styles.parent}`}>
      <section className={styles.child1}>
        <PointsInterestSlider pointData={filteredMuncipality} lang={lang} />
      </section>
      <section className={styles.child2}>
        <RightSideContent
          cordinates={cordinates}
          pointData={filteredMuncipality}
          lang={lang}
          setLang={setLang}
          setPointData={setFilteredMuncipality}
        />
      </section>
    </div>
  );
};

export default InnerContent;
