import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import locationIcon from "../../../Assets/locationIcon.png";
import searchIcon from "../../../Assets/searchIcon.png";
import Button from "../../../Components/Button";
import API from "../../../Config/API";
import Select from "react-select";
import MultiRangeSlider from "multi-range-slider-react";
import { useTranslation } from "react-i18next";

const HeaderBox = ({ btnValue, location, setAllData, cordinates }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [categories, setCategories] = useState([]);
  const [minDistanceValue, set_minDistanceValue] = useState(0);
  const [maxDistanceValue, set_maxDistanceValue] = useState(200);
  const [muncipalities, setMuncipalties] = useState([]);
  const [searchParams, setSearchParams] = useState({
    selectedCategory: "",
    keyword: "",
    latitude: currentLocation?.latitude,
    longitude: currentLocation?.longitude,
    language: "Spanish",
  });
  const { t } = useTranslation();

  const getMuncipalities = async () => {
    try {
      const res = await API.post("/get-all-muncipalities", { userId: 2 });
      setMuncipalties(res?.data?.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Get Current User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Current Location
    const currentLocation = localStorage.getItem("currentLocation");
    const parsedLocation = JSON.parse(currentLocation);
    setCurrentLocation(parsedLocation);

    // Get Current Language
    const userLanguage = localStorage.getItem("userLanguage");
    setCurrentLanguage(userLanguage);
  }, [localStorage?.userLanguage, localStorage?.currentLocation]);

  // Updating SearchParams whenever the location changes
  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      latitude: currentLocation?.latitude,
      longitude: currentLocation?.longitude,
    }));
  }, [currentLocation]);

  // Updating SearchParams whenever the language changes
  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      language: currentLanguage,
    }));
  }, [currentLanguage]);

  useEffect(() => {
    getCategories();
    getMuncipalities();
  }, [currentUser]);

  const getCategories = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/get-categories", {
          userId: currentUser?.id,
          type: "event",
        });
        setCategories(res?.data?.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const options = categories?.map((category) => ({
    label: category?.name,
    value: category,
  }));
  const muncipalitiesOptions = muncipalities?.map((munc) => ({
    label: munc?.title,
    value: munc,
  }));

  const handleCategoryChange = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      selectedCategory: selectedOption.value,
    });
  };
  const handleMuncChange = (selectedOption) => {
    setSearchParams({
      ...searchParams,
      selectedMunc: selectedOption.value,
    });
  };

  const handleDistanceChange = (value) => {
    set_minDistanceValue(value?.minValue);
    set_maxDistanceValue(value?.maxValue);
  };

  const handleKeywordChange = (event) => {
    setSearchParams({
      ...searchParams,
      keyword: event.target.value,
    });
  };

  const handleSearch = async () => {
    try {
      const res = await API.post("/search", {
        searchType: "events",
        search: searchParams?.keyword,
        language: searchParams?.language,
        latitude:
          String(cordinates?.latitude) == "undefined"
            ? ""
            : String(cordinates?.latitude),
        longitude:
          String(cordinates?.longitude) == "undefined"
            ? ""
            : String(cordinates?.longitude),
        categoryId: searchParams?.selectedCategory?.id,
        muncipalityId: searchParams?.selectedMunc?.id,
        distance: maxDistanceValue,
      });
      let filterData = res?.data?.data.filter(
        (val) => val?.status?.toLowerCase() === "active"
      );
      setAllData(filterData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <section
        className={`row ${
          location === "home" ? styles.header_box : styles.header_other
        } `}
      >
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={`px-1 py-2 ${styles.header_box_1}`}>
            <img
              src={searchIcon}
              className={styles.searchIcon}
              width="20px"
              height="20px"
              alt="searchIcon"
            />
            <input
              type="text"
              placeholder={t("Select-Keyword")}
              className={styles.inp}
              onChange={handleKeywordChange}
            />
          </section>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={styles.inpBox}>
            <span
              className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
            >
              <img width="20px" src={locationIcon} alt="arrowDown" />
              <Select
                options={options}
                onChange={handleCategoryChange}
                className={styles.muncSelect}
                placeholder={t("Select-Category")}
              />
            </span>
          </section>
        </div>
        <div className="col-lg-2 col-md-6 col-sm-6 col-12 py-3">
          <section className={styles.inpBox}>
            <span
              className={`${styles.selectContainer} d-flex align-items-center justify-content-start`}
            >
              <img width="20px" src={locationIcon} alt="arrowDown" />
              <Select
                className={styles.muncSelect}
                // isDisabled={isMunicipalityDisabled}
                options={muncipalitiesOptions}
                placeholder={t("Select-Muncipality")}
                onChange={handleMuncChange}
              />
            </span>
          </section>
        </div>
        <div
          className={`col-lg-3 col-md-6 col-sm-6 col-12 py-3  ${styles.progessParent}`}
        >
          <MultiRangeSlider
            onChange={handleDistanceChange}
            min={0}
            max={200}
            minValue={minDistanceValue}
            step={0.01}
            maxValue={maxDistanceValue}
            labels={[`${minDistanceValue}km`, `${maxDistanceValue}km`]}
            ruler={false}
            baseClassName={styles.multiRangeContainer}
            className={styles.multiRangeSlider}
            barInnerColor="#35c89b"
            barLeftColor="#fff"
            barRightColor="#fff"
            thumbLeftColor="#35c89b"
            thumbRightColor="#35c89b"
          />
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 col-12 py-3">
          <section>
            <a href="#target">
              <Button label={btnValue} onClick={handleSearch} width="100%" />
            </a>
          </section>
        </div>
      </section>
    </div>
  );
};

export default HeaderBox;
