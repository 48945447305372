import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Heading from "../../Components/Heading";
import { navAction } from "../../Store/Actions/navAction";
import mapIcon from "../../Assets/mapIcon.png";
import CustomHeader from "../../Components/CustomHeader";
import HeaderBox from "../Home/HeaderBox";
import { useTranslation } from "react-i18next";
import RouteMap from "../../Components/GoogleMap/RouteMap";

const RoutesMap = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("map"));
  }, []);

  return (
    <div>
      <CustomHeader icon={false} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox btnValue={t("Search")} />
        <div className="container py-4 pb-5">
          <Heading label={t("map")} img={mapIcon} bottom="10px" width="80px" />

          <RouteMap />
        </div>
      </div>
    </div>
  );
};

export default RoutesMap;
