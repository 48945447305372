import React, { useCallback, useEffect, useState } from "react";
import L from "leaflet";
import { Marker, useMap } from "react-leaflet";
import API from "../../Config/API";
import CardModal from "./CardModal";
import "./Map.css";
import useSupercluster from "use-supercluster";
import serviceMarker from "../../Assets/Services.png";

const pointMarker = new L.icon({
  iconUrl: serviceMarker,
  shadowUrl: "leaf-shadow.png",
  iconSize: new L.Point(35, 25),
});

const icons = {};
const fetchIcon = (count, size) => {
  if (!icons[count]) {
    icons[count] = L.divIcon({
      html: `<div class="cluster-markeres" style="width: ${size}px; height: ${size}px;">
        ${count}
      </div>`,
    });
  }
  return icons[count];
};

function ShowServices({ servicesData }) {
  const [show, setShow] = useState(false);
  const [value, setValue] = useState({});
  const [type, setType] = useState("");
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();
  const maxZoom = 22;

  const showEvents = async (showEvents) => {
    try {
      let response = await API.post("/get-service-lang", {
        showEvents,
        language: "Spanish",
      });
      console.log(response);
      setValue(response.data.data);
      setShow(true);
      setType("events");
    } catch (error) {}
  };

  // get map bounds
  function updateMap() {
    const b = map.getBounds();
    setBounds([
      b.getSouthWest().lng,
      b.getSouthWest().lat,
      b.getNorthEast().lng,
      b.getNorthEast().lat,
    ]);
    setZoom(map.getZoom());
  }

  const onMove = useCallback(() => {
    updateMap();
  }, [map]);

  React.useEffect(() => {
    updateMap();
  }, [map]);

  useEffect(() => {
    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map, onMove]);

  const serviceData = servicesData ? servicesData : [];
  const points = serviceData.map((crime) => {
    // console.log("points Latitude ", crime.longitude);
    // if (crime?.longitude && crime?.latitude) {
    return {
      type: "Feature",
      properties: {
        cluster: false,
        crimeId: crime.id,
        category: crime.categoryId,
      },
      geometry: {
        type: "Point",
        coordinates: [
          parseFloat(crime?.longitude),
          parseFloat(crime?.latitude),
        ],
      },
    };
    // }
  });

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });
  return (
    <>
      <CardModal show={show} setShow={setShow} value={value} type={type} />
      {clusters.map((cluster) => {
        // every cluster point has coordinates
        const [longitude, latitude] = cluster.geometry.coordinates;
        // the point may be either a cluster or a crime point
        const { cluster: isCluster, point_count: pointCount } =
          cluster.properties;

        // we have a cluster to render
        if (isCluster) {
          return (
            <Marker
              key={`cluster-${cluster.id}`}
              position={[latitude, longitude]}
              icon={fetchIcon(
                pointCount,
                10 + (pointCount / points.length) * 40
              )}
              eventHandlers={{
                click: () => {
                  const expansionZoom = Math.min(
                    supercluster.getClusterExpansionZoom(cluster.id),
                    maxZoom
                  );
                  map.setView([latitude, longitude], expansionZoom, {
                    animate: true,
                  });
                },
              }}
            />
          );
        }

        // we have a single point (crime) to render
        return (
          <Marker
            key={`crime-${cluster.properties.crimeId}`}
            position={[latitude, longitude]}
            icon={pointMarker}
            eventHandlers={{
              click: () => {
                showEvents(cluster.properties.crimeId);
              },
            }}
          />
        );
      })}
    </>
  );
}

export default ShowServices;
