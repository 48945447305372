import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import "leaflet.offline";
import L from "leaflet";
import "./Map.css";
import "leaflet/dist/leaflet.css";
import { useLocation } from "react-router-dom";
import marker from "../../Assets/Marker.png";
import manIcon from "../../Assets/manIcon.png";

const pointMarker = new L.icon({
  iconUrl: marker,
  shadowUrl: "leaf-shadow.png",
  iconSize: new L.Point(25, 35),
});
const userMarker = new L.icon({
  iconUrl: manIcon,
  shadowUrl: "leaf-shadow.png",
  iconSize: new L.Point(40, 40),
});

function RouteMap() {
  const { state } = useLocation();
  const [location, setLocation] = useState(null);
  const [map, setMap] = useState();

  useEffect(() => {
    const trackLocation = () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.watchPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setLocation({ latitude, longitude });
          },
          (error) => {
            console.error(error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    };

    trackLocation();
  }, []);

  useEffect(() => {
    if (map) {
      const tileLayerOffline = L.tileLayer.offline(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          subdomains: "abc",
          minZoom: 13,
          maxZoom: 16,
        }
      );

      tileLayerOffline.addTo(map);

      const controlSaveTiles = L.control.savetiles(tileLayerOffline, {
        zoomlevels: [13, 14, 15, 16], // optional zoomlevels to save, default current zoomlevel
        confirm(layer, succescallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
            succescallback();
          }
        },
        confirmRemoval(layer, successCallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm("Remove all the tiles?")) {
            successCallback();
          }
        },
        saveText:
          '<i class="fas fa-download" aria-hidden="true" title="Save tiles"></i>',
        rmText:
          '<i class="fas fa-trash" aria-hidden="true"  title="Remove tiles"></i>',
      });

      controlSaveTiles.addTo(map);
    }
  }, [map]);

  let pointLocations = state?.routesArray?.map((v) => [v.point1, v.point2]);
  let crackPoint = pointLocations.map((v) =>
    v.map((v) => [v.latitude, v.longitude])
  );
  let flattenedArray = crackPoint.flat(1);

  return (
    <MapContainer center={[39.21377454981294, -3.4179556504347497]} zoom={6}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      {flattenedArray && location && (
        <Polyline
          positions={[
            ...flattenedArray,
            [location.latitude, location.longitude],
          ]}
          color="red"
        />
      )}
      {pointLocations &&
        pointLocations?.map((value, index) => {
          return value.map((val, ind) => {
            return (
              <Marker
                key={ind}
                position={[Number(val.latitude), Number(val.longitude)]}
                icon={pointMarker}
              />
            );
          });
        })}
      {location && (
        <Marker
          // key={index}
          position={[location.latitude, location.longitude]}
          icon={userMarker}
        />
      )}
    </MapContainer>
  );
}

export default RouteMap;
