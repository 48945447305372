import API from "../../Config/API";
import { CHECK_LANGUAGE } from "../Constants/userConstant";

export const checkLanguage = (pointId) => {
  return async (dispatch) => {
    try {
      const res = await API.post("/check-language", { pointId });
      if (res.data.status === 200) {
        dispatch({
          type: CHECK_LANGUAGE,
          payload: res,
        });
      } else {
        const error = res?.data;
        throw error;
      }
      return res;
    } catch (error) {}
  };
};
