import React, { useState } from "react";
import styles from "./style.module.css";
import Heading from "../../../../Components/Heading";
import Switch from "react-switch";
import { AiOutlineRight } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const MySettings = () => {
  const [status, setStatus] = useState("Inactive");
  const [checkbox, setCheckbox] = useState(false);
  const { t } = useTranslation();

  const checkMark = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
    setCheckbox(status === "active" ? false : true);
  };

  return (
    <section className={styles.mainBox}>
      <div className={`${styles.headingBox}`}>
        <Heading
          style={{ fontWeight: "bold" }}
          label={t("sidebar.mySettings")}
          color="#35C89B"
        />
      </div>
      <div className={`${styles.optionsContainer}`}>
        <section className={styles.optionBox}>
          <div>
            <h4>Push Notifications</h4>
          </div>
          <div>
            <Switch
              onChange={checkMark}
              checked={status === "active"}
              height={15}
              width={30}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#34c89a"
            />
          </div>
        </section>
        <section className={styles.optionBox}>
          <div>
            <h4>GPS Location</h4>
          </div>
          <div>
            <Switch
              onChange={checkMark}
              checked={status === "active"}
              height={15}
              width={30}
              uncheckedIcon={false}
              checkedIcon={false}
              onColor="#34c89a"
            />
          </div>
        </section>
        <section className={styles.optionBox}>
          <div>
            <h4>Radius of Action</h4>
          </div>
          <div>
            <AiOutlineRight fontWeight={"bold"} size={25} />
          </div>
        </section>
        <section className={styles.optionBox}>
          <div>
            <h4>Obtain Data</h4>
          </div>
          <div>
            <AiOutlineRight fontWeight={"bold"} size={25} />
          </div>
        </section>
        <section className={styles.optionBox}>
          <div>
            <h4>Delete Account</h4>
          </div>
          <div>
            <AiOutlineRight fontWeight={"bold"} size={25} />
          </div>
        </section>
      </div>
    </section>
  );
};

export default MySettings;
