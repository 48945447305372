import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button";
import Heading from "../../../Components/Heading";
import Input from "../../../Components/Input";
import styles from "./style.module.css";
import { GoMail } from "react-icons/go";
import { FaGoogle } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineLock, AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { loginAction, registerAction } from "../../../Store/Actions/authAction";
import Switch from "react-switch";
import OutlineButton from "../../../Components/OutlineBtn";
import { app, auth } from "../../../Config/firebase";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import API from "../../../Config/API";
import { showToast } from "../../../Store/Actions/toastActions";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Form = () => {
  const [status, setStatus] = useState("Inactive");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [toggleCheck, setToggleCheck] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const checkMark = () => {
    setToggleCheck(!toggleCheck);
    setCheckbox1(!toggleCheck);
  };
  const checkMark2 = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
    setCheckbox2(status === "active" ? false : true);
  };
  const authStates = useSelector((state) => state?.authReducer);

  const register = (e) => {
    e.preventDefault();

    if (
      name !== "" &&
      email !== "" &&
      password !== "" &&
      cpassword !== ""
      // checkbox1 !== false &&
      // checkbox2 !== false
    ) {
      if (password.length >= 6 && cpassword.length >= 6) {
        if (password !== cpassword) {
          alert("Password & Confirm Password are not Match");
        } else {
          const data = {
            name,
            email,
            password,
            cpassword,
            checkbox1,
            checkbox2,
          };
          dispatch(registerAction(data, navigate));
          // navigate("/login");

          // if (authStates?.registrationRes?.status === 401) {
          //   alert("Email Already Exist");
          // } else if (authStates?.registrationRes?.message !== "Registered successfully") {
          //   alert("Error Occurred");
          // } else {
          //   navigate("/login");
          // }
        }
      } else {
        dispatch(showToast("Please Enter Atleast 6 Word Password", "error"));
      }
    } else {
      dispatch(showToast("Require Field are Missing", "error"));
    }
  };

  // Signup With Gmail
  const handleSignUpWithGoogle = async (e) => {
    e.preventDefault();

    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const tokenRes = result._tokenResponse;
      // navigate("/");
      // console.log(tokenRes)

      // Send Googe Email and Name to Backend
      if (tokenRes) {
        try {
          const data = {
            email: tokenRes?.email,
            name: tokenRes?.fullName,
          };
          const response = await API.post("/gmail", data);
          // console.log(response);
          if (response?.data?.status) {
            alert("Signup Successfully");
            navigate("/follow-municipality");
            localStorage.setItem(
              "currentUser",
              JSON.stringify(response?.data?.data)
            );
            localStorage.setItem(
              "UserDetails",
              JSON.stringify(response?.data?.data)
            );
          }
        } catch (apiErr) {
          console.log(apiErr);
        }
      }
    } catch (firebaseError) {
      console.log(firebaseError);
    }
  };

  return (
    <form className={styles.formBox}>
      <section className={` ${styles.heading}`}>
        <Heading label={t("Create-An-Account")} color="#35C89B" />
        <Link to={"/login"} className={`${styles.Link}`}>
          {t("Have-an-account")}
          <span> {t("Login")}</span>
        </Link>
      </section>
      <div className="">
        <section className={styles.inpBox}>
          <AiOutlineUser fontSize={"1.3rem"} style={{ marginRight: 10 }} />
          <Input placeholder={t("Full-Name")} onchange={setName} type="text" />
        </section>
        <section className={styles.inpBox}>
          <GoMail fontSize={"1.3rem"} style={{ marginRight: "10px" }} />
          <Input
            placeholder={t("Email-Address")}
            onchange={setEmail}
            type="email"
          />
        </section>
      </div>
      <div className="">
        <section className={styles.inpBox}>
          <AiOutlineLock fontSize={"1.4rem"} style={{ marginRight: 5 }} />
          <Input
            placeholder={t("Passwrod")}
            type="password"
            onchange={setPassword}
          />
        </section>
        <section className={styles.inpBox}>
          <AiOutlineLock fontSize={"1.3rem"} style={{ marginRight: 10 }} />
          <Input
            placeholder="Confirm Passwrod"
            type="password"
            onchange={setCPassword}
          />
        </section>
      </div>

      <div className={styles.checkBoxContainer}>
        <div
          className={styles.checkBox}
          onClick={checkMark}
          style={{
            background: toggleCheck ? "#34c89a" : "white",
            color: toggleCheck ? "#fff" : "#34c89a",
          }}
        >
          <AiOutlineCheck fontSize={"0.9rem"} />
        </div>

        <Link className={`${styles.termsAndCondition}`}>
          I agree to VEYVE
          <span>terms and conditions</span>
        </Link>
      </div>
      <div className={styles.switchContainer}>
        <span>{t("improve-Sections-Accepted")}</span>

        <Switch
          onChange={checkMark2}
          checked={status === "active"}
          height={15}
          width={30}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#34c89a"
        />
      </div>

      <div className="mt-5">
        <Button label={t("signup")} width="100%" onClick={register} />
      </div>
      <div className="text-center mt-2">
        {/* <Heading label="Or"  color="#35C89B" /> */}
        <p className={`${styles.para}`}>{t("Or")}</p>
      </div>

      <div className="d-flex justify-content-center align-items-center">
        {authStates?.isLoading ? (
          <Spinner style={{ color: "#34c89a" }} />
        ) : (
          <OutlineButton
            Icon={<FaGoogle />}
            label={t("signup-with-gamil")}
            width="100%"
            onClick={handleSignUpWithGoogle}
          />
        )}
      </div>
    </form>
  );
};

export default Form;
