import React from "react";
import Slider from "react-slick";
import { pointDetailsCardData } from "../../../logics/Data";
import Card from "../Cards";

const RoutesSlider = ({ allData }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    centerPadding: "60px",

    speed: 500,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: "0px",
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        {
          allData && allData.length > 0
            ? allData?.map((value, index, arr) => {
              return (
                <div key={value.id}>
                  <Card key={value.id} value={value} index={index} arr={arr} />
                </div>
              );
            })
            : <h1 className="text-center fw-bold">No Routes</h1>
        }
      </Slider>
    </div>
  );
};

export default RoutesSlider;
