import React from "react";
import styles from "./style.module.css";
import locationIcon from "../../../Assets/locationssIcon.png";
import { useNavigate } from "react-router-dom";
import pointCard1 from "../../../Assets/ImagePlaceholder.png";
const Card = ({ value, arr, index }) => {
  const navigate = useNavigate();

  const pointDetails = () => {
    // const title = value?.title?.split(" ");
    // const formattedTitle = title?.join("");
    navigate(`/plan-routes` , {state:value});
  };

  return (
    <div
      className={styles.parent}
      style={{
        // border:"3px solid red",
        // borderRadius:"16px"
        cursor: "pointer",
      }}
      onClick={pointDetails}
    >
      <section
        className={styles.child1}
        style={{
          border: value.category === "premium" && "4px solid #35C89B",
        }}
      >
        {/* <img src={value.eventImg} width="100%" alt="cardImg" /> */}
        {value?.point1?.imagesArraySpanish[0].image ? (
          <img
            src={`https://veyveback.com/${value?.point1?.imagesArraySpanish[0].image}`}
            width="100%"
            height={"100%"}
            alt="cardImg"
          />
        ) : (
          <img src={pointCard1} width="100%" height={"100%"} alt="cardImg" />
        )}
      </section>
      <section className={styles.child2}>
        <div>
          <h5>{value?.point1?.title}</h5>
          {/* <p>
            {value?.description?.slice(0, 14).replace(/<\/?[^>]+(>|$)/g, "")}
          </p> */}
          <section
            style={{
              width: "60%",
            }}
            className="border rounded  p-2 px-2 d-flex justify-content-between align-items-center"
          >
            <img alt="icon" className="me-2" width="25px" src={locationIcon} />
            <span style={{ fontSize: 12 }}>{value.distance} KM Away</span>
          </section>
        </div>
        <div className="text-center">
          {index + 1}/{arr?.length}
        </div>
      </section>
    </div>
  );
};

export default Card;
