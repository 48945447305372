import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomHeader from "../../Components/CustomHeader";
import InnerContent from "./InnerContent";
import { useTranslation } from "react-i18next";

const MuncipalityDetails = ({ cordinates }) => {

  const [title, setTitle] = useState("");

  const { t } = useTranslation();

  return (
    <div>
      <CustomHeader label1={t("detailScreen.muncipality")} label2={title} />
      <div className="container py-5 f">
        <InnerContent setTitle={setTitle} cordinates={cordinates} />
        {/* <section className={styles.dropDownAndAudio}>
        <div style={{ width: 250 }}>
          <Audio />
        </div>
        <div className="ms-3">
          <FlagDropDown />
        </div>
      </section> */}
      </div>
    </div>
  );
};

export default MuncipalityDetails;
