const INITIAL_STATE = {
  activeNav: "",
  language: "Spanish"
};

const navReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "NAV_ACTION":
      return {
        ...state,
        activeNav: action.payload,
      };
    case "NAV_LANGUAGE_CHANGE":
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

export { navReducer };
