import React, { useEffect, useState } from "react";
import CardsSlider from "../../../Components/CardsSilder";
import Heading from "../../../Components/Heading";
import servicesIcon from "../../../Assets/servicesIcon.png";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../../Config/API";
import { Spinner } from "react-bootstrap";

const Services = ({ cordinates }) => {
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);

  const { t } = useTranslation();
  // Get Current Location
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    setCurrentLocation(parsedLocation);

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getServices();
      setIsApiCallInProgress(true);
    }
  }, [currentUser, currentLang, currentLocation]);
  const compareDistances = (a, b) => {
    return a.distance - b.distance;
  };

  const getServices = async () => {
    setIsLoading(true);
    try {
      let res;
      if (currentUser) {
        res = await API.post("/get-services", {
          userId: currentUser?.id,
          latitude: String(cordinates?.latitude) || "",
          longitude: String(cordinates?.longitude) || "",
          distance: 10000,
        });
      } else {
        res = await API.post("/get-services-web", {
          language: currentLang || "Spanish",
          latitude: String(cordinates?.latitude) || "",
          longitude: String(cordinates?.longitude) || "",
        });
      }
      let activeServices = res?.data?.data?.filter(
        (service) => service?.status === "Active"
      );
      activeServices.sort(compareDistances);
      setAllData(activeServices);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div className="container py-5 ">
      <section className={styles.eventHeader}>
        <Heading
          label={t("services")}
          img={servicesIcon}
          style={{ fontWeight: "bold" }}
          bottom="22px"
        />
        <Link to={"/services"}>
          <button>See All</button>
        </Link>
      </section>
      <section className={isLoading && "d-flex justify-content-center"}>
        {isLoading ? (
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        ) : (
          <CardsSlider data={allData} name="services" />
        )}
      </section>
    </div>
  );
};

export default Services;
