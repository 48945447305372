import React, { useState } from "react";
import CustomHeader from "../../Components/CustomHeader";
import Heading from "../../Components/Heading";
import HeaderBox from "./HeaderBox";
import eventsIcon from "../../Assets/eventsImg.png";
import EventCard from "./EventCard";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { navAction } from "../../Store/Actions/navAction";
import OutlineButton from "../../Components/OutlineBtn";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Events = ({ cordinates }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [allData, setAllData] = useState([]);
  const [currentUser, setCurrentUser] = useState("");

  const { t } = useTranslation();
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("events"));
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  return (
    <div>
      <CustomHeader icon={false} />
      <div
        style={{
          position: "relative",
          bottom: "20px",
        }}
      >
        <HeaderBox
          setAllData={setAllData}
          cordinates={cordinates}
          btnValue={t("Search")}
        />
        <div className={`container`}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Heading label={t("events")} img={eventsIcon} />
            {currentUser && (
              <OutlineButton
                Icon={<AiOutlinePlusCircle />}
                label={"Add Event"}
                width={"25%"}
                onClick={() => {
                  navigate("/add-event");
                  setToggle(true);
                }}
              />
            )}
          </div>
          <div className="mt-5" id="target">
            <EventCard
              allData={allData}
              cordinates={cordinates}
              setAllData={setAllData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
