import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import "./Map.css";
import API from "../../Config/API";
import "leaflet/dist/leaflet.css";
import ShowPointsOfInterest from "./ShowPointsOfInterest";
import ShowServices from "./ShowServices";
import ShowEvents from "./ShowEvents";
import "leaflet.offline";
import L from "leaflet";

function Map() {
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [pointData, setPointData] = useState();
  const [servicesData, setServicesData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [map, setMap] = useState();

  const getPoints = () => {
    API.post("/get-points-web", {
      language: "Spanish",
    }).then((res) => {
      let filterPoints = res?.data?.data?.filter(
        (val) => val?.status?.toLowerCase() === "active"
      );
      setPointData(filterPoints);
    });
  };

  const getService = async () => {
    try {
      let res;
      if (currentUser) {
        res = await API.post("/get-services", { userId: currentUser?.id });
      } else {
        res = await API.post("/get-services-web", {
          language: currentLang || "Spanish",
          lat: currentLocation?.latitude || "",
          long: currentLocation?.longitude || "",
        });
      }

      const services = res?.data?.data || [];

      const activeServices = services.filter(
        (service) => service.status === "Active"
      );

      setServicesData(activeServices);
    } catch (error) {
      console.error(error);
    } finally {
      // setIsLoading(false);
      // setIsApiCallInProgress(false);
    }
  };

  const getEvents = async () => {
    try {
      let res;
      if (currentUser) {
        // API call for authenticated user
        res = await API.post("/get-events", { userId: currentUser?.id });
      } else {
        // API call for non-authenticated user
        res = await API.post("/get-events-web", {
          language: currentLang || "Spanish",
          lat: currentLocation?.latitude || "",
          long: currentLocation?.longitude || "",
        });
      }

      // Extract events and premium events from the response
      const events = res?.data?.data || [];
      const premiumEvents = res?.data?.premium;

      // Filter only the events with status "Active"
      const activeEvents = events.filter((event) => event.status === "Active");

      // Combine active events and active premium events into a single array
      const allEvents = [...activeEvents, ...premiumEvents];

      // Set the combined events to the "EventsData" state
      setEventsData(allEvents);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPoints();
    getService();
    getEvents();
  }, []);

  useEffect(() => {
    if (map) {
      const tileLayerOffline = L.tileLayer.offline(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          subdomains: "abc",
          minZoom: 13,
          maxZoom: 16,
        }
      );

      tileLayerOffline.addTo(map);

      const controlSaveTiles = L.control.savetiles(tileLayerOffline, {
        zoomlevels: [13, 14, 15, 16], // optional zoomlevels to save, default current zoomlevel
        confirm(layer, succescallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm(`Save ${layer._tilesforSave.length}`)) {
            succescallback();
          }
        },
        confirmRemoval(layer, successCallback) {
          // eslint-disable-next-line no-alert
          if (window.confirm("Remove all the tiles?")) {
            successCallback();
          }
        },
        saveText:
          '<i class="fas fa-download" aria-hidden="true" title="Save tiles"></i>',
        rmText:
          '<i class="fas fa-trash" aria-hidden="true"  title="Remove tiles"></i>',
      });

      controlSaveTiles.addTo(map);
    }
  }, [map]);

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Current Location
    const currentLocation = localStorage.getItem("currentLocation");
    const parsedLocation = JSON.parse(currentLocation);
    setCurrentLocation(parsedLocation);

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  return (
    <MapContainer
      center={[39.21377454981294, -3.4179556504347497]}
      zoom={7}
      ref={setMap}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <ShowPointsOfInterest data={pointData} />
      <ShowServices servicesData={servicesData} />
      <ShowEvents eventsData={eventsData} />
    </MapContainer>
  );
}

export default Map;
