import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CustomModal({ show, handleClose }) {
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Download Mobile Application....!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Ignore
          </Button>
          <Button
            variant="primary"
            style={{
              background: "#35C89B",
            }}
            onClick={handleClose}
          >
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default CustomModal;
