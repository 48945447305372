import React from 'react'
import styles from "./style.module.css"
import Heading from '../../Components/Heading'
import CustomHeader from '../../Components/CustomHeader'

const AvisoLegal = () => {
  return (
    <>
      <CustomHeader icon={false} label1={"Aviso Legal"} />
      <section className={styles.mainBox}>
        <div className={styles.contentBox}>
          <div>
            <Heading label={"AVISO LEGAL Y TÉRMINOS DE USO"} style={{ fontSize: "1.3rem", fontWeight: "bold" }} />
            <p>En cumplimiento con el deber de información recogido en el art. 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación se hace constar:</p>
          </div>
          <div className={styles.listBox}>
            <li>
              <Heading label={"Datos identificativos del responsable"} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>El contenido de esta página web pertenece VEYVE TECNOLOGIC SL, con CIF número B72749088, empresa inscrita en el registro mercantil de Badajoz. El domicilio social de la empresa se encuentra en Calle Peon De La Villa 26 06427, Monterrubio De La Serena (Badajoz). España.</p>
          </div>
          <div className={styles.listBox}>
            <li>
              <Heading label={"Usuario y régimen de responsabilidades"} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>La navegación, acceso y uso de este sitio web le confiere la condición de usuario, que acepta, desde dicho acceso y/o uso, los presentes términos de uso sin perjuicio de la aplicación de la correspondiente normativa de obligado cumplimiento legal según el caso.</p>
            <p className='mx-5'>2.1.- Uso del sitio Web</p>
            <p>El sitio web proporciona el acceso a artículos, informaciones y datos (en adelante “los contenidos”) propiedad del sitio web. El uso de los contenidos, lectura de información o visita del mismo sitio web está destinado única y exclusivamente para personas mayores de edad, para más de 18 años, según estipula la normativa vigente española. El usuario asegura cumplir con los criterios necesarios para su utilización.</p>
            <p>El sitio web proporciona acceso a la lista de usuarios registrados en la plataforma, y permite que los usuarios puedan contactar con ellos. Permite que el usuario pueda contactar con otros usuarios reales. A su vez, la plataforma también permite el contacto con usuarios de entretenimiento ficticios que permiten hablar con los usuarios.</p>
            <p>La página web ofrecerá al usuario sistemas de suscripción mensual, de pago recurrente y compras de créditos para hacer uso de determinadas funciones del portal, a un precio fijo y unitario. El usuario podrá cancelar la suscripción en cualquier momento que lo desee antes del periodo de renovación. Una vez adquirida la suscripción o compra de un producto o servicio, se entenderá consumido, y por ello, no reembolsable.</p>
            <p>
              El usuario asume su responsabilidad en el uso correcto del sitio web. Esta responsabilidad se extenderá a:
              <ul>
                <li>
                  Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público.
                </li>
                <li>
                  Provocar daños en los sistemas físicos y lógicos del sitio web, de sus proveedores o de terceras personas, introducir o difundir en la red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados
                </li>
                <li>
                  El uso de la información, servicios y datos ofrecidos por el sitio web contrariamente a lo dispuesto por las presentes condiciones, la Ley, la moral, las buenas costumbres o el orden público, o que de cualquier otro modo puedan suponer lesión de los derechos de terceros o del mismo funcionamiento del sitio.
                </li>
              </ul>
            </p>
            <p>En cualquier caso, el sitio web no será responsable de las opiniones vertidas por los usuarios a través del blog u otras herramientas de participación que puedan crearse, conforme a lo previsto en la normativa de aplicación.</p>
            <p className='mx-5'>2.2.- Captura de información</p>
            <p>- Formulario donde el usuario deberá rellenar los campos de nombre, email, teléfono y asunto. Los datos facilitados a través del apartado “Contacto” serán usados con la finalidad de ponernos en contacto con usted para atender sus dudas o consultas y remitirle la información que pueda resultarle de interés de acuerdo con la petición formulada. En el momento del registro, el usuario proporciona sus datos personales, para poder realizar contacto con él, y enviarle notificaciones de promociones, acciones de marketing o información del propio sistema o plataforma, como de proyectos colaborativos del grupo.</p>
            <p>- Formularios de pago: Los formularios y secciones de pago, serán gestionadas a través de empresas terceras encargadas en exclusiva para tal fin, como el caso de Redsys. Serán las responsables de gestionar, tramitar y almacenar, en caso que sea necesario, de los datos e información de los usuarios. El sitio web, no se hace responsable del uso que puedan hacer agentes externos de los datos para el pago, tales como tarjeta de crédito, o números de cuenta bancaria.</p>
            <p>- Navegación y dirección IP: al navegar por esta web, el usuario facilita de forma automática al servidor de la web información relativa a su dirección IP, fecha y hora de acceso, el hipervínculo que le ha reenviado a éstas, su sistema operativo y el navegador utilizado. La Agencia Española de Protección de Datos considera la IP como dato de carácter personal. Esta web no utilizará la IP para identificar a sus usuarios, estos datos serán guardados y utilizados únicamente para el control y realización de estadísticas de acceso y visitas a la web, para analizar tendencias, administrar el sitio y para recopilar información demográfica sobre nuestra base de usuarios en su conjunto. En ningún caso serán comunicados o cedidos a terceros.</p>
          </div>

          <div className={styles.listBox}>
            <li>
              <Heading label={"Política de enlaces y exenciones de responsabilidad"} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>Como un servicio a nuestros visitantes, nuestro sitio web puede incluir hipervínculos a otros sitios que nos son operados o controlados por el sitio web. Por ello no se garantiza ni se hace responsable de la licitud, fiabilidad, utilidad, veracidad y actualidad de los contenidos de tales sitios web o de sus prácticas de privacidad y declara que en ningún caso procederá a examinar o ejercitar ningún tipo de control sobre el contenido de otros sitios de la red.</p>
            <p>El sitio web declara haber adoptado todas las medidas necesarias para evitar cualquier daño a los usuarios de su sitio web, que pudieran derivarse de la navegación por su sitio web. En consecuencia, no se hace responsable, en ningún caso, de los eventuales daños que por la navegación por Internet pudiera sufrir el usuario.</p>
          </div>
          <div className={styles.listBox}>
            <li>
              <Heading label={"Modificaciones"} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>El sitio web se reserva el derecho a realizar las modificaciones que considere oportunas, sin aviso previo, en el contenido de su sitio web. Tanto en lo referente a los contenidos del sitio web, como en las condiciones de uso del mismo. Dichas modificaciones podrán realizarse a través de su sitio web por cualquier forma admisible en derecho y serán de obligado cumplimiento durante el tiempo en que se encuentren publicadas en la web y hasta que no sean modificadas válidamente por otras posteriores.</p>
          </div>
          <div className={styles.listBox}>
            <li>
              <Heading label={"Propiedad intelectual e industrial "} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>El sitio web por sí o como cesionario, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo no limitativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.).</p>
            <p>Todos los derechos reservados. En virtud de lo dispuesto en la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización del sitio web.</p>
            <p>El usuario se compromete a respetar los derechos de Propiedad Intelectual e Industrial titularidad del sitio web. Podrá visualizar los elementos de la web e incluso imprimirlos, copiar copiarlos y almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico siempre y cuando sea, única y exclusivamente, para su uso personal y privado. El usuario deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas del sitio web.</p>
          </div>
          <div className={styles.listBox}>
            <li>
              <Heading label={"Certificado SSL (SECURE SOCKETS LAYER) "} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>El CERTIFICADO SSL proporciona autenticación, privacidad y seguridad de la información entre el sitio web y el usuario.</p>
            <p>El sitio web dispone de un certificado de seguridad que se utiliza para realizar conexiones seguras y se establece usando llaves preestablecidas, codificando y descodificando todos los datos enviados hasta que la conexión sea cerrada.</p>
          </div>
          <div className={styles.listBox}>
            <li>
              <Heading label={"Certificado SSL (SECURE SOCKETS LAYER) "} style={{ fontSize: "1.1rem", fontWeight: "bold", display: "inline" }} />
            </li>
            <p>El sitio web se reserva, asimismo, la facultad de presentar las acciones civiles o penales que considere oportunas por la utilización indebida de su sitio web y contenidos, o por el incumplimiento de las presentes condiciones.</p>
            <p>La relación entre el usuario y el prestador se regirá por la normativa vigente y de aplicación en el territorio español. De surgir cualquier controversia las partes podrán someter sus conflictos a arbitraje o acudir a la jurisdicción ordinaria cumpliendo con las normas sobre jurisdicción y competencia al respecto. El sitio web tiene su domicilio en Barcelona, España.</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default AvisoLegal
