import {
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS
} from "../Actions/authAction";

const INITIAL_STATE = {
  registrationRes: [],
  loginRes: "",
  isLoading: false,
  error: null,
  // updateProfile: {},
  // loginState: "",
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REGISTER_REQUEST:
    case LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        registrationRes: action.payload,
        isLoading: false
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginRes: action.payload,
        isLoading: false
      };
    case REGISTER_FAILURE:
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }
    // case "UPDATE_PROFILE":
    //   return {
    //     ...state,
    //     updateProfile: action.payload,
    //   };

    default:
      return state;
  }
};
export { authReducer };
