import React from "react";
import styles from "./style.module.css";
import locationMarker from "../../Assets/locationBlackIcon.png";

// import location from "../../Assets/locationssIcon.png";
import bannerImg from "../../Assets/banner.png";
import ImagePlaceholder from "../../Assets/ImagePlaceholder.png";
import { useNavigate } from "react-router-dom";

const PremiumEventCard = ({ value, onclick }) => {
  const navigate = useNavigate();
  const details = () => {
    navigate(`/premium-event/${value?.name}/${value?.id}`, { state: value });
  };
  // console.log("value", value.events[0].distance);
  let ind = value?.events?.filter(
    (v, ind) => v?.status?.toLowerCase() == "active"
  );
  return (
    <div className={styles.cardBox}>
      <section className={styles.cardImageBox} onClick={details}>
        {/* <img src={value.eventImg} alt="card1" width="100%" /> */}
        {value?.events[0]?.imagesArray &&
        value?.events[0]?.imagesArray[0]?.image ? (
          <img
            src={
              `https://veyveback.com/${value?.events[0]?.imagesArray[0]?.image}` ||
              bannerImg
            }
            alt={"Event"}
            height={"200"}
            width="100%"
          />
        ) : (
          <img
            src={ImagePlaceholder}
            alt={"logo"}
            width="100%"
            height={"200"}
          />
        )}
        <div className={styles.imgBoxText}></div>
      </section>
      <section className={styles.cardContentParent}>
        <h6>{value?.name} </h6>
        <section className={styles.cardContent}>
          <div className={styles.child1}>
            {value?.events[0]?.distance && (
              <div className={styles.locationBOx}>
                <section className={`d-flex ${styles.imgChild1}`}>
                  <img
                    src={locationMarker}
                    alt="locationMarker"
                    width="30px"
                    height="20px"
                  />
                </section>
                <section className={`d-flex ${styles.imgChild2}`}>
                  <span>{value?.events[0]?.distance} KM </span>
                </section>
              </div>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};

export default PremiumEventCard;
