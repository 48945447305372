import React from "react";
import NewsCard from "./MyNewsCard";
import styles from "./style.module.css"
import Heading from "../../../../Components/Heading";

const MyNews = () => {
  return (
    <section className={styles.mainBox}>
      <div className="mb-3">
        <Heading label={"My News"} style={{ fontSize: "2rem", fontWeight: "bold" }} />
      </div>
      <div className={styles.cardBoxParent}>
        <NewsCard />
      </div>
    </section>
  )
};

export default MyNews;
