import React from "react";
import styles from "./style.module.css";
import StageCard from "../StageCard";
import { MdOutlineWatchLater } from "react-icons/md";
import locationBlack from "../../../Assets/locationBlackIcon.png";
import locationICon from "../../../Assets/locationIcon.png";
import Button from "../../../Components/Button";
import { useNavigate } from "react-router-dom";

function LocationBox() {
  const navigate = useNavigate();
  return (
    <section className={styles.main}>
      <div className={styles.parent}>
        {[1, 2, 3, 4].map((val) => {
          return <StageCard value={val} key={val} />;
        })}
      </div>
      <div className={`p-3 ${styles.routesBox}`}>
        <div className={styles.routesParent}>
          <section className="d-flex">
            <div>
              <img src={locationBlack} width={20} />
            </div>
            <div className="ms-2">
              <p>
                Total : <span>270 KM</span>
              </p>
              <p>
                Completed : <span>120 KM</span>
              </p>
              <p>
                Remaining : <span>150 KM</span>
              </p>
            </div>
          </section>
          <section className="d-flex">
            <div>
              <MdOutlineWatchLater size={20} />
            </div>
            <div className="ms-2">
              <p>
                Estimated : <span>45 Hrs</span>
              </p>
              <p>
                Completed : <span>15 Hrs</span>
              </p>
              <p>
                Remaining : <span>30 Hrs</span>
              </p>
            </div>
          </section>
        </div>
        <div className={styles.routesParent}>
          <section className="d-flex align-items-end">
            <div>
              <img className="me-2" src={locationICon} width={20} />
            </div>
            <div>
              <p>
                Total Stages : <span>6</span>
              </p>
            </div>
          </section>
          <section className="d-flex align-items-end pe-4">
            <div>
              <img className="me-2" src={locationICon} width={20} />
            </div>
            <div>
              <p>
                Completed : <span>2</span>
              </p>
            </div>
          </section>
        </div>
        <div className="mt-2">
          <Button
            label={"Start Route"}
            onClick={() => navigate("/tourist-point")}
            width={"100%"}
          />
        </div>
      </div>
    </section>
  );
}

export default LocationBox;
