import React, { useEffect, useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { GoMail } from "react-icons/go";
import Switch from "react-switch";
import styles from "./linksection.module.css";
import { GrMap } from "react-icons/gr";
import Button from "../../../../Components/Button";
import OutlineButton from "../../../../Components/OutlineBtn";
import Heading from "../../../../Components/Heading";
import Input from "../../../../Components/Input";
import { HiLanguage } from "react-icons/hi2";
import { BiUserCheck } from "react-icons/bi";
import { useTranslation } from "react-i18next";
const MyProfile = () => {
  const [currentUser, setCurrentUser] = useState("");
  const [status, setStatus] = useState("Inactive");
  //   const [checkbox2, setCheckbox2] = useState(false);
  const { t } = useTranslation();

  const checkMark2 = () => {
    setStatus((curr) => (curr === "active" ? "Inactive" : "active"));
    // setCheckbox2(status === "active" ? false : true);
  };

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  // console.log(currentUser)

  return (
    <>
      <div className={styles.formBox}>
        <section className={` ${styles.heading}`}>
          <Heading label={t("sidebar.myProfile")} color="#35C89B" />
        </section>
        <section className={styles.inpBox}>
          <AiOutlineUser fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
          <Input
            disabled={true}
            placeholder="Full Name"
            value={currentUser?.name || "Test1"}
            type="text"
          />
        </section>
        <section className={styles.inpBox}>
          <GoMail fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
          <Input
            disabled={true}
            placeholder="Email Address"
            value={currentUser?.email}
            type="email"
          />
        </section>
        {/* <section className={styles.inpBox}>
          <GrMap fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
          <Input
            disabled={true}
            placeholder="Location"
            value={"Humanes de Madrid"}
            type="text"
          />
        </section> */}
        <section className={styles.inpBox}>
          <HiLanguage fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
          <Input
            disabled={true}
            placeholder="Language"
            value={currentUser?.language}
            type="text"
          />
        </section>
        <section className={styles.inpBox}>
          <BiUserCheck fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
          <Input
            disabled={true}
            placeholder="Role"
            value={currentUser?.role}
            type="text"
          />
        </section>

        <div className={styles.switchContainer}>
          <span>{t("sidebar.Improve")}</span>

          <Switch
            onChange={checkMark2}
            checked={status === "active"}
            height={15}
            width={30}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#34c89a"
          />
        </div>

        <div className="mt-5">
          <Button label={t("sidebar.editProfile")} width="100%" />
        </div>

        <div className={`${styles.btnBox}`}>
          <div>
            <OutlineButton label={t("sidebar.changePassword")} />
          </div>
          <div>
            <OutlineButton label={t("sidebar.changeLanguage")} />
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
