import React from "react";
import styles from "./style.module.css";
import locationRoutes from "../../Assets/locationBlackIcon.png";
import location from "../../Assets/locationIcon.png";
import { useState } from "react";

import Button from "../../Components/Button";
import { useNavigate } from "react-router-dom";
import Card from "./Cards";
import { pointDetailsCardData } from "../../logics/Data";
import { FaLongArrowAltRight } from "react-icons/fa";

function ManualRoutes() {
  const [selectedRoute, setSelectedRoute] = useState([]);
  const navigate = useNavigate();

  return (
    <div className={styles.parent}>
      <section className={styles.child}>
        <div>
          <h3>Select Routes</h3>
        </div>
        <div>
          <p>
            Lorem ipsum jipsum detod Lorem ipsum jipsum detod Lorem ipsum jipsum
            detod Lorem ipsum jipsum detod Lorem ipsum jipsum detod Lorem ipsum
            jipsum detod Lorem ipsum jipsum detod Lorem ipsum jipsum detod
          </p>
        </div>
        <div>
          <h5>Mieracoles. 16 dec 2022</h5>
        </div>
        <section className={`m-0 my-5 row p-0  ${styles.inResponsive}`}>
          {pointDetailsCardData.slice(0, 6).map((value, index, arr) => {
            return (
              <div className="col-lg-2 col-md-4 col-sm-6 ">
                <Card
                  key={value.id}
                  selectedRoute={selectedRoute}
                  setSelectedRoute={setSelectedRoute}
                  value={value}
                  index={index}
                  arr={arr}
                />
              </div>
            );
          })}
        </section>

        <div className={styles.locationBox}>
          <section>
            <div>
              <img src={locationRoutes} width={20} />
              <span>Distance: 0 KM</span>
            </div>
            <div>
              <img src={location} width={20} />
              <span>Stages: 0 </span>
            </div>
            <div className={styles.text}>
              <span>Monterrubia de la serena </span>
              <FaLongArrowAltRight />
              <span>Medeline </span>
            </div>
          </section>
          <section className={styles.btnBoxEnd}>
            <Button
              label={"Save & Continue"}
              onClick={() => navigate("/plan-routes", { state: {selectedRoute , check :"Manual"} })}
            />
          </section>
        </div>
      </section>
    </div>
  );
}

export default ManualRoutes;
