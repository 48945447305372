import React, { useEffect, useState } from "react";
import eventIcon from "../../../../Assets/eventsicon.png";
import pointIcon from "../../../../Assets/pointsicon.png";
import serviceIcon from "../../../../Assets/servicesicontab.png";
import newsIcon from "../../../../Assets/newsicontab.png";
import routesIcon from "../../../../Assets/routesicontab.png";
import Heading from "../../../../Components/Heading";
import CircleGroup from "./CircleGroup/CircleGroup";
import styles from "./myfavorite.module.css";
import PointsOfInterestCard from "../../../PointsOfInterest/PointsOfInterestCard";
import Cards from "./Cards/Cards";
import { useNavigate } from "react-router-dom";
import API from "../../../../Config/API";
import { useTranslation } from "react-i18next";

const MyFavorite = () => {
  const [currentUser, setCurrentUser] = useState();
  const [activeTab, setActiveTab] = useState("Events");
  const [pointsData, setPointsData] = useState([]);
  const [eventsData, setEventsData] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const { t } = useTranslation();

  const circleArr = [
    {
      icon: <img src={eventIcon} width={"100%"} />,
      heading: "Events",
      title: t("events"),
      id: 1,
      isActive: activeTab === "Events",
    },
    {
      icon: <img src={newsIcon} width={"100%"} />,
      heading: "News",
      title: t("news"),
      id: 2,
      isActive: activeTab === "News",
    },
    {
      icon: <img src={serviceIcon} width={"100%"} />,
      heading: "Services",
      title: t("services"),
      id: 3,
      isActive: activeTab === "Services",
    },
    {
      icon: <img src={pointIcon} width={"100%"} />,
      heading: "Point Of Interests",
      title: t("pointsOfInterest"),
      id: 4,
      isActive: activeTab === "Point Of Interests",
    },
    {
      icon: <img src={routesIcon} width={"100%"} />,
      title: t("routes"),
      heading: "Routes",
      id: 5,
      isActive: activeTab === "Routes",
    },
  ];

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  useEffect(() => {
    getFavorites();
  }, [currentUser]);

  // Favourite API Call
  const getFavorites = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/get-favourites", {
          userId: currentUser?.id,
        });

        let filterDataEvent = res?.data?.events?.filter(
          (val) => val.event?.status?.toLowerCase() === "active"
        );

        let filterDataPoint = res?.data?.points?.filter(
          (val) => val.point?.status?.toLowerCase() === "active"
        );
        let filterDataNews = res?.data?.news?.filter(
          (val) => val.news?.status?.toLowerCase() === "active"
        );
        let filterDataService = res?.data?.service?.filter(
          (val) => val.service?.status?.toLowerCase() === "active"
        );

        setPointsData(filterDataPoint);
        setEventsData(filterDataEvent);
        setNewsData(filterDataNews);
        setServiceData(filterDataService);
      } catch (error) {
        console.log(error);
      }
    }
  };

  // Handle Tab Click
  const handleTabClick = (tab) => {
    setActiveTab(tab.heading);
  };

  useEffect(() => {
    getDataByActiveTab();
  }, [activeTab]);

  const getDataByActiveTab = () => {
    switch (activeTab) {
      case "Events":
        return eventsData;
      case "News":
        return newsData;
      case "Services":
        return serviceData;
      case "Point Of Interests":
        return pointsData;
      case "Routes":
        return [];
      default:
        return [];
    }
  };

  return (
    <div className={styles.mainContainer}>
      <section className={styles.heading}>
        <Heading label={t("sidebar.myFavourites")} color="#35C89B" />
      </section>

      <section className={styles.circleGroup}>
        {circleArr.map((item) => (
          <CircleGroup
            key={item.id}
            icon={item.icon}
            h6={item.title}
            heading={item.heading}
            isActive={item.isActive}
            activeTab={activeTab}
            onClick={() => handleTabClick(item)}
          />
        ))}
      </section>

      <Cards data={getDataByActiveTab()} activeTab={activeTab} />
    </div>
  );
};

export default MyFavorite;
