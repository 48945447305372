import React from "react";
import styles from "./style.module.css";
import locationIcon from "../../../Assets/locationssIcon.png";
import { useNavigate } from "react-router-dom";
import glass from "../../../Assets/glass.png";
import spoon from "../../../Assets/spoonIcon.png";
import bed from "../../../Assets/bed.png";
import { FaLongArrowAltDown } from "react-icons/fa";
import { AiFillCheckCircle, AiOutlineCheckCircle } from "react-icons/ai";
import { useState } from "react";
const Card = ({ value, arr, index, selectedRoute, setSelectedRoute }) => {
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);

  const handelCheck = (value) => {
    setCheck(!check);

    if (!check) {
      setSelectedRoute([...selectedRoute, value]);
    } else {
      let filter = selectedRoute?.filter((v) => v.id !== value.id);
      setSelectedRoute(filter);
    }
  };

  return (
    <div
      className={styles.parent}
      style={{
        cursor: "pointer",
      }}
    >
      <section className={styles.child1}>
        <img src={value.eventImg} width="100%" height={"100%"} alt="cardImg" />
      </section>
      <section className={styles.child2}>
        <div className={styles.content}>
          <h6 className={`text-center m-0`}>Palaza Mayoure</h6>
          <FaLongArrowAltDown size={20} />
          <h6 className={`text-center m-0`}>Madrid</h6>
        </div>
        <div className={styles.content}>
          <p className={`text-center`}>
            <img src={locationIcon} width={20} />
            <span className="text-white mx-2">20 KM</span>
          </p>
        </div>
        <div className={styles.content}>
          <img src={glass} width={25} />
          <img className="mx-3" src={spoon} width={25} />
          <img src={bed} width={25} />
        </div>
        <div className={styles.content}>
          {check ? (
            <AiFillCheckCircle
              size={20}
              color="#34c89a"
              onClick={() => handelCheck(value)}
            />
          ) : (
            <AiOutlineCheckCircle
              size={20}
              onClick={() => handelCheck(value)}
            />
          )}
        </div>
      </section>
    </div>
  );
};

export default Card;
