import API from "../../Config/API";
import { showToast } from "./toastActions";

export const Action_handleCreateService = (payload, navigate, setIsLoading) => {
    return async (dispatch) => {
        try {
            const res = await API.post("/store-service", payload);
            if (res?.data?.status) {
                navigate(-1);
                dispatch(showToast("Service SuccessFully Created!", "info"));
            } else {
                setIsLoading(false);
                dispatch(showToast(res?.data?.message, "info"));
            }
            return res;
        } catch (error) {
            setIsLoading(false);
            dispatch(showToast("Something is wrong try again!", "error"));
            console.log("Action_handleCreateServie ==>", error);
        }
    };
};