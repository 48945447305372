import React from "react";
import styles from "./style.module.css";
import GoogleMapReact from "google-map-react";

const MapBox = () => {
  const defaultProps = {
    center: {
      lat: 39.21377454981294,
      lng: -3.4179556504347497,
    },
    zoom: 8,
  };
  const mapOptions = {
    zoomControl: false, // Disable zoom control icons
    fullscreenControl: false, // Disable fullscreen control
    // You can add more options as needed
  };
  return (
    <div>
      <div className={styles.mapBox}>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: "AIzaSyCPr-b3VlC4X08HU1jYqBPYeZrzt_Ci0SE",
          }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          options={mapOptions}
        ></GoogleMapReact>
      </div>
    </div>
  );
};

export default MapBox;
