import React from "react";
import { useNavigate } from "react-router-dom";
import PointsOfInterestCart from "../../../../../Components/PointsOfInterstCart";
import Heading from "../../../../../Components/Heading";
import EventsCard from "../../../../../Components/EventsCard";
import NewsCard from "../../../../../Components/NewsCard";
import ServicesCard from "../../../../../Components/ServicesCard";

const Cards = ({ data, activeTab }) => {
  return (
    <div className="row pb-3 ">
      {data?.map((value) => (
        <section key={value.id} className="col-lg-6 col-md-12 col-sm-12 col-12">
          {(() => {
            switch (activeTab) {
              case "Events":
                return <EventsCard value={value?.event} />;

              case "News":
                return <NewsCard value={value?.news} />;

              case "Services":
                return <ServicesCard value={value?.service} />;

              case "Point Of Interests":
                return <PointsOfInterestCart value={value?.point} />;

              case "Routes":
                return <Heading label={"Routes"} />;

              default:
                return null;
            }
          })()}
        </section>
      ))}
    </div>
  );
};

export default Cards;
