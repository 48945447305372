import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import PointsOfInterest from "./Pages/PointsOfInterest";
import Map from "./Pages/Map";
import Services from "./Pages/Services";
import News from "./Pages/News";
import RoutesPage from "./Pages/Routes";
import PremiumPointDetails from "./Pages/PremiumPointDetails";
import PointsOfInterestDetails from "./Pages/PointsOfInterestDetails";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import UserDetails from "./Pages/UserDetails/UserDetails";
import MyProfile from "./Pages/UserDetails/AllLinkSections/Profile Section/MyProfile";
import MyFavorite from "./Pages/UserDetails/AllLinkSections/Favorite Section/MyFavorite";
import MyMunicipalities from "./Pages/UserDetails/AllLinkSections/Municipalities/MyMunicipalities";
import MyDownloads from "./Pages/UserDetails/AllLinkSections/MyDownloads/MyDownloads";
import MySettings from "./Pages/UserDetails/AllLinkSections/MySettings/MySettings";
import RateVevye from "./Pages/UserDetails/AllLinkSections/RateVevye/RateVevye";
import ReportIncident from "./Pages/UserDetails/AllLinkSections/Report/ReportIncident";
import ShareApp from "./Pages/UserDetails/AllLinkSections/ShareApp/ShareApp";
import MyBookings from "./Pages/UserDetails/AllLinkSections/My Bookings/MyBookings";
import MyNews from "./Pages/UserDetails/AllLinkSections/My News/MyNews";
import MyEvents from "./Pages/UserDetails/AllLinkSections/My Events/MyEvents";
import AddPointOfIntrest from "./Pages/AddPointOfInterest";
import { useEffect, useState } from "react";
import FollowMunicipality from "./Pages/FollowMunicipality";
import PrivateRoute from "./Components/PrivateRoutes";
import PolíticaDeCookies from "./Pages/PolíticaDeCookies";
import PolíticaDePrivacidad from "./Pages/PolíticaDePrivacidad";
import AvisoLegal from "./Pages/AvisoLegal";
import EventDetails from "./Pages/EventDetails";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-input-range/lib/css/index.css";
import { hideToast } from "./Store/Actions/toastActions";
import NewsDetails from "./Pages/NewsDetails";
import Events from "./Pages/Events";
import PremiumEventDetails from "./Pages/PremiumEventDetails";
import ServiceDetails from "./Pages/ServiceDetails";
import AddEvent from "./Pages/AddEvent";
import AddService from "./Pages/AddService";
import ContactUs from "./Pages/ContactUs";
import RadiusOfAction from "./Pages/UserDetails/AllLinkSections/RadiusOfAction";
import RoutesDetails from "./Pages/RoutesDetails";
import PlanRoute from "./Pages/PlanRoute";
import ManualRoutes from "./Pages/ManualRoutes";
import SetRoutes from "./Pages/SetRoutes";
import HeadPremiumDetails from "./Pages/HeadPremiumDetails";
import TouristPoint from "./Pages/TouristPoint";
import RoutesDetail from "./Pages/RoutesDetail";
import RoutesMap from "./Pages/Map/RouteMap";
import MuncipalityDetails from "./Pages/MuncipalityDetails";

function App() {
  // Toast Component
  const toastState = useSelector((state) => state.toastReducer);
  const dispatch = useDispatch();
  const [cordinates, setCordinates] = useState();

  useEffect(() => {
    // Getting user's current location
    navigator.geolocation.getCurrentPosition(function (position) {
      setCordinates({
        ...cordinates,
        ["latitude"]: position.coords.latitude,
        ["longitude"]: position.coords.longitude,
      });
    });
  }, []);

  useEffect(() => {
    if (toastState.visible) {
      const options = {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      if (toastState.type === "success") {
        toast.success(toastState.message, options);
      } else if (toastState.type === "error") {
        toast.error(toastState.message, options);
      } else if (toastState.type === "info") {
        toast.info(toastState.message, options);
      }
      dispatch(hideToast());
    }
  }, [toastState, dispatch]);

  return (
    <>
      <BrowserRouter>
        <ToastContainer />

        <Navbar />
        <Routes>
          <Route path="/" element={<Home cordinates={cordinates} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/user-details" element={<UserDetails />}>
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="my-favourite" element={<MyFavorite />} />
            <Route path="my-municipalities" element={<MyMunicipalities />} />
            <Route path="my-downloads" element={<MyDownloads />} />
            <Route path="my-settings" element={<MySettings />} />
            <Route path="radius-of-action" element={<RadiusOfAction />} />
            {/* <Route path="rate-vevye" element={<RateVevye />} /> */}
            <Route path="report-incident" element={<ReportIncident />} />
            {/* <Route path="share-app" element={<ShareApp />} /> */}
            <Route path="my-bookings" element={<MyBookings />} />
            <Route path="my-news" element={<MyNews />} />
            <Route path="my-events" element={<MyEvents />} />
          </Route>
          <Route path="/register" element={<Register />} />
          {/* <PrivateRoute path="/follow-municipality" element={<FollowMunicipality />} /> */}
          <Route path="/follow-municipality" element={<FollowMunicipality />} />
          <Route path="/events" element={<Events cordinates={cordinates} />} />
          <Route
            path="/points-of-interest"
            element={<PointsOfInterest cordinates={cordinates} />}
          />
          <Route
            path="/add-point-of-interest"
            element={<AddPointOfIntrest />}
          />
          <Route path="/add-event" element={<AddEvent />} />
          <Route path="/add-service" element={<AddService />} />
          <Route path="/map" element={<Map />} />
          <Route path="/routes-map" element={<RoutesMap />} />
          <Route
            path="/services"
            element={<Services cordinates={cordinates} />}
          />
          <Route path="/news" element={<News />} />
          <Route path="/routes" element={<RoutesPage />} />
          <Route
            path="/routes-details/:name/:id"
            element={<RoutesDetail cordinates={cordinates} />}
          />
          <Route path="/routes-details" element={<RoutesDetails />} />
          <Route path="/plan-routes" element={<PlanRoute />} />
          <Route path="/manual-routes" element={<ManualRoutes />} />
          <Route path="/set-routes" element={<SetRoutes />} />
          <Route path="/tourist-point" element={<TouristPoint />} />
          <Route
            path="/premium-points/:name/:id"
            element={<PremiumPointDetails />}
          />
          <Route
            path="/head-point-premium/:headName/:name/:id"
            element={<PremiumPointDetails />}
          />
          <Route
            path="/history-points/:name/:id"
            element={<HeadPremiumDetails />}
          />
          <Route
            path="point/:name/:id"
            element={<PointsOfInterestDetails cordinates={cordinates} />}
          />
          <Route
            path="muncipality/:name/:id"
            element={<MuncipalityDetails cordinates={cordinates} />}
          />
          <Route
            path="points-of-interest-details/points/:id"
            element={<PointsOfInterestDetails cordinates={cordinates} />}
          />
          <Route
            path="points-of-interest-details/history/:id"
            element={<HeadPremiumDetails />}
          />
          <Route
            path="points-of-interest-details/premium/:id"
            element={<PremiumPointDetails cordinates={cordinates} />}
          />
          <Route
            path="premium-points/:premiumName/:name/:id"
            element={<PointsOfInterestDetails cordinates={cordinates} />}
          />
          <Route path="/premiumEvent/:id" element={<PremiumEventDetails />} />
          <Route
            path="/premium-event/:name/:id"
            element={<PremiumEventDetails />}
          />
          <Route
            path="/event-details/event/:id"
            element={<EventDetails cordinates={cordinates} />}
          />
          <Route
            path="/event-details/:name/:id"
            element={<EventDetails cordinates={cordinates} />}
          />
          <Route
            path="/service-details/service/:id"
            element={<ServiceDetails cordinates={cordinates} />}
          />
          <Route
            path="/service-details/:name/:id"
            element={<ServiceDetails cordinates={cordinates} />}
          />
          <Route path="/news-details/news/:id" element={<NewsDetails />} />
          <Route path="/news-details/:name/:id" element={<NewsDetails />} />
          <Route path="/aviso-legal" element={<AvisoLegal />} />
          <Route path="/política-de-cookies" element={<PolíticaDeCookies />} />
          <Route
            path="/política-de-privacidad"
            element={<PolíticaDePrivacidad />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
