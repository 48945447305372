import React from "react";
import Card from "react-bootstrap/Card";
import HotelImg from "../../../../Assets/card7.png"
import styles from "./bookingCards.module.css"

const PreviousBookings = () => {
    const previousBookings = [
        { id: 1, title: "Madrid XYZ", eventName: "Event 1", date: "2022-03-20" },
        { id: 2, title: "Madrid XYZ", eventName: "Event 2", date: "2022-04-10" },
    ];

    return (
        <div className={`${styles.cardBox} d-flex flex-wrap justify-content-between`}>
            {previousBookings.map((booking, index) => (
                <>
                    <section
                        className={styles.customCard}
                        style={{ borderBottom: index === previousBookings.length - 1 ? 'none' : '1px solid #ccc' }}
                    >
                        <div className={styles.imageBox}>
                            <img src={HotelImg} width={"100%"} height={"120px"} alt="Hotel" />
                        </div>
                        <div className={styles.textBox}>
                            <p className="fw-bold">
                                {booking.title}
                            </p>
                            <p className="text-muted">
                                {booking.eventName}
                            </p>
                            <p className="fw-bold">
                                {booking.date}
                            </p>
                        </div>
                    </section>
                </>
            ))}
        </div>
    );
};

const UpcomingBookings = () => {
    const upcomingBookings = [
        { id: 1, title: "Madrid XYZ", eventName: "Event 4", date: "2022-06-05" },
        { id: 2, title: "Madrid XYZ", eventName: "Event 5", date: "2022-07-15" },
    ];

    return (
        <div className={`${styles.cardBox} d-flex flex-wrap justify-content-between`}>
            {upcomingBookings.map((booking, index) => (
                <section
                    className={styles.customCard}
                    style={{ borderBottom: index === upcomingBookings.length - 1 ? 'none' : '1px solid #ccc' }}
                >
                    <div className={styles.imageBox}>
                        <img src={HotelImg} width={"100%"} height={"120px"} alt="Hotel" />
                    </div>
                    <div className={styles.textBox}>
                        <p className="fw-bold">
                            {booking.title}
                        </p>
                        <p className="text-muted">
                            {booking.eventName}
                        </p>
                        <p className="fw-bold">
                            {booking.date}
                        </p>
                    </div>
                </section>
            ))}
        </div>
    );
};

export { PreviousBookings, UpcomingBookings };
