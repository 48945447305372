import React from "react";
import Form from "./Form";
import styles from "./styles.module.css";
import logo from "../../Assets/Register Banner.png";
const FollowMunicipality = () => {
  return (
    <div className={styles.loginBox}>
      <section className={styles.imgBox}>
        <img src={logo} alt="logo" />
      </section>
      <section className={styles.formBox}>
        <Form />
      </section>
    </div>
  );
};

export default FollowMunicipality;
