import { Modal, Spinner } from "react-bootstrap";
import React, { createRef, useState } from "react";
import { BsDownload } from "react-icons/bs";
import Heading from "../../../Components/Heading";
import CustomButton from "../../../Components/Button";
import styles from "./style.module.css";
import { AiOutlinePlusCircle } from "react-icons/ai";
import DataCard from "./DataCard";
import Cropper from "react-cropper";
import API from "../../../Config/API";
import { useTranslation } from "react-i18next";

const ModalComp1 = ({
  show,
  handleClose,
  setAddServices,
  galleryImage,
  setShowGallery,
  setGalleryImage,
}) => {
  const [file, setFile] = useState(null);
  const [src, setSrc] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [inpVal, setInpVal] = useState("");
  const cropperRef = createRef();
  const { t } = useTranslation();

  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    setFile(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSrc(reader.result);
    };
  };
  const handleClickI = async () => {
    if (galleryImage) {
      setGalleryImage("");
      setAddServices((odl) => [
        ...odl,
        {
          image: galleryImage,
          title: inpVal,
        },
      ]);
      handleClose();
      setInpVal("");
      return;
    }
    setIsLoading(true);
    const randomNumber = Math.floor(Math.random() * 100);
    if (typeof cropperRef.current?.cropper !== "undefined") {
      await fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then(async (blob) => {
          const fd = new FormData();
          const fileC = new File([blob], file?.name + inpVal + randomNumber);
          fd.append("file", fileC);
          const res = await API.post("/upload-file", fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (res.data.status === true) {
            setAddServices((odl) => [
              ...odl,
              {
                image: res?.data?.data,
                title: inpVal,
              },
            ]);
            setFile("");
            setInpVal("");
            setSrc("");
            handleClose();
            setIsLoading(false);
          }
        });
    } else {
      setFile("");
      setInpVal("");
      setSrc("");
      handleClose();
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          <section className={styles.modalInpFileBox1}>
            {src || galleryImage ? (
              <Cropper
                ref={cropperRef}
                zoomTo={0.5}
                initialAspectRatio={1}
                src={src ? src : `https://veyveback.com/${galleryImage}`}
                viewMode={2}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            ) : (
              <label>
                <input
                  type={"file"}
                  width={170}
                  height={170}
                  onChange={handleFileChange}
                />
                <BsDownload className={styles.cropperDownloadIcon} />
              </label>
            )}
            <p
              className="text-center p-3"
              style={{
                color: "gray",
              }}
            >
              {file?.name || "Image"}
            </p>
          </section>
          <div className="d-flex  justify-content-center">
            <input
              type="text"
              style={{
                width: "50%",
                position: "relative",
                bottom: "20px",
                padding: 5,
              }}
              value={inpVal}
              placeholder={t("Title")}
              onChange={(e) => setInpVal(e.target.value)}
            />
          </div>
          {/* <div className="my-2">
            <CustomButton
              onClick={() => setShowGallery(true)}
              label={"Media Library"}
              width={"100%"}
            />
          </div> */}
          <section className="mt-1 d-flex justify-content-center">
            {isLoading ? (
              <Spinner style={{ color: "#35C89B" }} />
            ) : (
              <CustomButton
                onClick={handleClickI}
                label={t("Upload-Image")}
                width="100%"
              />
            )}
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
};

const AddedService = ({
  addServices,
  setAddServices,
  setShowGallery,
  showModal,
  setShowModal,
  galleryImage,
  setGalleryImage,
}) => {
  const handleModalClose = () => {
    setShowModal(false);
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="p-4">
        <section className={styles.morePointsBox}>
          <Heading
            label={t("Added Service")}
            fontSize="1.5rem"
            color="#35C89B"
          />
          <CustomButton
            label={t("Add Service Details")}
            width="250px"
            icon={<AiOutlinePlusCircle />}
            onClick={() => {
              setShowModal(true);
            }}
          />
        </section>
        <div className={styles.inpFileParent}>
          <DataCard addServices={addServices} />
        </div>
      </div>
      <ModalComp1
        show={showModal}
        handleClose={handleModalClose}
        addServices={addServices}
        setAddServices={setAddServices}
        galleryImage={galleryImage}
        setShowGallery={setShowGallery}
        setGalleryImage={setGalleryImage}
      />
    </>
  );
};

export default AddedService;
