import React, { useEffect, useState } from "react";
import CardsSlider from "../../../Components/CardsSilder";
import Heading from "../../../Components/Heading";
import eventsImg from "../../../Assets/eventsImg.png";
import styles from "./style.module.css";
import { Link } from "react-router-dom";
import API from "../../../Config/API";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Event = ({ cordinates }) => {
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);

  const { t } = useTranslation();
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Current Location

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getEvents();
      setIsApiCallInProgress(true);
    }
  }, [currentUser, currentLang, currentLocation, cordinates]);
  const compareDistances = (a, b) => {
    return a.distance - b.distance;
  };

  const getEvents = async () => {
    setIsLoading(true);

    try {
      let res;
      if (currentUser) {
        res = await API.post("/get-events", {
          userId: currentUser?.id,
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      } else {
        res = await API.post("/get-events-web", {
          language: currentLang || "Spanish",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      }

      const events = res?.data?.data || [];
      const premiumEvents = res?.data?.premium;

      let filteredArray = premiumEvents.filter((item) => {
        return item.events.some(
          (event) => event.status.toLowerCase() === "active"
        );
      });

      // Filter only the events with status "Active"
      const activeEvents = events.filter(
        (event) => event.status?.toLowerCase() === "active"
      );
      // Combine active events and active premium events into a single array
      const allEvents = [...activeEvents, ...filteredArray];
      allEvents.sort(compareDistances);

      // Set the combined events to the "allData" state
      setAllData(allEvents);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
      setIsApiCallInProgress(false);
    }
  };

  return (
    <div className="container py-5 ">
      <section className={styles.eventHeader}>
        <Heading
          label={t("events")}
          style={{ fontWeight: "bold" }}
          img={eventsImg}
        />
        <Link to="/events">
          <button>See All</button>
        </Link>
      </section>
      <section className={isLoading && "d-flex justify-content-center"}>
        {isLoading ? (
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        ) : (
          <CardsSlider data={allData} name="events" />
        )}
      </section>
    </div>
  );
};

export default Event;
