export const GET_EVENT_IS_LOADING = "GET_EVENT_IS_LOADING";
export const GET_EVENTS_SUCCES = "GET_EVENTS_SUCCES";
export const GET_EVENTS_FIALURE = "GET_EVENTS_FIALURE";
export const DELETE_EVENT_REQUEST = "DELETE_EVENT_REQUEST";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILURE = "DELETE_EVENT_FAILURE";
export const EDIT_EVENT_REQUEST = "EDIT_EVENT_REQUEST";
export const EDIT_EVENT_SUCCESS = "EDIT_EVENT_SUCCESS";
export const EDIT_EVENT_FAILURE = "EDIT_EVENT_FAILURE";

