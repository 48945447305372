import { GET_CATEGORIES_FAIL, GET_CATEGORIES_LOADING, GET_CATEGORIES_SUCCESS } from "../Constants/categoryContants";
  
  const initialState = {
    category: [],
    isLoading: false,
    error: null,
  };
  
  export const getCategoriesReducers = (state = initialState, action) => {
    switch (action.type) {
      case GET_CATEGORIES_LOADING:
        return { ...state, isLoading: true };
      case GET_CATEGORIES_SUCCESS:
        return {
            isLoading : false,
            category: action.payload,
        };
      case GET_CATEGORIES_FAIL:
        return {
          ...state,
          isLoading : false,
          error: action.payload,
        };
     default:
        return state;
    }
  };
  