export const navAction = (active) => {
  return (dispatch) => {
    dispatch({
      type: "NAV_ACTION",
      payload: active,
    });
  };
};

export const navLangAction = (lang) => {
  return (dispatch) => {
    dispatch({
      type: "NAV_LANGUAGE_CHANGE",
      payload: lang,
    });
  };
};
