import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import CustomHeader from "../../Components/CustomHeader";
import InnerContent from "./InnerContent";
import { useTranslation } from "react-i18next";
import useWindowDimensions from "../../logics/useWindowDimension";
import CustomModal from "../../Components/Modal";

const NewsDetails = () => {
  const [title, setTitle] = useState("");

  const [show, setShow] = useState(false);
  const { name } = useParams();
  const { width } = useWindowDimensions();

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!name && width < 550) {
      setShow(true);
    }
  }, [width]);
  const { t } = useTranslation();

  return (
    <div>
      {title == "404" ? (
        <CustomHeader />
      ) : (
        <CustomHeader label1={t("news")} label2={title} />
      )}
      <div className="container py-5 f">
        {title == "404" ? (
          <h1 className="fw-bold text-center">404 Not Found</h1>
        ) : (
          <>
            <CustomModal show={show} handleClose={handleClose} />

            <InnerContent setTitle={setTitle} />
          </>
        )}
        {/* <section className={styles.dropDownAndAudio}>
        <div style={{ width: 250 }}>
          <Audio />
        </div>
        <div className="ms-3">
          <FlagDropDown />
        </div>
      </section> */}
      </div>
    </div>
  );
};

export default NewsDetails;
