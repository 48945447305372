import React from "react";
import styles from "./style.module.css";
import locationMarker from "../../Assets/locationBlackIcon.png";
import location from "../../Assets/locationBlackIcon.png";
import bannerImg from "../../Assets/ImagePlaceholder.png";
import { useNavigate } from "react-router-dom";
const PremiumPointsOfInterstCard = ({ value, onclick }) => {
  const navigate = useNavigate();
  const details = () => {
    navigate(`/premium-points/${value?.name}/${value?.id}`, { state: value });
    // const title = value?.title?.split(" ");
    // const formattedTitle = title?.join("");
    // navigate(
    //   `/premium-points-of-interest-details/${formattedTitle}/${value?.id}`,
    //   { state: value }
    // );
  };
  return (
    <div className={styles.cardBox}>
      <section className={styles.cardImageBox} onClick={details}>
        {/* <img src={value.eventImg} alt="card1" width="100%" /> */}
        {value?.image ? (
          <img
            src={`https://veyveback.com/${value?.image}`}
            alt={"logo"}
            width="100%"
            height={"200"}
          />
        ) : (
          <img src={bannerImg} alt={"logo"} width="100%" height={"200"} />
        )}
        <div className={styles.imgBoxText}>
          {/* <section className={`d-flex ${styles.imgChild1}`}>
            <img
              src={locationMarker}
              alt="locationMarker"
              width="20px"
              height="18px"
            />
            <span>7 Places</span>
          </section> */}
          {/* <section className={`d-flex ${styles.imgChild2}`}>
            <img src={location} width="25px" height="25px" alt="location" />
            <span>20 KM Away</span>
          </section> */}
        </div>
      </section>
      <section className={styles.cardContentParent}>
        {/* <h6>{value.eventName}   </h6> */}
        <h6>{value?.name} </h6>
        <section className={styles.cardContent}>
          <div className={styles.child1}>
            {/* <p>{value.eventDescription}</p> */}

            {value?.points[0]?.distance && (
              <div className={styles.locationBOx}>
                <section className={`d-flex ${styles.imgChild1}`}>
                  <img
                    src={locationMarker}
                    alt="locationMarker"
                    width="30px"
                    height="20px"
                  />
                </section>
                <section className={`d-flex ${styles.imgChild2}`}>
                  <span>{value?.points[0]?.distance} KM </span>
                </section>
              </div>
            )}
          </div>
        </section>
      </section>
    </div>
  );
};

export default PremiumPointsOfInterstCard;
