export const GET_POI_REQUEST = 'GET_POI_REQUEST';
export const GET_POI_SUCCESS = 'GET_POI_SUCCESS';
export const GET_POI_FAILURE = 'GET_POI_FAILURE';

export const CREATE_POI_REQUEST = 'CREATE_POI_REQUEST';
export const CREATE_POI_SUCCESS = 'CREATE_POI_SUCCESS';
export const CREATE_POI_FAILURE = 'CREATE_POI_FAILURE';

export const DELETE_POI_REQUEST = 'DELETE_POI_REQUEST';
export const DELETE_POI_SUCCESS = 'DELETE_POI_SUCCESS';
export const DELETE_POI_FAILURE = 'DELETE_POI_FAILURE';

export const EDIT_POI_REQUEST = 'EDIT_POI_REQUEST';
export const EDIT_POI_SUCCESS = 'EDIT_POI_SUCCESS';
export const EDIT_POI_FAILURE = 'EDIT_POI_FAILURE';


export const CURRENT_INDEX = "CURRENT_INDEX" 