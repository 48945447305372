import React, { useEffect, useState } from "react";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import Audio from "../../../Components/Audio";
import FLagDropDown from "../../../Components/FlagDropDown";
import location from "../../../Assets/locationBlackIcon.png";
import rightArrow from "../../../Assets/rightArrow.png";
import star2 from "../../../Assets/starIcon2.png";
import download from "../../../Assets/downloadIcon.png";
import share from "../../../Assets/shareIcon.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import API from "../../../Config/API";
import DOMPurify from "dompurify";
import { TbPhone } from "react-icons/tb";
import { BsEnvelope, BsGlobe } from "react-icons/bs";
import Button from "../../../Components/Button";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Store/Actions/toastActions";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

const RightSideContent = ({ lang, setLang, serviceData, setserviceData }) => {
  const [currentUser, setCurrentUser] = useState("");
  const [isFollowed, setIsFollowed] = useState(false);
  const { state } = useLocation();
  const { id } = useParams();
  const [isPlaying, setIsplaying] = useState(false);
  const [progress, setProgress] = useState();
  const dispatch = useDispatch();
  const audioElem = useRef();
  const { t } = useTranslation();
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);
  const navigate = useNavigate();
  // Getting Audio Filename

  // const parts = url.split("/");
  // const audioFilename = parts[parts.length - 1];
  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    if (serviceData?.audioFile) {
      if (isPlaying) {
        audioElem.current.play();
      } else {
        audioElem.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    handleChangeDrop();
  }, [lang]);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 3000);
  }, [currentUser]);

  const handleChangeDrop = async (e) => {
    // console.log(e.target.value);
    // console.log("--->", serviceData?.id);
    await API.post("/get-service-lang", {
      language: lang,
      serviceId: id,
      latitude: String(parsedLocation?.latitude) || "",
      longitude: String(parsedLocation?.longitude) || "",
    })
      .then((res) => {
        setserviceData(res?.data?.data);
        // console.log(res);
      })
      .catch((err) => console.log("err", err));
  };

  // console.log("serviceData", serviceData);
  const onPlaying = () => {
    const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;
    setProgress({ progresss: (ct / duration) * 100, length: duration });
  };

  // Follow Service
  const followService = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "service",
          userId: currentUser?.id,
          valueId: serviceData?.id,
        });
        dispatch(showToast(res?.data?.message, "success"));
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "services",
          userId: currentUser?.id,
          valueId: serviceData?.id,
        });
        // console.log("check service follow",res.data?.data);
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // Copy link to Clipboard
  const handleShareClick = () => {
    const currentURL = window.location.href;

    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        dispatch(showToast("URL Copied", "success"));
      })
      .catch((error) => {
        console.log("Failed to copy URL to clipboard:", error);
      });
  };

  const sanitizedDescription = DOMPurify.sanitize(serviceData?.description);

  return (
    <div>
      <section className="mb-3">
        <div className={styles.heading}>
          <Heading label={serviceData?.title} />
        </div>
        <p className={styles.para}>
          {serviceData?.startTime} - {serviceData?.endTime}
        </p>
      </section>
      <section className={`d-flex ${styles.dropDOwnAudioBox} align-items-end`}>
        {/* <div className={styles.audioBox}>
        </div> */}
        <div className={``}>
          <>
            <audio
              src={`https://veyveback.com/${serviceData?.audioFile}`}
              ref={audioElem}
              onTimeUpdate={onPlaying}
            />
            <Audio
              isPlaying={isPlaying}
              setIsplaying={setIsplaying}
              audioElem={audioElem}
              progress={progress}
              setProgress={setProgress}
              audioFile={serviceData?.audioFile}
            />
          </>
        </div>
        {serviceData?.audioFile && (
          <div className={`ms-3 ${styles.dropDownBox}`}>
            <FLagDropDown setName={setLang} />
          </div>
        )}
      </section>
      {serviceData?.distance && (
        <section className={`mt-3 ${styles.locationBox}`}>
          <div className={styles.locationContent}>
            <img width="25" height="25" src={location} alt="icon" />
            <span className="ms-2">{serviceData?.distance} KM Away</span>
          </div>
          <div>
            <img width="40" className="ms-3" src={rightArrow} alt="icon" />
          </div>
        </section>
      )}
      <section className="mt-3">
        <h6
          className="m-0 mb-1"
          style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}
        >
          {t("detailScreen.aboutThisService")}
        </h6>
        {/* <p
          className={styles.para}
          style={{
            fontSize: "13px",
          }}
        >
          {
            serviceData?.description
              ? serviceData?.description?.replace(/<\/?[^>]+(>|$)/g, "")
              : "No Description"
          }
        </p> */}
        {serviceData?.description ? (
          <div
            style={{ letterSpacing: "1px", wordSpacing: "2px" }}
            className="text-muted mb-3"
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          ></div>
        ) : (
          "No Description"
        )}
      </section>
      {/* <section className="my-4">
        <div>
          {serviceData?.publicationDate && (
            <h6
              className="m-0 mb-2"
              style={{ fontWeight: 900, color: "#000", fontSize: "15px" }}
            >
              Publication Date:{" "}
              <p className="text-muted">{serviceData?.publicationDate}</p>
            </h6>
          )}
          {serviceData?.publicationDate && (
            <h6
              className="m-0 mb-2"
              style={{ fontWeight: 900, color: "#000", fontSize: "15px" }}
            >
              Publication Time:{" "}
              <p className="text-muted">{serviceData?.publicationTime}</p>
            </h6>
          )}
          {serviceData?.publicationDate && (
            <h6
              className="m-0 mb-2"
              style={{ fontWeight: 900, color: "#000", fontSize: "15px" }}
            >
              Publication Status:{" "}
              <p className="text-muted">{serviceData?.publicationStatus}</p>
            </h6>
          )}
        </div>
      </section> */}
      <section>
        {serviceData?.price !== null && serviceData?.price != 0 && (
          <h6
            className="m-0 my-3"
            style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}
          >
            {t("detailScreen.ticketPrice")}{" "}
            <span className={`${styles.ticket}`}>{serviceData?.price}</span>
          </h6>
        )}
        <div className={styles.flagBox}>
          <section
            className={styles.cirCleImgBox}
            onClick={() =>
              navigate(
                `/muncipality/${serviceData?.muncipality?.title}/${serviceData?.muncipality?.id}`
              )
            }
          >
            <img
              src={
                serviceData?.signatureImage
                  ? `https://veyveback.com/${serviceData?.signatureImage}`
                  : serviceData?.muncipality?.signatureImage
              }
              width="100%"
              alt="signature"
            />
          </section>
          <section className="ms-3">
            <h6 className="m-0 mb-2" style={{ fontWeight: 600, color: "#000" }}>
              {t("detailScreen.organizerName")}
            </h6>
            <p className="text-capitalize">{serviceData?.muncipality?.title}</p>
            <div className={`${styles.organizerIconsBox}`}>
              {serviceData?.phone && (
                <Link className="mx-2" to={`tel:${serviceData?.phone}`}>
                  <TbPhone color="#000" size={27} />
                </Link>
              )}
              {serviceData?.websiteLink && (
                <Link
                  className="mx-2"
                  to={`${serviceData?.websiteLink}`}
                  target="_blank"
                >
                  <BsGlobe color="#000" size={27} />
                </Link>
              )}
              {serviceData?.email && (
                <Link className="mx-2" to={`mailto:${serviceData?.email}`}>
                  <BsEnvelope color="#000" size={27} />
                </Link>
              )}
            </div>
          </section>
        </div>
      </section>
      <section className={styles.actionsBox}>
        {/* <section className={styles.btnBox}>
          <Button label={"Buy Ticket"} width={"100%"} />
        </section> */}
        <section className={`${styles.iconBox}`}>
          {currentUser && (
            <>
              {/* <div className="me-2">
                <img src={clock} width="20px" alt="icon" />
              </div> */}
              <div className="mx-3" onClick={followService}>
                {isFollowed ? (
                  <AiFillStar size={20} color="#34C89A" />
                ) : (
                  <AiOutlineStar size={20} color="#34C89A" />
                )}
              </div>
              {/* <div className="mx-2">
                <img src={download} width="20px" alt="icon" />
              </div> */}
              <div className="ms-2" onClick={handleShareClick}>
                <img src={share} width="20px" alt="icon" />
              </div>
            </>
          )}
        </section>
      </section>
    </div>
  );
};

export default RightSideContent;
