import API from "../../Config/API";
import {
  CREATE_POI_FAILURE,
  CREATE_POI_REQUEST,
  CREATE_POI_SUCCESS,
  DELETE_POI_FAILURE,
  DELETE_POI_REQUEST,
  DELETE_POI_SUCCESS,
  EDIT_POI_FAILURE,
  EDIT_POI_REQUEST,
  EDIT_POI_SUCCESS,
  GET_POI_FAILURE,
  GET_POI_REQUEST,
  GET_POI_SUCCESS,
} from "../Constants/pointOfInterestConts";
import { showToast } from "./toastActions";

// Get Point Of Interest

export const getPoi = (userId) => {
  return async (dispatch) => {
    dispatch({ type: GET_POI_REQUEST });
    try {
      const res = await API.post("/get-points", {
        userId: 2,
        distance: "1000",
      });

      dispatch({
        type: GET_POI_SUCCESS,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: GET_POI_FAILURE,
        payload: error,
      });
    }
  };
};

// Create Point of Interest

export const createPoi = (poiData, navigate) => {
  return async (dispatch) => {
    dispatch({ type: CREATE_POI_REQUEST });

    try {
      const res = await API.post("/store-point", poiData);
      if (res.data.status === 200 || res.data.status === 201) {
        dispatch({
          type: CREATE_POI_SUCCESS,
          payload: res.data,
        });
        dispatch(showToast("Point Of Interested Created Successfully", "info"));
        setTimeout(() => {
          navigate(-1);
        }, 5000);
      } else {
        const error = res.data;
        throw error;
      }

    } catch (error) {
      dispatch({
        type: CREATE_POI_FAILURE,
        payload: error,
      });
      dispatch(showToast(error.message, "error"));
    }
  };
};

// Delete Point Of Interest

export const deletePoi = (pointId) => {
  return async (dispatch) => {
    dispatch({ type: DELETE_POI_REQUEST });

    try {
      const response = await API.post("/delete-point", { pointId: pointId });
      dispatch({
        type: DELETE_POI_SUCCESS,
        payload: response.data,
      });
      dispatch(showToast("Points Of Interest Deleted", "info"));
    } catch (error) {
      dispatch({
        type: DELETE_POI_FAILURE,
        payload: error,
      });
      dispatch(showToast(error.message, "error"));
    }
  };
};

// Edit Poi

export const editPoi = (data) => {
  return async (dispatch) => {
    dispatch({
      type: EDIT_POI_REQUEST,
    });
    try {
      const response = await API.post("/complete-point", data);
      dispatch({
        type: EDIT_POI_SUCCESS,
        payload: response.data,
      });
      dispatch(showToast("Point Of Interest Edited Successfully", "info"));
    } catch (error) {
      dispatch({
        type: EDIT_POI_FAILURE,
        payload: error,
      });
      dispatch(showToast(error.message, "error"));
    }
  };
};
