const INITIAL_STATE = {
  activeProfile: "",
};

const profileReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "PROFILE_ACTION":
      return {
        ...state,
        activeProfile: action.payload,
      };
    default:
      return state;
  }
};

export { profileReducer };
