import React from "react";
import Slider from "../../../Components/Slider/index";

const Header = () => {
  return (
    <div>
      <Slider />
    </div>
  );
};

export default Header;
