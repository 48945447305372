import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../../Components/Button";
import Heading from "../../../Components/Heading";
import Input from "../../../Components/Input";
import styles from "./style.module.css";
import { GoMail } from "react-icons/go";
import { AiOutlineLock } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../../Store/Actions/authAction";
import { showToast } from "../../../Store/Actions/toastActions";
import { Spinner } from "react-bootstrap";
import API from "../../../Config/API";
import { useTranslation } from "react-i18next";

const Form = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // useEffect(() => {
  //   if (localStorage.getItem("UserDetails")) {
  //     navigate("/");
  //   }
  // }, []);
  const authStates = useSelector((state) => state?.authReducer);
  const login = async (e) => {
    setLoading(true);
    const credentials = {
      email,
      password,
    };
    e.preventDefault();
    if (email !== "" || password !== "") {
      try {
        const data = await API.post(`/login`, credentials);
        let logDetail = data?.data?.data;
        if (data.data.message === "Logged In successfully") {
          localStorage.setItem("UserDetails", JSON.stringify(logDetail));
          localStorage.setItem("currentUser", JSON.stringify(logDetail));
          setLoading(false);
          dispatch(showToast(data.data.message, "success"));
          navigate("/follow-municipality");
        } else {
          dispatch(showToast(data.data.message, "error"));
          setLoading(false);
        }
      } catch (error) {
        dispatch(showToast("Internal Server Error ..!", "error"));
        setLoading(false);
      }
    } else {
      dispatch(showToast("Require Field are Missing", "error"));
      setLoading(false);
    }
  };
  return (
    <form className={styles.formBox} onSubmit={login}>
      <section className={` ${styles.heading}`}>
        <Heading label={t("Login")} color="#35C89B" />
        <Link to={"/register"} className={`${styles.Link}`}>
          {t("Dont-have-an-account")}
        </Link>
      </section>
      <section className={styles.inpBox}>
        <GoMail fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
        <Input
          placeholder={t("Email-Address")}
          onchange={setEmail}
          type="text"
        />
      </section>
      <section className={styles.inpBox}>
        <AiOutlineLock fontSize={"1.6rem"} style={{ marginRight: "10px" }} />
        <Input
          placeholder={t("Password")}
          type="password"
          onchange={setPassword}
        />
      </section>
      <section className="d-flex justify-content-center align-items-center">
        {loading ? (
          <Spinner style={{ color: "#34c89a" }} />
        ) : (
          <Button label={t("Login")} width="100%" />
        )}
      </section>
    </form>
  );
};

export default Form;
