import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import LocationBox from "./LocationBox";
import SetOfRoutes from "./SetOfRoutes";
import API from "../../Config/API";

function SetRoutes() {
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);


  return (
    <div className={`container ${styles.parent}`}>
      <section className={styles.child1}>
        <LocationBox />
      </section>
      <section className={styles.child2}>
        <SetOfRoutes  />
      </section>
    </div>
  );
}

export default SetRoutes;
