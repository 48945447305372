import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomButton from "../../Components/Button";
import Heading from "../../Components/Heading";
import { navAction } from "../../Store/Actions/navAction";
import AddedServices from "./AddedEvents";
import GeneralDataForm from "./GeneralDataForm";
import LocationData from "./LocationData";
import MultimediaData from "./MultimediaData";
import styles from "./style.module.css";
import { showToast } from "../../Store/Actions/toastActions";
import { Spinner } from "react-bootstrap";
import { BiPlusCircle } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AudioData from "./AudioData/AudioData";
import VideoData from "./VideoData";
import Gallery from "../../Components/Gallery";
import AddSecondaryImages from "./AddSecondaryImages";
import CustomHeader from "../../Components/CustomHeader";
import { Action_handleCreateService } from "../../Store/Actions/servicesAction";
import { useTranslation } from "react-i18next";

const AddService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let getmuncipalityId = localStorage.getItem("muncipalityId");

  // Getting User
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
    setGeneralData({ ...generalData, userId: parsedUser?.id });
  }, []);

  const [generalData, setGeneralData] = useState({
    title: "",
    address: "",
    startTime: "",
    endTime: "",
    price: "",
    telephone: "",
    email: "",
    websiteLink: "",
    muncipalityId: getmuncipalityId,
    description: "",
    latitude: "",
    longitude: "",
    imagesArray: [],
    audioFile: "",
    signatureImage: "",
    videos: [],
    secondaryimages: [],
    types: [],
    status: "Active",
    type: "Public",
    categoryId: "",
    userId: "",
  });

  const [originalImage, setOriginalImage] = useState("");
  const [listingImage, setListingImage] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [originalVideo, setOriginalVideo] = useState("");
  const [listingVideo, setListingVideo] = useState("");
  const [bannerVideo, setBannerVideo] = useState("");
  const [multyImagesText, setMultyImagesText] = useState([]);
  const [multyVideos, setMultyVideos] = useState([]);
  const [logoImage, setLogoImage] = useState("");
  const [audio, setAudio] = useState("");
  const [addServices, setAddedServices] = useState([]);
  const [userId, setUserID] = useState("");
  const [showGallery, setShowGallery] = useState(false);
  const [galleryImage, setGalleryImage] = useState("");
  const [showMuliMediaModal, setShowMuliMediaModal] = useState(false);
  const [addServiceModal, setAddServiceModal] = useState(false);
  const [showSecondaryModal, setShowSecondaryModal] = useState(false);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const { t } = useTranslation();

  const handleGetImage = (path) => {
    setGalleryImage(path);
    setShowGallery(false);
    if (showMuliMediaModal) {
      setShowMuliMediaModal(true);
    }
    if (addServiceModal) {
      setAddServiceModal(true);
    }
    if (showSecondaryModal) {
      setShowSecondaryModal(true);
    }
  };

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setUserID(parsedUser?.id);
  }, []);

  useEffect(() => {
    dispatch(navAction("Points Of interest"));
    // dispatch(handleGetCategory_Action("point", currentUser?.id));
  }, []);

  const handleCreateService = async () => {
    if (
      !generalData.lng ||
      !generalData.lat ||
      !generalData.title ||
      !generalData.description ||
      !getmuncipalityId ||
      !generalData.price ||
      !generalData.imagesArray.length == 0 ||
      !generalData.videos.length == 0
    ) {
      dispatch(showToast("Required Fileds are Missing", "error"));
    } else {
      await dispatch(
        Action_handleCreateService({ ...generalData }, navigate, setisLoading)
      );
    }
  };

  return (
    <>
      {showGallery ? (
        <div className={`${styles.formBox}`}>
          <Gallery handleGetImage={handleGetImage} />
        </div>
      ) : (
        <>
          <section className="pb-3">
            <CustomHeader label1={t("services")} label2={t("Add-Service")} />
            {/* <CustomButton
              onClick={handleNavigate}
              label={"Add Premium Point of Interest"}
              icon={<BiPlusCircle />}
            /> */}
          </section>
          <div className="container">
            <div className={styles.formBox}>
              <GeneralDataForm
                generalData={generalData}
                setGeneralData={setGeneralData}
              />
            </div>
            {/* <div className={`my-5 ${styles.formBox}`}>
            <MultimediaData
              setShowGallery={setShowGallery}
              galleryImage={galleryImage}
              showGallery={showGallery}
              setGalleryImage={setGalleryImage}
              originalImage={originalImage}
              setOriginalImage={setOriginalImage}
              listingImage={listingImage}
              setListingImage={setListingImage}
              bannerImage={bannerImage}
              setBannerImage={setBannerImage}
              logoImage={logoImage}
              setLogoImage={setLogoImage}
              MultyImagesText={multyImagesText}
              setMultyImagesText={setMultyImagesText}
              showModal={showMuliMediaModal}
              setShowModal={setShowMuliMediaModal}
            />
          </div> */}
            {/* <div className={`my-5 ${styles.formBox}`}>
            <AudioData setAudio={setAudio} audio={audio} />
          </div> */}
            <div className={`my-5 ${styles.formBox}`}>
              <VideoData
                setMultyVideos={setMultyVideos}
                multyVideos={multyVideos}
                originalVideo={originalVideo}
                setOriginalVideo={setOriginalVideo}
                listingVideo={listingVideo}
                setListingVideo={setListingVideo}
                bannerVideo={bannerVideo}
                setBannerVideo={setBannerVideo}
              />
            </div>
            <div className={styles.formBox}>
              <LocationData
                generalData={generalData}
                setGeneralData={setGeneralData}
              />
            </div>
            <div className={`my-5 ${styles.formBox}`}>
              <AddedServices
                setAddServices={setAddedServices}
                addServices={addServices}
                setShowGallery={setShowGallery}
                showModal={addServiceModal}
                setShowModal={setAddServiceModal}
                galleryImage={galleryImage}
                setGalleryImage={setGalleryImage}
              />
            </div>
            {/* <div className={`my-5 ${styles.formBox}`}>
            <AddSecondaryImages
              showModal={showSecondaryModal}
              setShowModal={setShowSecondaryModal}
              setShowGallery={setShowGallery}
              galleryImage={galleryImage}
              setGalleryImage={setGalleryImage}
              secondaryImages={secondaryImages}
              setSecondaryImages={setSecondaryImages}
            />
          </div> */}
            <div className="d-flex justify-content-center align-items-center mb-2">
              {isLoading ? (
                <Spinner style={{ color: "#35C89B" }} />
              ) : (
                <CustomButton
                  onClick={handleCreateService}
                  label={t("Upload Service")}
                  width="50%"
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddService;
