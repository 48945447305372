import { Modal, Spinner } from "react-bootstrap";
import React, { useState } from "react";
import { RiCameraLensFill } from "react-icons/ri";
import styles from "../style.module.css";
import CustomButton from "../../../../Components/Button";
import API from "../../../../Config/API";
import { useDispatch } from "react-redux";
import { BsImage } from "react-icons/bs";
import { createRef } from "react";
import { Cropper } from "react-cropper";

export function ModalCompForLogo({
  show,
  handleClose,
  setLogoImage,
  setShowGallery,
  galleryImage,
  setGalleryImage,
}) {
  const cropperRef = createRef();
  const [src, setSrc] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    setFile(file);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSrc(reader.result);
    };
  };

  const handleClick = async () => {
    if (galleryImage) {
      setLogoImage(galleryImage);
      handleClose();
      setGalleryImage("");
      return;
    }
    setIsLoading(true);
    const data = {
      previewImg: src,
      file: file,
    };
    const randomNumber = Math.floor(Math.random() * 100);
    if (typeof cropperRef.current?.cropper !== "undefined") {
      await fetch(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
        .then((res) => res.blob())
        .then(async (blob) => {
          const fd = new FormData();
          const fileC = new File([blob], file?.name + randomNumber);
          fd.append("file", fileC);
          const res = await API.post("/upload-file", fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          if (res.data.status === true) {
            setLogoImage(res.data.data);
            handleClose();
          }
        });
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          <section className={styles.modalInpFileBox1}>
            {src || galleryImage ? (
              <Cropper
                ref={cropperRef}
                zoomTo={0.5}
                initialAspectRatio={1}
                src={src ? src : `https://veyveback.com/${galleryImage}`}
                viewMode={2}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
              />
            ) : (
              <>
                <label>
                  <input
                    type={"file"}
                    width={170}
                    height={170}
                    onChange={handleFileChange}
                  />
                  <RiCameraLensFill className={styles.cropperDownloadIcon} />
                </label>
              </>
            )}
            <p
              className="text-center p-3"
              style={{
                color: "gray",
              }}
            >
              {file?.name || "Your Logo"}
            </p>
          </section>
          <CustomButton
            onClick={() => setShowGallery(true)}
            label={" Media Library"}
            icon={<BsImage />}
            width="100%"
          />
          <section className="mt-1 d-flex justify-content-center">
            {isLoading ? (
              <Spinner style={{ color: "#35C89B" }} />
            ) : (
              <CustomButton
                onClick={handleClick}
                label="Upload Image"
                width="100%"
              />
            )}
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

const LogoUpload = ({
  logoImage,
  setLogoImage,
  setShowGallery,
  galleryImage,
  setGalleryImage,
  showLogoModal,
  setShowLogoModal,
}) => {
  const handleModalClose = () => {
    setShowLogoModal(false);
  };

  const handleDownloadClick = () => {
    setShowLogoModal(true);
  };

  return (
    <>
      <section className={styles.inpFileBox1}>
        {logoImage ? (
          <label>
            <img
              src={`https://veyveback.com/${logoImage}`}
              className={styles.previewImg}
              alt="Logo"
            />
          </label>
        ) : (
          <label onClick={handleDownloadClick}>
            <RiCameraLensFill className={styles.downloadIcon} />
          </label>
        )}
        <p
          className="text-center p-3"
          style={{
            color: "gray",
          }}
        >
          Your Logo
        </p>
      </section>

      <ModalCompForLogo
        show={showLogoModal}
        setLogoImage={setLogoImage}
        handleClose={handleModalClose}
        setShowGallery={setShowGallery}
        galleryImage={galleryImage}
        setGalleryImage={setGalleryImage}
      />
    </>
  );
};

export default LogoUpload;
