import React from "react";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import CustomButton from "../../../Components/Button";
import { FiVideo } from "react-icons/fi";
import styles from "./style.module.css";
import "react-image-crop/dist/ReactCrop.css";

function ModalCompForCropping({
  show,
  handleClose,
  title,
  handleUploadClick,
  loading,
}) {
  const [src, setSrc] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e?.target?.files[0];
    setFile(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setSrc(reader.result);
    };
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="d-flex justify-content-center align-items-center"
      >
        <Modal.Body
          style={{
            minWidth: "600px",
            background: "#fff",
            borderRadius: 5,
          }}
        >
          <section className={styles.modalInpFileBox2}>
            {src ? (
              <>
                <label className="d-flex justify-content-center align-items-center text-center">
                  <p style={{ fontSize: "1.1rem" }} className="text-muted">
                    {file?.name}
                  </p>
                </label>
              </>
            ) : (
              <>
                <label>
                  <input onChange={handleFileChange} type="file" />
                  <FiVideo className={styles.cropperDownloadIcon} />
                </label>
              </>
            )}
            <p
              className="text-center p-3"
              style={{
                color: "gray",
              }}
            >
              {title}
            </p>
          </section>
          <section className="mt-1 d-flex justify-content-center align-items-center">
            {loading ? (
              <Spinner style={{ color: "#35C89B" }} />
            ) : (
              <CustomButton
                label="Upload Video"
                width="100%"
                onClick={() => handleUploadClick(file, setSrc, setFile)}
              />
            )}
          </section>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ModalCompForCropping;

// Crop Preview

// function CroppedImagePreview({ src, crop }) {
//     const canvasRef = useRef(null);

//     useEffect(() => {
//         const canvas = canvasRef.current;
//         const ctx = canvas.getContext('2d');

//         const image = new Image();
//         image.src = src;
//         image.onload = () => {
//             const scaleX = image.naturalWidth / crop.width;
//             const scaleY = image.naturalHeight / crop.height;
//             const x = crop.x * scaleX;
//             const y = crop.y * scaleY;
//             const width = crop.width * scaleX;
//             const height = crop.height * scaleY;

//             // console.log("scaleX", scaleX)
//             // console.log("scaleY", scaleY)
//             // console.log(crop)

//             ctx.clearRect(0, 0, canvas.width, canvas.height);
//             ctx.drawImage(
//                 image,
//                 crop.x * scaleX,
//                 crop.y * scaleY,
//                 crop.width * scaleX,
//                 crop.height * scaleY,
//                 0,
//                 0,
//                 crop.width,
//                 crop.height
//             );
//             console.log(crop.width)
//         };
//     }
//         , [src, crop]);

//     return (
//         <canvas
//             ref={canvasRef}
//             width={crop.width}
//             height={crop.height}
//             style={{ maxWidth: '100%' }}
//         />
//     );
// }
