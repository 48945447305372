import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { navReducer } from "./navReducer";
import { profileReducer } from "./profileReducer";
import { toastReducer } from "./toastReducer";
import { PoiReducer } from "./PoiReducer";
import { getCategoriesReducers } from "./categoryReducer";
import { getEventsReducers } from "./EventsReducer";
import { getUserReducer } from "./getUserReducer";

const combineReducer = combineReducers({
  navReducer: navReducer,
  authReducer: authReducer,
  profileReducer: profileReducer,
  toastReducer: toastReducer,
  PoiReducer: PoiReducer,
  getCategoriesReducers: getCategoriesReducers,
  getEventsReducers: getEventsReducers,
  getUserReducer: getUserReducer,
});

export default combineReducer;
