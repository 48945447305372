import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { navAction } from "../../Store/Actions/navAction";

import Event from "./Events";
import Header from "./Header";
import HeaderBox from "./HeaderBox";
import Map from "./Map";
import News from "./News";
import PointsOfInterest from "./PointsOfInterest";
import Routes from "./Routes";
import Services from "./Services";
import EventCard from "../Events/EventCard";
import PointsOfInterestCard from "../PointsOfInterest/PointsOfInterestCard";
import NewsCard from "../News/NewsCard";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next";
import eventsImg from "../../Assets/eventsImg.png";
import pointOfInterest from "../../Assets/pointOfInterest.png";
import serviceIcon from "../../Assets/servicesIcon.png";
import newsIcon from "../../Assets/newsIcon.png";
import ServicesCard from "../Services/ServicesCard";
import { Spinner } from "react-bootstrap";
import getUserDetail from "../../Store/Actions/getUserDetail";
import LeafletMap from "../../Components/GoogleMap/LeafletMap";

const Home = ({ cordinates }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [searchType, setSearchType] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    dispatch(navAction("home"));

    // Getting user's current location

    dispatch(getUserDetail());
  }, []);

  useEffect(() => {
    let currentLocation = { latitude, longitude };
    localStorage.setItem("currentLocation", JSON.stringify(currentLocation));
  }, [latitude, longitude]);

  // Render Default Content
  const renderDefaultContent = () => {
    return (
      <>
        <Event cordinates={cordinates} />
        <PointsOfInterest cordinates={cordinates} />
        <Services cordinates={cordinates} />
        <div className="container">
          <LeafletMap cordinates={cordinates} />
        </div>
        <Routes cordinates={cordinates} />
        <News cordinates={cordinates} />
      </>
    );
  };

  // Render Filtered Content
  const renderFilteredContent = () => {
    if (searchType === "events") {
      return (
        <section className="container">
          <Heading
            label={t("events")}
            style={{ fontWeight: "bold" }}
            img={eventsImg}
          />
          <EventCard allData={searchData} />
        </section>
      );
    }
    if (searchType === "points") {
      return (
        <section className="container">
          <Heading
            label={t("pointsOfInterest")}
            img={pointOfInterest}
            style={{ fontWeight: "bold" }}
            bottom="22px"
          />
          <PointsOfInterestCard allData={searchData} />
        </section>
      );
    }
    if (searchType === "services") {
      return (
        <section className="container">
          <Heading
            label={t("services")}
            img={serviceIcon}
            style={{ fontWeight: "bold" }}
            bottom="22px"
          />
          <ServicesCard allData={searchData} />
        </section>
      );
    }
    if (searchType === "news") {
      return (
        <section className="container">
          <Heading
            label={t("news")}
            img={newsIcon}
            style={{ fontWeight: "bold" }}
            bottom="20px"
            width="50px"
          />
          <NewsCard allData={searchData} />
        </section>
      );
    }
  };

  return (
    <>
      <Header />
      <HeaderBox
        setIsLoading={setIsloading}
        setSearchType={setSearchType}
        setSearchData={setSearchData}
        btnValue="Done"
        location="home"
        cordinates={cordinates}
      />
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner
            style={{ color: "#35C89B", width: "50px", height: "50px" }}
          />
        </div>
      ) : searchData && searchData?.length > 0 ? (
        renderFilteredContent()
      ) : (
        renderDefaultContent()
      )}
    </>
  );
};

export default Home;
