import React, { useState, useEffect } from "react";
import { bannerData, mobBannerData } from "../../logics/Data";
import styles from "./styles.module.css";
import "./style.css";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import useWindowDimensions from "../../logics/useWindowDimension";
const Slider = () => {
  const { width } = useWindowDimensions();

  const [people, setPeople] = useState([]);
  const [index, setIndex] = useState(0);
  useEffect(() => {
    setPeople(width <= 500 ? mobBannerData : bannerData);
  }, [width]);

  useEffect(() => {
    const lastIndex = people.length - 1;
    if (index < 0) {
      setIndex(lastIndex);
    }
    if (index > lastIndex) {
      setIndex(0);
    }
  }, [index, people]);

  useEffect(() => {
    let slider = setInterval(() => {
      setIndex(index + 1);
    }, 10000);
    return () => {
      clearInterval(slider);
    };
  }, [index]);

  return (
    <section className={styles.section}>
      <div className={styles.section_container}>
        {people.map((value, ind) => {
          let position = "nextSlide";
          if (ind === index) {
            position = "activeSlide";
          }
          if (
            ind === index - 1 ||
            (index === 0 && index === people.length - 1)
          ) {
            position = "lastSlide";
          }
          return (
            <article key={ind} className={position}>
              <div
                className={styles.imgBox}
                style={{
                  backgroundImage: `url(${value.img})`,
                }}
              ></div>
              {/* <img src={value.img} alt="img" width="100%"  /> */}
            </article>
          );
        })}
        <section className={styles.iconBox}>
          <button className={styles.prev} onClick={() => setIndex(index - 1)}>
            <IoIosArrowBack />
          </button>
          <button className={styles.next} onClick={() => setIndex(index + 1)}>
            <IoIosArrowForward />
          </button>
        </section>
      </div>
    </section>
  );
};

export default Slider;
