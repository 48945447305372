import React from "react";
import styles from "./style.module.css";

const OutlineButton = ({ label, width, onClick, Icon }) => {
  return (
    <button
      className={styles.btn}
      onClick={onClick}
      style={{
        width: width ? width : "",
      }}
    >
      {Icon ? Icon : ""}
      {label}
    </button>
  );
};

export default OutlineButton;
