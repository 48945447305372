import React from "react";
import Heading from "../../Components/Heading";
import CustomHeader from "../../Components/CustomHeader";
import  styles from "./style.module.css"

const PolíticaDeCookies = () => {
  return (
    <>
      <CustomHeader label1={"PolíticaDeCookies"} icon={false} />
      <section className="container my-2">
        {/* <Heading label={"PolíticaDeCookies"} /> */}
        <h5>¿Qué son las cookies?</h5>
        <p>
          Una Cookie es un fichero que se descarga en su ordenador al acceder a
          determinadas páginas web. Las cookies permiten a una página web, entre
          otras cosas, almacenar y recuperar información sobre los hábitos de
          navegación de un usuario o de su equipo y, dependiendo de la
          información que contenga y de la forma en que utilice su equipo,
          pueden utilizarse para reconocer al usuario.
        </p>
        <p>
          Las cookies son esenciales para el funcionamiento de internet,
          aportando innumerables ventajas en la prestación de servicios
          interactivos, facilitándole la navegación y usabilidad de nuestra web.
        </p>
        <h5>Tipos de cookies</h5>
        <p>
          La información que le proporcionamos a continuación, le ayudará a
          comprender los diferentes tipos de cookies:
        </p>
        <table border="1px" className=" my-3">
          <tbody className={styles.cookieTableBody}>
            <tr>
              <td rowSpan={2}>SEGÚN LA ENTIDAD QUE LAS GESTIONE</td>
              <td>Cookies propias</td>
              <td>
                Son aquellas que se recaban por el propio editor para prestar el
                servicio solicitado por el usuario.
              </td>
            </tr>
            <tr>
              <td>Cookies de tercero</td>
              <td>
                Son aquellas que son recabadas y gestionadas por un tercero,
                estas no se pueden considerar propias.
              </td>
            </tr>
            <tr>
              <td rowSpan={2}>SEGÚN EL PLAZO DE TIEMPO QUE PERMANEZCAN ACTIVADAS</td>
              <td>Cookies de sesión</td>
              <td>
                Recaban datos mientras el usuario navega por la red con la
                finalidad de prestar el servicio solicitado.
              </td>
            </tr>
            <tr>
              <td>Cookies persistentes</td>
              <td>
                Se almacenan en el terminal y la información obtenida, será
                utilizada por el responsable de la cookie con la finalidad de
                prestar el servicio solicitado.
              </td>
            </tr>
            <tr>
              <td rowSpan={4}>SEGÚN SU FINALIDAD</td>
              <td>Cookies técnicas</td>
              <td>
                Son las necesarias para la correcta navegación por la web.
              </td>
            </tr>
            <tr>
              <td>Cookies de personalización</td>
              <td>
                Permiten al usuario las características (idioma) para la
                navegación por la website
              </td>
            </tr>
            <tr>
              <td>Cookies de análisis</td>
              <td>
                Permiten al prestador el análisis vinculado a la navegación
                realizada por el usuario, con la finalidad de llevar un
                seguimiento de uso de la página web, así como realizar
                estadísticas de los contenidos más visitados, número de
                visitantes, etc.
              </td>
            </tr>
            <tr>
              <td>Cookies publicitarias</td>
              <td>
                Permiten al editor incluir en la web, espacios publicitarios,
                según el contenido de la propia web.
              </td>
            </tr>
          </tbody>
        </table>
        <table border={1} className="my-2">
          <tbody className={styles.cookieTableBody}>
            <tr>
              <td style={{ width: 240 }}></td>
              <td>Cookies de publicidad comportamental</td>
              <td>
                Permiten al editor incluir en la página web espacios
                publicitarios según la información obtenida a través de los
                hábitos de navegación del usuario.
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Según lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de
          julio, de Servicios de la Sociedad de la Información y de Comercio
          Electrónico (LSSI-CE), le informamos de las cookies utilizadas en
          nuestra website:
        </p>
        <p>
          Nombre de la cookie: _ga Descripción: cookie de Google Analytics cuya
          finalidad es identificar usuarios. La primera vez que un usuario entre
          en el sitio web a través de un navegador se instalará esta cookie.
          Cuando este usuario vuelva a entrar en la web con el mismo navegador,
          la cookie considerará que es el mismo usuario. Solo en el caso de que
          el usuario cambie de navegador, se considerará otro usuario. Servidor
          desde el que se envía: . veyve.net Propia o de terceros: terceros.
          Finalidad: analíticas. Fecha de caducidad: 24 meses desde que se creó
          inicialmente o se restableció No excluida del deber de la información
          y consentimiento.
        </p>
        <p>
          Nombre de la cookie: _gat Descripción: cookie de Google Analytics cuya
          finalidad es diferenciar entre los diferentes objetos de seguimiento
          creados en la sesión. La cookie se actualiza cada vez que envía datos
          a Google Analytics. Servidor desde el que se envía: . veyve.net Propia
          o de terceros: terceros Finalidad: analíticas. Fecha de caducidad: 10
          minutos. No excluida del deber de la información y consentimiento.
        </p>
        <p>
          Nombre de la cookie: _gid Descripción: cookie de Google Analytics cuya
          finalidad es distinguir a los usuarios. Servidor desde el que se
          envía: . veyve.net Propia o de terceros: terceros Finalidad:
          analíticas. Fecha de caducidad: 24 horas. No excluida del deber de la
          información y consentimiento.
        </p>
        <ul>
          <li>
            Google Analytics: Almacena cookies para poder elaborar estadísticas
            sobre el tráfico y volumen de visitas de esta web. Al utilizar este
            sitio web está consintiendo el tratamiento de información acerca de
            usted por Google. Por tanto, el ejercicio de cualquier derecho en
            este sentido deberá hacerlo comunicando directamente con Google.
          </li>
        </ul>
        <p>
          Las cookies de Google Analytics, se almacenan en servidores ubicados
          en Estados Unidos y se compromete a no compartirla con terceros,
          excepto en los casos en los que sea necesario para el funcionamiento
          del sistema o cuando la ley obligue a tal efecto. Según Google no
          guarda su dirección IP.
        </p>
        <p>
          Google Inc. es una compañía adherida a “Privacy Shiels” (escudo de
          privacidad) que garantiza que todos los datos transferidos serán
          tratados con un nivel de protección acorde a la normativa europea.
          Puede consultar información detallada a este respecto a través del
          siguiente enlace:{" "}
        </p>
        <p>
          <a href="https://support.google.com/analytics/answer/6004245">
            https://support.google.com/analytics/answer/6004245.
          </a>
        </p>
        <p>
          Si lo desea puede utilizar el Complemento de inhabilitación para
          navegadores de Google Analytics a través de cuyas instrucciones pueden
          rechazarse las cookies analíticas de dicho servicio en todos los
          navegadores. Puede consultar más información al respecto en el
          siguiente enlace:{" "}
        </p>
        <p>
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout.
          </a>
        </p>
        <h5>Desactivación y eliminación de cookies</h5>
        <p>
          Le informamos de que tiene la posibilidad de configurar su navegador
          de modo que se le informe de la recepción de cookies, pudiendo, si así
          lo desea, impedir que sean instaladas en su disco duro.
        </p>
        <p>
          A continuación le proporcionamos los enlaces de diversos navegadores,
          a través de los cuales podrá realizar dicha configuración:
        </p>
        <ul>
          <li>
            {" "}
            Firefox desde aquí:
            <a href=" http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los">
              {" "}
              http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los
            </a>{" "}
            <br />
            sitios-we
          </li>
          <li>
            Chrome desde aquí:
            <a
              href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
"
            >
              http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647
            </a>
          </li>
          <li>
            Explorer desde aquí:
            <a href=" http://windows.microsoft.com/es-es/internet-explorer/delete-manage- ">
              {" "}
              http://windows.microsoft.com/es-es/internet-explorer/delete-manage-{" "}
            </a>
            <br />- cookies#ie=ie-10
          </li>
          <li>
            Safari desde aquí:
            <a href="https://support.apple.com/kb/ph17191?locale=es_ES">
              https://support.apple.com/kb/ph17191?locale=es_ES
            </a>
          </li>
          <li>
            Opera desde aquí:
            <a href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">
              http://help.opera.com/Windows/11.50/es-ES/cookies.html
            </a>
          </li>
        </ul>
      </section>
    </>
  );
};

export default PolíticaDeCookies;
