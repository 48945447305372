import React from "react";
import EventsCard from "../../../../Components/EventsCard";
import { eventsCardData } from "../../../../logics/Data"
import styles from "./style.module.css"
import Heading from "../../../../Components/Heading";

const MyEvents = () => {
  return (
    <section className={styles.mainBox}>
      <section className={styles.headingBox}>
        <Heading label={"My Events"} style={{ fontWeight: "bold" }} />
      </section>
      <section className={styles.eventBox}>
        {
          eventsCardData?.map((value) => (
            <div>
              <EventsCard value={value} />
            </div>
          ))
        }
      </section>
    </section>
  )
};

export default MyEvents;
