import React from "react";
import user from "../../Assets/user.png";
import pencil from "../../Assets/pencil.png";
import mail2 from "../../Assets/mail2.png";
import styles from "./style.module.css";
import { IoPencilOutline } from "react-icons/io5";
import Button from "../../Components/Button";
import location from "../../Assets/locationContact.png";
import mail from "../../Assets/mail.png";
import phone from "../../Assets/phone.png";
import Heading from "../../Components/Heading";
import { useTranslation } from "react-i18next";

const ContactUs = () => {
  const { t } = useTranslation();
  return (
    <div className="container my-5">
      <section className={styles.headingBox}>
        <Heading
          label={t("footer.contactUs")}
          style={{
            textDecoration: "underline #34C89A",
          }}
        />
      </section>
      <div className={`  ${styles.parent}`}>
        <div className={styles.child1}>
          <section className={styles.inpBoxP}>
            <div className={styles.imgBox}>
              <img src={user} width={20} />
            </div>
            <div className={styles.inpBox}>
              <input type="text" placeholder="Full Name" />
            </div>
          </section>
          <section className={styles.inpBoxP}>
            <div className={styles.imgBox}>
              <img src={mail2} width={20} />
            </div>
            <div className={styles.inpBox}>
              <input type="text" placeholder="Email Address" />
            </div>
          </section>
          <section className={styles.inpBoxP}>
            <div className={styles.imgBox}>
              <img src={pencil} width={20} />
            </div>
            <div className={styles.inpBox}>
              <textarea placeholder="Your Message"></textarea>
            </div>
          </section>
          <section>
            <Button label={"Submit"} width={"100%"} />
          </section>
        </div>
        <div className={styles.child2}>
          <section
            className={styles.contactCards}
            style={{ borderRadius: "8px 8px 0 0" }}
          >
            <div className={styles.contactDetailsImg}>
              <img src={location} width={"100%"} />
            </div>
            <div className={styles.contactDetails}>
              <h3>Location</h3>
              <p>Calle Peon De La Villa 26 06427, Monterrubio De La Serena (Badajoz). España</p>
            </div>
          </section>
          <section className={styles.contactCards}>
            <div className={styles.contactDetailsImg}>
              <img src={mail} width={"100%"} />
            </div>
            <div className={styles.contactDetails}>
              <h3>Email Address</h3>
              <p>info@veyve.net</p>
            </div>
          </section>
          <section
            className={styles.contactCards}
            style={{ borderRadius: "0 0 8px 8px" }}
          >
            <div className={styles.contactDetailsImg}>
              <img src={phone} width={"100%"} />
            </div>
            <div className={styles.contactDetails}>
              <h3>Phone Number</h3>
              <p>+34 645 77 10 87</p>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
