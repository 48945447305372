import React from "react";
import styles from "./style.module.css"
import Facebook from "./Social Icons/facebook.png"
import Twitter from "./Social Icons/twitter.png"
import Instagram from "./Social Icons/instagram.png"
import Skype from "./Social Icons/skype.png"
import Network from "./Social Icons/network.png"
import Whatsapp from "./Social Icons/whatsapp.png"
import Pinterest from "./Social Icons/pinterest.png"
import Codepen from "./Social Icons/codepen.png"
import Linkedin from "./Social Icons/linkedin.png"
import Snapchat from "./Social Icons/snapchat.png"
import GooglePlus from "./Social Icons/googlePlus.png"
import Vimeo from "./Social Icons/vimeo.png"
import Heading from "../../../../Components/Heading";

const ShareApp = () => {

  const socialIcons = [
    { id: 1, iconName: "Facebook", image: Facebook },
    { id: 2, iconName: "Twitter", image: Twitter },
    { id: 3, iconName: "Instagram", image: Instagram },
    { id: 4, iconName: "Skype", image: Skype },
    { id: 5, iconName: "Network", image: Network },
    { id: 6, iconName: "Whatsapp", image: Whatsapp },
    { id: 7, iconName: "Pinterest", image: Pinterest },
    { id: 8, iconName: "Codepen", image: Codepen },
    { id: 9, iconName: "Linkedin", image: Linkedin },
    { id: 10, iconName: "Snapchat", image: Snapchat },
    { id: 11, iconName: "GooglePlus", image: GooglePlus },
    { id: 12, iconName: "Vimeo", image: Vimeo }
  ];

  return (
    <section className={styles.mainBox}>
      <div className="mb-3">
        <Heading label={"Share App"} style={{ fontSize: "2rem", fontWeight: "bold" }} />
      </div>
      <div className={styles.iconBox}>
        {socialIcons.map(icon => (
          <img key={icon.id} src={icon.image} width={"60px"} height={"60px"} alt={icon.iconName} />
        ))}
      </div>
    </section>
  )
};

export default ShareApp;
