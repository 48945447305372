import React from "react";
import Heading from "../../../Components/Heading";
import styles from "./styles.module.css";
import JoditEditor from "jodit-react";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const GeneralDataForm = ({ generalData, setGeneralData }) => {
  const { category } = useSelector((state) => state?.getCategoriesReducers);

  const { t } = useTranslation();

  const [categories, setCategories] = useState();

  useEffect(() => {
    const formattedArray = category?.map((val) => ({
      label: val?.name,
      value: val?.id,
    }));
    setCategories(formattedArray);
  }, [category]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGeneralData({ ...generalData, [name]: value });
  };
  const handleChange2 = (event) => {
    setGeneralData({ ...generalData, description: event });
  };

  const handleChangeType = (e, value) => {
    if (e.target.checked) {
      setGeneralData({ ...generalData, type: value });
    } else {
      setGeneralData({ ...generalData, type: "" });
    }
  };

  const addMuncipality = (e) => {
    e.preventDefault();
    if (
      !generalData?.title ||
      !generalData?.address ||
      !generalData?.telephone ||
      !generalData?.description ||
      !generalData?.email ||
      !generalData?.price ||
      !generalData?.websiteLink
    ) {
      alert("Empty Fields");
    }
  };

  return (
    <form className={`p-4`} onSubmit={addMuncipality}>
      <section>
        <Heading label={t("General-Data")} fontSize="1.5rem" color="#35C89B" />
      </section>
      <section className={styles.inpBox}>
        <input
          type="text"
          onChange={handleChange}
          name="title"
          value={generalData?.title}
          placeholder={t("Title")}
        />
        <input
          type="text"
          onChange={handleChange}
          name="address"
          value={generalData?.address}
          placeholder={t("Address")}
        />
      </section>
      <section className={styles.inpBox}>
        <input
          type="number"
          onChange={handleChange}
          name="telephone"
          value={generalData?.telephone}
          placeholder={t("Telephone")}
        />
        <input
          type="text"
          onChange={handleChange}
          name="email"
          value={generalData?.email}
          placeholder={t("Email")}
        />
      </section>
      <section className={styles.inpBox}>
        <input
          type="number"
          value={generalData?.price}
          onChange={handleChange}
          name="price"
          placeholder={t("Price")}
        />
        <input
          type="text"
          name="websiteLink"
          value={generalData?.websiteLink}
          onChange={handleChange}
         placeholder={t("Website-Link")}
        />
      </section>
      {/* <section className="w-100 my-3">
        <Select
          name="Category"
          options={categories}
          onChange={(e) => {
            setGeneralData({ ...generalData, "categoryId": e?.value });
          }}
          placeholder="Category..."
        />
      </section> */}
      <section>
        <Heading label={t("Description")} fontSize="1.5rem" color="#35C89B" />
      </section>
      <section
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <JoditEditor
          style={{ height: "900px" }}
          className="JoditEditor"
          value={generalData?.description}
          onChange={(newContent) => handleChange2(newContent)}
        />
      </section>
      {/* <section className="my-3 d-flex align-items-center">
        <div className="form-check my-2 me-3">
          <input
            className="form-check-input"
            type="radio"
            id="flexRadioDefault1"
            checked={generalData.type == "Public"}
            onChange={(e) => handleChangeType(e, "Public")}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault1">
            Public
          </label>
        </div>
        <div className="form-check my-2">
          <input
            className="form-check-input"
            type="radio"
            id="flexRadioDefault2"
            checked={generalData.type == "Private"}
            onChange={(e) => handleChangeType(e, "Private")}
          />
          <label className="form-check-label" htmlFor="flexRadioDefault2">
            Private
          </label>
        </div>
      </section> */}
    </form>
  );
};

export default GeneralDataForm;
