import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PointsOfInterestCards from "../../../Components/PointsOfInterstCart";
import PremiumPointsOfInterstCard from "../../../Components/PremiumPointsOfInterstCard";
import API from "../../../Config/API";
import { pointsOfInterest } from "../../../logics/Data";
import Heading from "../../../Components/Heading";
import HeadPremiumsCard from "../../../Components/HeadPremiumsCard";

let headOfPremiumsdemo = [
  {
    name: "History",
    id: 1,
    premiums: [
      {
        name: "premium 1",
        point: [
          {
            name: "point1",
            status: "acitve",
          },
          {
            name: "point2",
            status: "acitve",
          },
          {
            name: "point3",
            status: "inactive",
          },
          {
            name: "point4",
            status: "acitve",
          },
        ],
      },
      {
        name: "premium 2",
        point: [
          {
            name: "premimum 2 point1",
            status: "acitve",
          },
          {
            name: "premimum 2  point2",
            status: "acitve",
          },
          {
            name: "premimum 2  point3",
            status: "inactive",
          },
          {
            name: "premimum 2 point4",
            status: "acitve",
          },
        ],
      },
    ],
  },
  {
    name: "History",
    id: 1,
    premiums: [
      {
        name: "premium 1",
        point: [
          {
            name: "point1",
            status: "inactive",
          },
          {
            name: "point2",
            status: "inactive",
          },
          {
            name: "point3",
            status: "inactive",
          },
          {
            name: "point4",
            status: "inactive",
          },
        ],
      },
      {
        name: "premium 2",
        point: [
          {
            name: "premimum 2 point1",
            status: "acitve",
          },
          {
            name: "premimum 2  point2",
            status: "acitve",
          },
          {
            name: "premimum 2  point3",
            status: "inactive",
          },
          {
            name: "premimum 2 point4",
            status: "acitve",
          },
        ],
      },
    ],
  },
  {
    name: "History",
    id: 1,
    premiums: [],
  },
];

const PointsOfInterestCard = ({
  allData,
  setAllData,
  cordinates,
  isLoading,
  setIsLoading,
}) => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState("");
  const [currentLocation, setCurrentLocation] = useState("");

  const pointsDetails = () => {
    console.log("headOfPremiumsdemo", headOfPremiumsdemo);
    navigate("/points-of-interest-details");
  };
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);
  const user = localStorage.getItem("UserDetails");
  const parsedUser = JSON.parse(user);

  useEffect(() => {
    setCurrentUser(parsedUser);

    getPoints();
  }, [cordinates]);
  function compareDistances(a, b) {
    const aDistance = a.points ? a.points[0].distance : a.distance;
    const bDistance = b.points ? b.points[0].distance : b.distance;

    return aDistance - bDistance;
  }

  const getPoints = async () => {
    setIsLoading(true);

    try {
      let data;
      let url;

      if (parsedUser) {
        url = "/get-points";
        data = await API.post(url, {
          userId: parsedUser?.id,
          distance: "10000",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
          distance: 10000,
        });
      } else {
        url = "/get-points-web";
        data = await API.post(url, {
          language: "Spanish",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      }

      let points = data?.data?.data?.filter(
        (point) => point.status?.toLowerCase() === "active"
      );
      let headOfPremiums = data?.data.premiumHead;
      let premiumPoints = data?.data.premium;
      
      let filterPremium = premiumPoints?.filter(
        (point) => point.status?.toLowerCase() === "active"
      );

      let filterHead = headOfPremiums?.filter(
        (point) => point.status?.toLowerCase() === "active"
      );
      // let filteredArray = premiumPoints.filter((item) => {
      //   return item.points.some(
      //     (point) => point.status.toLowerCase() === "active"
      //   );
      // });

      // let activePoints = headOfPremiums.filter((item) =>
      //   item.premiums?.some((premium) =>
      //     premium.points.some((point) => {
      //       return point.status?.toLowerCase() == "active";
      //     })
      //   )
      // );

      let allPoints = [...points, ...filterPremium , ...filterHead];

      allPoints.sort(compareDistances);

      setAllData(allPoints);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="row pb-3 mx-0">
      {/* {pointsOfInterest.map((value) => {
        return (
          <section
            key={value.id}
            className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
          >
            <PointsOfInterestCards onclick={pointsDetails} value={value} />
          </section>
        );
      })} */}
      {isLoading ? (
        <section className="d-flex justify-content-center align-items-center">
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        </section>
      ) : allData && allData?.length > 0 ? (
        allData?.map((value, ind) => {
          if (value?.premiums) {
            return (
              <section
                key={ind}
                className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
              >
                <HeadPremiumsCard value={value} />
              </section>
            );
          } else if (value.points) {
            return (
              <section
                key={ind}
                className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
              >
                <PremiumPointsOfInterstCard value={value} />
              </section>
            );
          } else {
            return (
              <section
                key={ind}
                className="col-lg-3 col-md-4 col-sm-6 col-12 my-2"
              >
                <PointsOfInterestCards onclick={pointsDetails} value={value} />
              </section>
            );
          }
        })
      ) : (
        <Heading label={"No Points Found"} />
      )}
    </div>
  );
};

export default PointsOfInterestCard;
