import React, { useEffect, useState } from "react";
import Heading from "../../../Components/Heading";
import styles from "./style.module.css";
import Audio from "../../../Components/Audio";
import FLagDropDown from "../../../Components/FlagDropDown";
import location from "../../../Assets/locationBlackIcon.png";
import rightArrow from "../../../Assets/rightArrow.png";
import flrgCircle from "../../../Assets/flagCircle.png";
import signatureimage from "../../../Assets/signatureimage.webp";
import clock from "../../../Assets/clockIcon.png";
import star from "../../../Assets/starIcon.png";
import star2 from "../../../Assets/starIcon2.png";
import download from "../../../Assets/downloadIcon.png";
import share from "../../../Assets/shareIcon.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import API from "../../../Config/API";
import DOMPurify from "dompurify";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { showToast } from "../../../Store/Actions/toastActions";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { checkLanguage } from "../../../Store/Actions/checkLangAtion";
import { TbPhone } from "react-icons/tb";
import { BsEnvelope, BsGlobe } from "react-icons/bs";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper";
import "../PointsInterestSlider/swiper.css";
import TimeDropDown from "../../../Components/TimeDropDown";

const RightSideContent = ({
  lang,
  setLang,
  pointData,
  setPointData,
  cordinates,
}) => {
  const [secondaryThumbsSwiper, setSecondaryThumbsSwiper] = useState(null);

  const [isFollowed, setIsFollowed] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = useParams();
  const [isPlaying, setIsplaying] = useState(false);
  const [progress, setProgress] = useState();
  const [signature, setSignature] = useState("");
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);
  const secondaryImages = pointData?.secondaryImages;
  const navigate = useNavigate();
  const audioElem = useRef();
  const { t } = useTranslation();

  const listenAudio = async () => {
    await API.post("/listen", {
      type: "points",
      valueId: id || "",
      language: lang,
    })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    if (pointData?.audioFile) {
      if (isPlaying) {
        audioElem.current.play();
        listenAudio();
      } else {
        audioElem.current.pause();
      }
    }
  }, [isPlaying]);

  useEffect(() => {
    handleChangeDrop();
    dispatch(checkLanguage(id));
  }, [lang, currentUser]);

  useEffect(() => {
    setTimeout(() => {
      checkIsFollowed();
    }, 5000);
  }, [currentUser]);

  const handleChangeDrop = async (e) => {
    // console.log(e.target.value);
    await API.post("/get-point-lang", {
      language: lang,
      pointId: id,
      latitude: String(cordinates?.latitude) || "",
      longitude: String(cordinates?.longitude) || "",
    })
      .then((res) => {
        setPointData(res?.data?.data);
        setSignature(
          `https://veyveback.com/${res?.data?.data?.signatureImage}`
        );
      })
      .catch((err) => console.log("err", err));
  };

  const onPlaying = () => {
    const duration = audioElem.current.duration;
    const ct = audioElem.current.currentTime;
    setProgress({ progresss: (ct / duration) * 100, length: duration });
  };

  // Follow Point
  const followPoint = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/follow", {
          type: "point",
          userId: currentUser?.id,
          valueId: pointData?.id,
        });
        dispatch(showToast(res?.data?.message, "success"));
        setIsFollowed(!isFollowed);
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const checkIsFollowed = async () => {
    if (currentUser) {
      try {
        const res = await API.post("/isFollow", {
          type: "point",
          userId: currentUser?.id,
          valueId: pointData?.id,
        });
        // console.log(res.data?.data);
        if (res?.data?.data === "yes") {
          setIsFollowed(true);
        } else if (res?.data?.data === "yes") {
          setIsFollowed(false);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  // Copy link to Clipboard
  const handleShareClick = () => {
    const currentURL = window.location.href;

    navigator.clipboard
      .writeText(currentURL)
      .then(() => {
        dispatch(showToast("URL Copied", "success"));
      })
      .catch((error) => {
        console.log("Failed to copy URL to clipboard:", error);
      });
  };

  const navigateToGoogleMaps = () => {
    const url = `https://www.google.com/maps?q=${pointData?.latitude},${pointData?.longitude}`;
    window.open(url, "_blank"); // Naye tab mein Google Maps kholein
  };

  const sanitizedDescription = DOMPurify.sanitize(pointData?.description);

  return (
    <div>
      <section className="mb-3">
        <div className={styles.heading}>
          <Heading
            label={pointData?.title ? pointData.title : "No Title"}
            style={{ fontWeight: 900 }}
          />
        </div>
        <p className={styles.para}>
          {pointData?.address ? pointData.address : "No Title"}
        </p>
      </section>
      <section className={`d-flex ${styles.dropDOwnAudioBox} align-items-end`}>
        {/* <div className={styles.audioBox}>
        </div> */}
        <div className={``}>
          <>
            <audio
              src={`https://veyveback.com/${pointData?.audioFile}`}
              ref={audioElem}
              onTimeUpdate={onPlaying}
            />
            <Audio
              audioFile={pointData?.audioFile}
              isPlaying={isPlaying}
              setIsplaying={setIsplaying}
              audioElem={audioElem}
              progress={progress}
              setProgress={setProgress}
            />
          </>
        </div>
        {pointData?.audioFile && (
          <div className={`ms-3 ${styles.dropDownBox}`}>
            <FLagDropDown setName={setLang} type="point" />
          </div>
        )}
      </section>
      <section>
        <TimeDropDown data={pointData} />
      </section>
      {pointData?.distance && (
        <section className={`mt-3 ${styles.locationBox}`}>
          <div className={styles.locationContent}>
            <img width="25" height="25" src={location} alt="icon" />
            <span className="ms-2">{pointData?.distance} KM Away</span>
          </div>
          <div>
            <img
              onClick={navigateToGoogleMaps}
              width="40"
              className="ms-3"
              src={rightArrow}
              alt="icon"
            />
          </div>
        </section>
      )}
      <section className="mt-3">
        <h6
          className="m-0 mb-1"
          style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}
        >
          {t("detailScreen.about")}
        </h6>
        {/* <p
          className={styles.para}
          style={{
            fontSize: "13px",
          }}
        >
          {
            pointData?.description
              ? pointData?.description?.replace(/<\/?[^>]+(>|$)/g, "")
              : "No Description"
          }
        </p> */}
        {pointData?.description ? (
          <div
            style={{ letterSpacing: "1px", wordSpacing: "2px" }}
            className="text-muted mb-3"
            dangerouslySetInnerHTML={{ __html: sanitizedDescription }}
          ></div>
        ) : (
          "No Description"
        )}
      </section>
      <section>
        {pointData?.price !== null && pointData?.price != 0 && (
          <h6
            className="m-0 my-3"
            style={{ fontWeight: 900, color: "#000", fontSize: "18px" }}
          >
            {t("detailScreen.ticketPrice")}{" "}
            <span className={`${styles.ticket}`}>{pointData?.price}</span>
          </h6>
        )}
        <div className={styles.flagBox}>
          <section
            className={styles.cirCleImgBox}
            onClick={() =>
              navigate(
                `/muncipality/${pointData?.muncipality?.title}/${pointData?.muncipality?.id}`
              )
            }
          >
            {/* <img src={signatureimage} width="100%" alt="signature" /> */}

            {signature ? (
              <img src={signature} width="100%" alt="signature" />
            ) : (
              "No Image"
            )}
          </section>
          <section className="ms-3">
            <h6 className="m-0 mb-2" style={{ fontWeight: 500, color: "gray" }}>
              {pointData?.muncipality
                ? pointData?.muncipality?.title
                : t("detailScreen.muncipality")}
            </h6>
            <h6 className="m-0 mb-1" style={{ fontWeight: 600 }}>
              {t("detailScreen.muncipality")}
            </h6>
          </section>
        </div>
        <section className="mt-3">
          <div className={`${styles.organizerIconsBox}`}>
            {pointData?.phone && (
              <Link className="mx-2" to={`tel:${pointData?.phone}`}>
                <TbPhone color="#000" size={27} />
              </Link>
            )}
            {pointData?.websiteLink && (
              <Link
                className="mx-2"
                to={`${pointData?.websiteLink}`}
                target="_blank"
              >
                <BsGlobe color="#000" size={27} />
              </Link>
            )}
            {pointData?.email && (
              <Link className="mx-2" to={`mailto:${pointData?.email}`}>
                <BsEnvelope color="#000" size={27} />
              </Link>
            )}
          </div>
        </section>
      </section>
      <section className={`mt-3 ${styles.iconBox}`}>
        {currentUser && (
          <>
            {/* <div>
              <img src={clock} width="100%" alt="icon" />
            </div> */}
            <div className="mx-3" onClick={followPoint}>
              {isFollowed ? (
                <AiFillStar size={20} color="#34C89A" />
              ) : (
                <AiOutlineStar size={20} color="#34C89A" />
              )}
            </div>
            {/* <div>
              <img src={download} width="90%" alt="icon" />
            </div> */}
            <div className="ms-3" onClick={handleShareClick}>
              <img src={share} width="90%" alt="icon" />
            </div>
          </>
        )}
      </section>
      <div>
        {secondaryImages && secondaryImages?.length > 0 && (
          <div
            className={`container  ${styles.sliderParent} ${styles.secondarySliderParent}`}
          >
            <Swiper
              style={{
                "--swiper-navigation-color": "#000",
                "--swiper-pagination-color": "#000",
              }}
              spaceBetween={10}
              navigation={true}
              thumbs={{ swiper: secondaryThumbsSwiper }}
              modules={[FreeMode, Navigation, Thumbs]}
              className="secondaryMySwiper2"
            >
              {secondaryImages &&
                secondaryImages?.length > 0 &&
                secondaryImages?.map((item) => {
                  if (item) {
                    return (
                      <SwiperSlide>
                        <img
                          width="100%"
                          height={"300px"}
                          alt="slider"
                          src={`https://veyveback.com/${item}`}
                        />
                      </SwiperSlide>
                    );
                  }
                })}
            </Swiper>
            <Swiper
              onSwiper={setSecondaryThumbsSwiper}
              spaceBetween={10}
              slidesPerView={4}
              freeMode={true}
              watchSlidesProgress={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="secondaryMySwiper"
            >
              {secondaryImages &&
                secondaryImages.length > 0 &&
                secondaryImages?.map((item) => {
                  if (item) {
                    return (
                      <SwiperSlide>
                        <img
                          width="100%"
                          height={"100px"}
                          alt="slider"
                          src={`https://veyveback.com/${item}`}
                        />
                      </SwiperSlide>
                    );
                  }
                })}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
};

export default RightSideContent;
