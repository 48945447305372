import React, { useEffect, useState } from "react";
import CardsSlider from "../../../Components/CardsSilder";
import Heading from "../../../Components/Heading";
import routesIcon from "../../../Assets/routesIcon.png";
import styles from "./style.module.css";
import { routesData } from "../../../logics/Data";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import API from "../../../Config/API";

const Routes = () => {
  const [allData, setAllData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();
  const user = localStorage.getItem("UserDetails");
  const parsedUser = JSON.parse(user);
  const userLang = localStorage.getItem("userLanguage");

  const getRoutes = async () => {
    let response;
    try {
      if (parsedUser) {
        response = await API.post("get-roots", { userId: parsedUser?.id });
      } else {
        response = await API.post("get-roots-web", {
          language: userLang || "Spanish",
        });
      }
      setAllData(response?.data?.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getRoutes();
  }, []);

  return (
    <div className="container py-5 ">
      <section className={styles.eventHeader}>
        <Heading
          label={t("routes")}
          width="60px"
          img={routesIcon}
          style={{ fontWeight: "bold" }}
          bottom="5px"
        />
        <Link to={"/routes"}>
          <button>See All</button>
        </Link>
      </section>
      <section>
        <CardsSlider data={allData} name="routes" />
      </section>
    </div>
  );
};

export default Routes;
