import React, { useState } from "react";
import Heading from "../../../../Components/Heading";
import styles from "./style.module.css";
import MultiRangeSlider from "multi-range-slider-react";
import "../../../Home/HeaderBox/style.css";
import Button from "../../../../Components/Button";
import API from "../../../../Config/API";
import { showToast } from "../../../../Store/Actions/toastActions";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
const RadiusOfAction = () => {
  const [minDistanceValue, set_minDistanceValue] = useState(0);
  const user = JSON.parse(localStorage.getItem("UserDetails"));
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state?.getUserReducer?.userData);
  const [maxDistanceValue, set_maxDistanceValue] = useState(
    userDetails ? +userDetails?.radius : 1000
  );
  const handleDistanceChange = (value) => {
    // console.log(value)
    set_minDistanceValue(value?.minValue);
    set_maxDistanceValue(value?.maxValue);
  };
  const updateRadius = async () => {
    setIsLoading(true);
    if (user) {
      try {
        const res = await API.post("/update-radius", {
          userId: user?.id,
          distance: maxDistanceValue,
        });
        res?.status
          ? dispatch(showToast("Radius Updated", "info"))
          : dispatch(showToast("Something Went Wrong", "error"));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={styles.mainBox}>
      <section>
        <Heading label={"Radius Of Action"} />
      </section>

      <div className={`my-3 ${styles.progessParent}`}>
        <MultiRangeSlider
          onChange={handleDistanceChange}
          min={0}
          max={1000}
          minValue={minDistanceValue}
          maxValue={maxDistanceValue}
          labels={[`${minDistanceValue}km`, `${maxDistanceValue}km`]}
          ruler={false}
          baseClassName={styles.multiRangeContainer}
          className={styles.multiRangeSlider}
          barInnerColor="#35c89b"
          barLeftColor="#fff"
          barRightColor="#fff"
          thumbLeftColor="#35c89b"
          thumbRightColor="#35c89b"
        />
      </div>
      <section className="text-center my-5">
        {loading ? (
          <Spinner className="mx-auto" style={{ color: "#35C89B" }} />
        ) : (
          <Button
            label={"Update Radius"}
            onClick={updateRadius}
            width={"100%"}
          />
        )}
      </section>
    </div>
  );
};

export default RadiusOfAction;
