import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../../Config/API";
import RightSideContent from "../RightSideContent";
import styles from "./styles.module.css";
import EventSlider from "../EventSlider";
import { watchModule } from "../../../Store/Actions/detailsModuleActions";
import { useDispatch } from "react-redux";

const InnerContent = ({ setTitle, cordinates }) => {
  // Getting ID from Params
  const { id } = useParams();
  const [currentUser, setCurrentUser] = useState("");
  const [lang, setLang] = useState("Spanish");
  const [filteredEvent, setFilteredEvent] = useState("");
  const [currentLang, setCurrentLang] = useState("");
  const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    // Get User
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);

    // Get Language
    const userLang = localStorage.getItem("userLanguage");
    setCurrentLang(userLang);
  }, []);

  useEffect(() => {
    if (!isApiCallInProgress) {
      getEvents();
      setIsApiCallInProgress(true);
    }
  }, [currentLang, lang]);

  useEffect(() => {
    dispatch(
      watchModule({
        type: "event",
        valueId: id,
        userId: currentUser?.id || "",
      })
    );
  }, []);

  const getEvents = async () => {
    // if (currentUser) {
    //   try {
    //     const res = await API.post(
    //       "/get-events",
    //       { userId: 1, distance: "10000" }
    //     );
    //     const data = res?.data?.data;
    //     // console.log(data)
    //     const sortedData = data?.filter((value) => {
    //       return value.id == id;
    //     });
    //     // console.log(sortedData)
    //     setFilteredEvent(sortedData[0]);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // } else {
    //   try {
    //     const res = await API.post(
    //       "/get-points-web",
    //       { language: lang, latitude: "", longitude: "" }
    //     );
    //     const data = res?.data?.data;
    //     // console.log(data)
    //     const sortedData = data?.filter((value) => {
    //       return value.id == id;
    //     });
    //     // console.log(sortedData)
    //     setFilteredEvent(sortedData[0]);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    // Calling Language API
    try {
      const res = await API.post("/get-event-lang", {
        language: lang,
        eventId: id,
      });

      const data = res?.data?.data;
      setFilteredEvent(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsApiCallInProgress(false);
    }
  };

  setTitle(filteredEvent?.title);

  return (
    <div className={`d-flex justify-content-around ${styles.parent}`}>
      <section className={styles.child1}>
        <EventSlider eventData={filteredEvent} />
      </section>
      <section className={styles.child2}>
        <RightSideContent
          eventData={filteredEvent}
          lang={lang}
          setLang={setLang}
          setEventData={setFilteredEvent}
          cordinates={cordinates}
        />
      </section>
    </div>
  );
};

export default InnerContent;
