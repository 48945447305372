import React from "react";
import API from "../../Config/API";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./style.module.css";
import "./style.module.css";
import Heading from "../Heading";
import { BsTrash } from "react-icons/bs";
import Select from "react-select";
import { BsUpload } from "react-icons/bs";
import CustomButton from "../Button";

const Gallery = ({ handleGetImage }) => {
  const [images, setImages] = useState([]);
  const [mediaFilter, setMediaFilter] = useState({
    label: "All Media",
    value: "All Media",
  });
  const [currentImage, setCurrentImage] = useState("");

  useEffect(() => {
    getAll();
  }, []);

  const getAll = async () => {
    const res = await API.post("get-all-paths");
    setImages(res.data?.data);
  };

  const filterOption = [
    {
      label: "All Media",
      value: "All Media",
    },
  ];

  const handleUpload = () => {
    handleGetImage(currentImage);
  };

  return (
    <div className="p-3">
      <div className="ps-2">
        <Heading label="Media Library" fontSize="2.5rem" color="#35C89B" />
      </div>
      <div className="py-3 px-2  d-flex align-items-center justify-content-between">
        <div className=" d-flex align-items-center">
          <section>
            <input type="checkbox" id="box" className={styles.checkBox} />
            <label htmlFor="box" className="cursor-pointer">
              Select All (1)
            </label>
          </section>
          <section className="ms-4 d-flex align-items-center">
            <BsTrash />
            <label htmlFor="box" className="cursor-pointer ps-2">
              Delete
            </label>
          </section>
        </div>
        <div className="w-25">
          <Select
            value={mediaFilter}
            options={filterOption}
            onChange={(e) => setMediaFilter(e)}
          />
        </div>
      </div>
      <div className={`row m-0 ${styles.imagesContainer}`}>
        {images
          ?.filter((_, index) => index <= 20)
          ?.map((item, index) => (
            <React.Fragment key={index}>
              <img
                onClick={() => setCurrentImage(item)}
                className={`${styles.image} ${item == currentImage && styles.imageActive} col-lg-3 col-md-6 col-sm-12 my-3 p-0`}
                src={`https://veyveback.com/${item}`}
                width={"200px"}
                onLoad={(e) => (e.currentTarget.style.display = "block")}
                onError={(e) => (e.currentTarget.style.display = "none")}
                alt="galleryImg"
              />
            </React.Fragment>
          ))}
      </div>
      <div className="d-flex justify-content-center align-items-center py-4">
        <CustomButton
          label={"Upload"}
          icon={<BsUpload />}
          width={"50%"}
          onClick={handleUpload}
        />
      </div>
    </div>
  );
};

export default Gallery;
