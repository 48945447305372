import { Modal } from "react-bootstrap";
import PointsOfInterstCart from "../PointsOfInterstCart";
import ServicesCard from "../ServicesCard";
import EventsCard from "../EventsCard";

function CardModal({ show, setShow, value, type }) {
  const handleClose = () => setShow(false);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        centered={true}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // padding: "10px",
        }}
      >
        {type === "points" && <PointsOfInterstCart value={value} />}
        {/* {type === "points" && */}
        {/* // (value?.points ? ( */}
        {/* // <PremiumPointsOfInterstCard value={value} /> */}
        {/* // ) : ( */}
        {/* ))} */}
        {type === "events" && <EventsCard value={value} />}
        {type === "service" && <ServicesCard value={value} />}
      </Modal>
    </>
  );
}

export default CardModal;
