import React, { useEffect, useState } from "react";
import CardsSlider from "../../../Components/CardsSilder";
import Heading from "../../../Components/Heading";
import pointOfInterest from "../../../Assets/pointOfInterest.png";
import styles from "./style.module.css";
import { pointsOfInterest } from "../../../logics/Data";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import API from "../../../Config/API";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PointsOfInterest = ({ cordinates }) => {
  const [pointsData, setPointsData] = useState([]);
  const [currentUser, setCurrentUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentLocation, setCurrentLocation] = useState("");
  const currentLocationUser = localStorage.getItem("currentLocation");
  const parsedLocation = JSON.parse(currentLocationUser);

  const languageState = useSelector((state) => state.navReducer);
  const { t } = useTranslation();

  useEffect(() => {
    const user = localStorage.getItem("UserDetails");
    const parsedUser = JSON.parse(user);
    setCurrentUser(parsedUser);
  }, []);

  useEffect(() => {
    getPoints();
  }, [languageState?.language, currentLocation, cordinates]);
  // console.log(languageState);
  function compareDistances(a, b) {
    const aDistance = a.points ? a.points[0].distance : a.distance;
    const bDistance = b.points ? b.points[0].distance : b.distance;

    return aDistance - bDistance;
  }

  // Getting Points of Interest
  const getPoints = async () => {
    setIsLoading(true);

    try {
      let data;
      let url;

      if (currentUser) {
        url = "/get-points";
        data = await API.post(url, {
          userId: 1,
          distance: "10000",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      } else {
        url = "/get-points-web";
        data = await API.post(url, {
          language: languageState?.language
            ? languageState?.language
            : "Spanish",
          latitude:
            String(cordinates?.latitude) == "undefined"
              ? ""
              : String(cordinates?.latitude),
          longitude:
            String(cordinates?.longitude) == "undefined"
              ? ""
              : String(cordinates?.longitude),
        });
      }
      let points = data?.data?.data?.filter(
        (point) => point.status?.toLowerCase() === "active"
      );
      let headOfPremiums = data?.data?.premiumHead;
      let premiumPoints = data?.data?.premium;

      let filteredArray = premiumPoints?.filter((item) => {
        return item?.points?.some(
          (point) => point?.status?.toLowerCase() === "active"
        );
      });

      let activePoints = headOfPremiums?.filter((item) =>
        item.premiums?.some((premium) =>
          premium?.points?.some((point) => {
            return point?.status?.toLowerCase() == "active";
          })
        )
      );

      let allPoints = [...points, ...activePoints, ...filteredArray];

      allPoints.sort(compareDistances);

      setPointsData(allPoints);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container py-5 ">
      <section className={styles.eventHeader}>
        <Heading
          label={t("pointsOfInterest")}
          img={pointOfInterest}
          style={{ fontWeight: "bold" }}
          bottom="22px"
        />
        <Link to="/points-of-interest">
          <button>See All</button>
        </Link>
      </section>
      <section className={isLoading && "d-flex justify-content-center"}>
        {isLoading ? (
          <Spinner style={{ color: "#35c89b", fontSize: 20 }} />
        ) : (
          <CardsSlider data={pointsData} name="pointsOfInterest" />
        )}
      </section>
    </div>
  );
};

export default PointsOfInterest;
